<template>
  <pdl-dialog
    qaid="cart-item-unavailablity-dialog"
    :visible="isModalVisible"
    :title="$t('myCart.notAvailable')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <p>{{ $t('myCart.notAvailable.message') }}</p>
    <template #footer>
      <div class="buttons flex-col md:flex-row-reverse">
        <slot name="dialog-footer" />
        <pdl-button secondary small @click="$emit('close')"> {{ $t('text.button.cancel') }} </pdl-button>
      </div>
    </template>
  </pdl-dialog>
</template>
<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlButton} from '@pedal/pdl-button';
export default {
  name: 'CartItemUnavailablityDialog',
  components: {
    PdlDialog,
    PdlButton,
  },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
