<template>
  <pdl-button
    secondary
    small
    :qaid="`product-attribute-button-${option.value}`"
    :class="classes"
    class="product-attribute-btn"
    @click="updateSelected"
  >
    {{ option.displayName }}
  </pdl-button>
</template>
<script>
import {mapState} from 'vuex';
import {PdlButton} from '@pedal/pdl-button';
import {isInStock} from '@/components/containers/pdp/utils/retailer-helpers';

export default {
  name: 'ProductAttributeButton',
  components: {
    PdlButton,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    selectedAttributes: {
      type: Object,
      default: () => {},
    },
    updateSelectedAttributes: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('backend', ['b2b']),
    ...mapState('pdp', ['isECommAvailable']),
    classes() {
      const selected = this.option.value === this.selectedAttributes?.[this.option.code];
      return {
        selected,
        unavailable: !this.isAvailable,
      };
    },
    isAvailable() {
      if (!this.isECommAvailable) return true;
      const variant = this.product.variants.find((v) => {
        return Object.entries(this.selectedAttributes).every(([key, value]) => {
          if (key === this.option.code) {
            return v.variantAttributes[this.option.code] === this.option.value;
          }
          return v.variantAttributes[key] === value;
        });
      });

      return variant?.isLocallyStockAvailable || isInStock(this.b2b, variant?.globalStockStatus);
    },
  },
  methods: {
    updateSelected() {
      this.updateSelectedAttributes({
        [this.option.code]: this.option.value,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.product-attribute-btn {
  &.button--secondary {
    border: 1px solid var(--gray-20);
    padding: 0.5rem;
    position: relative;
    white-space: nowrap;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:focus,
    &:hover {
      background: var(--blue-10);
      border: 1px solid var(--blue-100);
      box-shadow: inset 0 0 2px 1px var(--blue-100);
    }

    &.unavailable {
      background: var(--gray-10);
      color: var(--gray-70);
      border: 1px solid var(--gray-70);

      &:focus,
      &:hover {
        border: 1px solid var(--blue-100);
      }

      &::after {
        background: linear-gradient(
          to top left,
          transparent 0,
          transparent 49%,
          var(--gray-70) 50%,
          transparent 51%,
          transparent
        );
      }
    }

    &.selected {
      border: 1px solid var(--blue-100);
      box-shadow: inset 0 0 0 1px var(--blue-100);

      &:focus,
      &:hover {
        background: var(--blue-10);
        box-shadow: inset 0 0 2px 1px var(--blue-100);
      }
    }
  }
}
</style>
