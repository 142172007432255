const PersonalDetailsKeyMap = Object.freeze({
  firstName: 'firstName',
  lastName: 'lastName',
  phone: 'phone',
});

const RequiredAddressKeyMap = Object.freeze({
  line1: 'line1',
  townCity: 'town',
  regionIso: 'region',
  postcode: 'postalCode',
});

const CustomerDetailsFieldsKeyMap = Object.freeze({
  ...PersonalDetailsKeyMap,
  line2: 'line2',
  ...RequiredAddressKeyMap,
});

export {CustomerDetailsFieldsKeyMap, PersonalDetailsKeyMap, RequiredAddressKeyMap};
