<template>
  <div class="border border-red-100 p-2">
    <cart-errors @handle-click="removeUnavailableCartItems" />
    <pdl-media-object-list>
      <cart-item
        v-for="(entry, i) of cartEntries"
        :key="`cart-unavailable-item-${entry.product.code}-${i}`"
        :is-ecomm-available="isEcommAvailable"
        :is-item-out-of-stock="true"
        :entry="entry"
        :is-quantity-control-enabled="isQuantityControlEnabled"
        :has-mixed-promo-items="hasMixedPromoItems"
      />
    </pdl-media-object-list>
  </div>
</template>
<script>
import CartErrors from '@/components/cart/b2c/CartErrors.vue';
import CartItem from '@/components/cart/b2c/CartItem.vue';
import {PdlMediaObjectList} from '@pedal/pdl-media-object';
import {mapActions} from 'vuex';
export default {
  components: {
    CartItem,
    PdlMediaObjectList,
    CartErrors,
  },
  props: {
    cartData: {
      type: Object,
      required: true,
    },
    cartEntries: {
      type: Array,
      required: true,
    },
    isEcommAvailable: {
      type: Boolean,
      required: true,
    },
    isQuantityControlEnabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasMixedPromoItems() {
      return (
        this.cartData?.entries?.length > 1 &&
        this.cartData?.appliedProductPromotions?.length > 0 &&
        !this.cartData?.entries?.every((entry) => entry.isLineLevelPromotionPresent)
      );
    },
  },
  methods: {
    ...mapActions('cart', ['removeOutOfStockCartItems']),
    removeUnavailableCartItems() {
      this.removeOutOfStockCartItems();
    },
  },
};
</script>
