import {mapState, mapGetters} from 'vuex';
import {getValidLocationFromAddress, getValidLocationFromPostCode} from '@/components/mapbox/utils/mapbox-api.js';
import {getFormattedAddress, normalisePostalCode} from '@/utils/cart/delivery-address-util.js';

const MapboxLocation = {
  computed: {
    ...mapState('backend', ['mapboxApiKey']),
    ...mapGetters('backend', ['countryCode']),
  },
  methods: {
    async locationfromPostCode(postcode) {
      return getValidLocationFromPostCode({
        postalCode: postcode,
        accessToken: this.mapboxApiKey,
        country: this.countryCode,
      });
    },

    async mapboxLocation(address) {
      try {
        const locationFromAddress = await getValidLocationFromAddress({
          address: getFormattedAddress(address),
          accessToken: this.mapboxApiKey,
          country: this.countryCode,
        });

        if (normalisePostalCode(locationFromAddress?.postcode) !== normalisePostalCode(address?.postcode)) {
          return this.locationfromPostCode(address?.postcode);
        }

        return locationFromAddress;
      } catch (error) {
        return this.locationfromPostCode(address?.postcode);
      }
    },
    validateLocation(location, formObject) {
      if (normalisePostalCode(location?.postcode) !== normalisePostalCode(formObject.postcode)) {
        this.setValidationErrors(this.mapboxFailureError());
        return false;
      }
      return true;
    },
    mapboxFailureError() {
      return {
        postcode: this.$t('address.postcode.invalid'),
      };
    },
  },
};

export {MapboxLocation};
