<template>
  <fieldset class="mb-5" :class="isRestrictedToRetailer ? 'mb-4' : 'mb-5'">
    <legend class="text-lg font-bold">{{ $t('checkout.address.details') }}</legend>
    <slot name="postcode-autofill" />
    <ul>
      <li
        v-for="(componentConfig, index) in fieldList"
        :key="`${componentConfig.field}-${index}`"
        :qaid="componentConfig.qaid"
      >
        <component :is="componentConfig.component" v-bind="getFieldProps(componentConfig.field)" />
      </li>
    </ul>
  </fieldset>
</template>

<script>
import {Line1Field, Line2Field, TownCityField, RegionField, PostcodeField} from '@/components/checkout/fields/address';
import {getAddressFormConfig, AddressFormComponents} from '@/config/checkout/address-form-config';
import FormGroup from '@/components/FormGroup';
import {mapState, mapGetters} from 'vuex';

export default {
  components: {Line1Field, Line2Field, TownCityField, RegionField, PostcodeField, FormGroup},

  props: {
    isPostcodeLookupEnabled: {
      type: Boolean,
      default: false,
    },
    regions: {
      type: Array,
      default: () => [],
    },
    postcodeMaxLength: {
      type: Number,
      required: true,
    },
    postcodeFormat: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('backend', ['locale']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    fieldList() {
      return getAddressFormConfig(this.locale).addressDetails;
    },
  },
  methods: {
    getFieldProps(field) {
      const propConfig = {
        [AddressFormComponents.LINE1.field]: {
          isBillingAddressForm: true,
        },
        [AddressFormComponents.REGION.field]: {
          regions: this.regions,
        },
        [AddressFormComponents.POSTCODE.field]: {
          postcodeMaxLength: this.postcodeMaxLength,
          postcodeFormat: this.postcodeFormat,
        },
      };
      return propConfig[field] ?? {};
    },
  },
};
</script>
