<template>
  <div>
    <table id="users-grid" class="b2b-grid b2b-grid--collapse mb-0 is-compact">
      <caption class="sr-only">
        {{
          $t('text.users')
        }}
      </caption>
      <thead class="b2b-grid__header">
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            class="b2b-grid__cell b2b-grid__head"
            :class="[column.cssClasses]"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(row, rowIndex) in paginatedData">
          <user-item :key="rowIndex" :row="row" :row-id="row[idKey]" />
        </template>
      </tbody>
    </table>
    <div class="mt-2">
      <pagination-controls
        v-if="pageCount > 1"
        ref="pagination"
        :page-number="pageNumber"
        :page-count="pageCount"
        @page-change="pageChange"
        @prev-page="prevPage"
        @next-page="nextPage"
      />
    </div>
  </div>
</template>

<script>
import pagination from '../../../mixins/pagination';
import PaginationControls from '@/components/PaginationControls';
import UserItem from '@/components/containers/my-company/UserItem';

export default {
  components: {PaginationControls, UserItem},
  mixins: [pagination],

  provide() {
    return {
      grid: this,
    };
  },

  props: {
    size: {
      type: Number,
      required: false,
      default: ACC.usersPageSize || 15,
    },

    gridData: {
      type: Array,
      default: () => [],
    },

    columns: {
      type: Array,
      default: () => [],
    },

    idKey: {
      type: String,
      default: 'uid',
    },
  },

  data() {
    return {};
  },

  computed: {
    listData() {
      return this.gridData;
    },
  },
};
</script>
