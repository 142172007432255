<template>
  <div class="cell auto">
    <checkout-dialog
      v-if="isCheckoutDialogEnabled"
      :is-modal-visible="isCheckoutDialogVisible"
      @close-modal="closeCheckoutModal"
    >
      <template #checkout-buttons>
        <div class="buttons buttons--right">
          <apple-pay-payment-button
            :is-express-checkout="true"
            qaid="apple-pay-button-modal"
            button-style="white-outline"
          />
          <pdl-button tertiary @click="closeCheckoutModal">{{ $t('checkout.multi.cancel') }}</pdl-button>
        </div>
      </template>
    </checkout-dialog>
    <cart-item-unavailablity-dialog :is-modal-visible="isProductUnavailableDialogVisible" @close="closeCartDialog">
      <template #dialog-footer>
        <pdl-button primary small @click="removeUnavailableCartItems">
          {{ $t('myCart.notAvailable.remove') }}
        </pdl-button>
      </template>
    </cart-item-unavailablity-dialog>
    <pdl-collapse qaid="cart-summary-collapse" default-active>
      <pdl-collapse-item>
        <template #title>
          <div class="text-base">
            <span class="font-heading text-lg">{{ $t('text.cart') }}</span>
            <span v-if="cartData.code" class="text-gray-60 font-normal">{{ `(${cartData.code})` }}</span>
          </div>
        </template>
        <cart-promotion-input :has-mixed-promo-items="hasMixedPromoItems" />
        <cart-delivery-mode-selector
          v-if="hasCartDataEntries"
          :entries="cartData.entries"
          @delivery-mode-selected="updateShippingSpeeds"
        />
        <cart-summary-totals />
      </pdl-collapse-item>
    </pdl-collapse>
    <div class="mt-4 mb-5 md:mb-8">
      <div class="buttons">
        <pdl-button qaid="cart-checkout-link" primary block @click="handleCheckoutResolver">
          {{ $t('checkout.checkout') }}
        </pdl-button>
        <apple-pay-payment-button
          v-if="isApplePayEnabledOnCart"
          :is-full-width="true"
          :on-click="openCheckoutModal"
          button-style="white-outline"
        />
        <pdl-link qaid="cart-continue-link" :href="homePageUrl" button tertiary block icon-right icon="chevron_right">
          {{ $t('cart.page.continue') }}
        </pdl-link>
      </div>
    </div>
    <section class="mx-auto space-y-12 text-center mb-5 md:mb-8">
      <payment-icon-list />
    </section>
  </div>
</template>

<script>
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {mapMutations, mapState, mapActions, mapGetters} from 'vuex';
import {PdlLink} from '@pedal/pdl-link';
import {PdlButton} from '@pedal/pdl-button';
import PaymentIconList from '@/components/PaymentIconList.vue';
import CartSummaryTotals from '@/components/cart/b2c/CartSummaryTotals.vue';
import CartPromotionInput from '@/components/cart/b2c/CartPromotionInput.vue';
import ApplePayPaymentButton from '@/components/checkout/providers/apple-pay/ApplePayPaymentButton.vue';
import CheckoutDialog from '@/components/checkout/CheckoutDialog.vue';
import CartDeliveryModeSelector from '@/components/cart/b2c/CartDeliveryModeSelector.vue';
import {ApplePayProvider} from '@/utils/checkout/payment-providers/apple-pay-provider.js';
import {ShipToHomeMessages} from '@/constants/delivery-modes.js';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {delayedScrollToElement} from '@/utils/scroll-to.js';
import CartItemUnavailablityDialog from '@/components/cart/b2c/CartItemUnavailablityDialog.vue';

export default {
  components: {
    PdlCollapse,
    PdlCollapseItem,
    PdlLink,
    PdlButton,
    PaymentIconList,
    CartSummaryTotals,
    CartPromotionInput,
    ApplePayPaymentButton,
    CheckoutDialog,
    CartDeliveryModeSelector,
    CartItemUnavailablityDialog,
  },
  inject: ['trackProceedCheckout'],
  props: {
    isApplePayEnabledOnCart: {
      type: Boolean,
      default: false,
    },
    checkoutUrl: {
      type: String,
      required: true,
    },
    homePageUrl: {
      type: String,
      required: true,
    },
    cartData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('backend', ['currencyIso', 'isUserLoggedIn', 'supportedPaymentMethods']),
    ...mapState('checkout', ['isCheckoutDialogVisible']),
    ...mapState('cart', ['isProductUnavailableDialogVisible']),
    ...mapGetters('cart', ['hasMixedPromoItems', 'hasDeliveryModeSelectionsMade', 'outOfStockCartItems']),
    supportedPaymentCodes() {
      return this.supportedPaymentMethods.filter((method) => method?.code?.toLocaleLowerCase());
    },
    isApplePayAvailable() {
      return ApplePayProvider.canMakePayments();
    },
    isCheckoutDialogEnabled() {
      return this.cartData && this.isApplePayEnabledOnCart && this.isApplePayAvailable;
    },
    hasCartDataEntries() {
      return !!this.cartData?.entries?.length;
    },
    handleCheckoutResolver() {
      return this.outOfStockCartItems.length ? this.openCartDialog : this.handleStartCheckout;
    },
  },
  methods: {
    ...mapMutations('checkout', ['setIsCheckoutDialogVisible']),
    ...mapMutations('cart', ['setHasClickedProceedWithoutDeliveryModeSelected', 'setIsCartProductDialogVisible']),
    ...mapActions('cart', [
      'validateCart',
      'getCartData',
      'updateCartEntryOptions',
      'revertToAnonymousCart',
      'removeOutOfStockCartItems',
    ]),
    async updateShippingSpeeds(deliveryMode) {
      let entriesToUpdate = this.cartData.entries.filter((entry) =>
        Object.keys(ShipToHomeMessages).includes(entry.deliveryMode?.code)
      );
      const response = await this.updateCartEntryOptions({
        dealerCode: this.cartData.dealerAddress?.name,
        deliveryMode: deliveryMode,
        entries: entriesToUpdate,
      });
      if (!response) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t('errorMessage.unableToProcess'),
        });
      }
    },
    openCheckoutModal() {
      this.setIsCheckoutDialogVisible(true);
    },
    closeCheckoutModal() {
      this.setIsCheckoutDialogVisible(false);
    },
    openCartDialog() {
      this.setIsCartProductDialogVisible(true);
    },
    closeCartDialog() {
      this.setIsCartProductDialogVisible(false);
    },
    async removeUnavailableCartItems() {
      await this.removeOutOfStockCartItems();
      this.$nextTick(() => {
        this.handleStartCheckout();
      });
    },
    async handleStartCheckout() {
      const isCartModifiedAfterValidation = await this.validateCart();
      if (!this.hasDeliveryModeSelectionsMade || isCartModifiedAfterValidation) {
        await this.getCartData();
        this.setHasClickedProceedWithoutDeliveryModeSelected(true);
        delayedScrollToElement('[qaid="choose-delivery-method-header"]', 20, 150);
        return;
      }

      try {
        if (!this.isUserLoggedIn) {
          await this.revertToAnonymousCart();
        }
        this.trackProceedCheckout();
        window.location = this.checkoutUrl;
      } catch (error) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t('errorMessage.unableToProcess'),
        });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .pdl-collapse-item__header {
  border: none;
}

::v-deep .pdl-collapse-item__wrap {
  border: none;
}
</style>
