<template>
  <div>
    <pdl-subheading size="sm" qaid="drawer-secondary-heading" class="mb-1">
      {{ $t('myCart.pickUpAvailability.product') }}
    </pdl-subheading>
    <pdl-media-object :qaid="`drawer-product-item-${entryItem.product.code}`">
      <a slot="media" qaid="drawer-product-image" :href="entryItem.product.url">
        <image-component
          :alt-text="thumbnailAsset.altText"
          :title="thumbnailAsset.altText"
          aspect-ratio="4x3"
          sizes="auto"
          :asset-id="productImage"
          img-format="pjpg"
          transformation="pad"
          :is-responsive="true"
        />
      </a>
      <template #content>
        <div class="space-y-3">
          <div qaid="drawer-product-summary">
            <p qaid="drawer-product-name" class="font-bold text-md">{{ entryItem.product.name }}</p>
            <ul qaid="drawer-product-details" class="text-gray-60 my-1 text-sm">
              <li
                v-for="(category, i) of entryItem.product.variantMatrix"
                :key="`category-${i}`"
                :qaid="`drawer-category-${getCategoryQaid(category)}`"
              >
                {{ `${category.parentVariantCategory.name}: ${category.variantValueCategory.name}` }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </pdl-media-object>
  </div>
</template>

<script>
import {PdlMediaObject} from '@pedal/pdl-media-object';
import {PdlSubheading} from '@pedal/pdl-section-header';
import ImageComponent from '@/components/ImageComponent.vue';
import {mapState} from 'vuex';

export default {
  components: {
    PdlMediaObject,
    PdlSubheading,
    ImageComponent,
  },
  computed: {
    ...mapState('backend', ['azureCdnImagesUrl']),
    ...mapState('cart', ['outOfStockProductSku', 'cartData']),

    thumbnailAsset() {
      return this.entryItem?.product?.images[0];
    },
    productImage() {
      const missingProductImage = `${this.azureCdnImagesUrl}/missing-product-300x300.jpg`;
      const url = this.thumbnailAsset?.url ?? '';
      return url.length > 0 ? url : missingProductImage;
    },
    entryItem() {
      return this.cartData?.entries?.find((item) => item.product.code === this.outOfStockProductSku);
    },
  },
  methods: {
    getCategoryQaid(category) {
      return category.parentVariantCategory.code ?? category.parentVariantCategory.name;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .pdl-media-object__media {
  width: 10rem;
}
</style>
