<template>
  <div class="grid-container my-5">
    <pdl-section-header size="xl">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('login.login') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <div v-if="viewBreakpointIs('small')">
      <pdl-collapse accordion :default-active="false" :value="['guest']" class="mb-2" qaid="checkout-login-collapse">
        <pdl-collapse-item name="guest">
          <div slot="title" class="heading heading--sm">{{ $t('guest.checkout') }}</div>
          <guest-checkout :base-properties="baseProperties" :options="options" />
        </pdl-collapse-item>
        <pdl-collapse-item name="login">
          <div slot="title" class="heading heading--sm">{{ $t('login.title') }}</div>
          <user-login :base-properties="baseProperties" :options="options" :error-messages="errorMessages" />
        </pdl-collapse-item>
      </pdl-collapse>
      <div class="text-sm">
        <a :href="withContextPath('/login/')" class="hover:underline">{{ $t('checkout.registration.link') }}</a>
      </div>
    </div>
    <div v-else class="flex my-5">
      <div class="w-1/2 border-r border-gray-10">
        <div class="max-w-sm md:mr-2 lg:mx-6">
          <div class="text-lg font-semibold mb-2">{{ $t('guest.checkout') }}</div>
          <guest-checkout :base-properties="baseProperties" :options="options" />
        </div>
      </div>
      <div class="w-1/2">
        <div class="max-w-sm md:ml-2 lg:mx-6">
          <div class="text-lg font-semibold mb-2">{{ $t('login.title') }}</div>
          <user-login
            :base-properties="baseProperties"
            :options="options"
            :error-messages="errorMessages"
            class="mb-2"
          />
          <div class="text-sm">
            <a :href="withContextPath('/login/')" class="hover:underline">{{ $t('checkout.registration.link') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserLogin from '@/components/containers/my-account/UserLogin';
import GuestCheckout from '@/components/containers/checkout/GuestCheckout';
import {Url} from '@/utils/url';
import {mapState, mapGetters} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  name: 'LoginCheckoutContainer',
  components: {UserLogin, GuestCheckout, PdlSectionHeader, PdlHeading},
  props: {
    options: {
      type: Object,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointIs']),
  },
  methods: {
    withContextPath: (url) => Url.withContextPath(url),
  },
};
</script>
