import get from 'lodash/get';
import clone from 'lodash/clone';
import merge from 'lodash/merge';
import debounce from 'lodash/debounce';
import {Url} from '@/utils/url';
import storefrontInstance from '@/api/instances/storefront';

const paginationDefaults = {
  page: 0,
  pageSize: 10,
  totalResults: 0,
};

const saleFilterId = 'ENUMFEATUREPOSFLAG001';

const LocatorApi = {
  props: {
    filtersData: {
      type: Object,
      default: () => null,
    },
  },
  created() {
    this.initServiceAndSaleFilters();
  },
  computed: {
    hasStores() {
      return this.stores && this.stores.length > 0;
    },
  },
  methods: {
    async getSearchOptions() {
      const textSearchQuery = this.textSearchQuery.substring(0, 100);

      let options = {
        searchTerm: textSearchQuery,
        page: this.pagination.page,
        timeStamp: new Date().getTime(),
        siteUid: ACC.siteUid,
      };

      if (this.isProjectOneRetailerSearch) {
        options.projectOne = true;
        options.services = 'JDE_P1';
      }

      if (this.textSearchCenter?.length) {
        options.longitude = this.textSearchCenter[0];
        options.latitude = this.textSearchCenter[1];
        const RESET_COORDINATES_AFTER_USE = [];
        this.textSearchCenter = RESET_COORDINATES_AFTER_USE;
      }

      if (this.searchBounds) {
        options.mapCenter = encodeURI(JSON.stringify(this.centerCoords));
        options.bounds = encodeURI(JSON.stringify(this.searchBounds));
      }

      if (this.filters && Object.keys(this.filters).length > 0) {
        for (let filter in this.filters) {
          options[filter] = this.filters[filter];
        }
      }

      return options;
    },

    /**
     * Initialize list of service an sale filters.
     */
    initServiceAndSaleFilters() {
      const data = this.filtersData;

      if (!data || !Object.keys(data).length) {
        return [];
      }

      for (let service in data) {
        if (!data[service] || !data[service].length) {
          continue;
        }

        for (let item of data[service]) {
          const target = get(item, 'featuresPOSFlag') === saleFilterId ? 'saleFilters' : 'serviceFilters';
          this.$set(this, target, [...this[target], item]);
        }
      }
    },

    newSearch() {
      this.searchBounds = null;
      this.quickViewId = null;
      this.loadData(0, true);
    },

    /**
     * Load data via API.
     */
    loadData: debounce(async function (page = 0, isNewTextSearch = false, isFilterChange = false) {
      const previousPage = this.pagination.page;
      this.isLoading = true;
      this.pagination.page = page;
      this.initialStoreIdFromNewSearch = '';

      this.searchStores(await this.getSearchOptions())
        .then((data) => {
          this.pagination = data.pagination;
          this.stores = data.stores && data.stores.length ? this.sortStores(data.stores) : [];

          if (!this.searchBounds) {
            this.setMapBounds({
              isNewTextSearch,
              isFilterChange,
              geoPointForSearchTerm: data.geoPointForSearchTerm,
              displayBounds: data.displayBounds,
              previousPage,
              currentPage: page,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 1000),

    /**
     * Sort stores to make sure "My Store" is always on top.
     * @param stores
     * @return {*}
     */
    sortStores(stores) {
      if (!stores || !stores.length) {
        return stores;
      }

      return stores.sort((a, b) => {
        if (a.isMyStore) return -1;
        return a.isMyStore !== b.isMyStore ? 1 : 0;
      });
    },

    /**
     * Perform an AJAX search query.
     * @return {Promise}
     */
    searchStores(options) {
      const apiUrl = Url.create('/store-finder/search', options);
      return new Promise((resolve, reject) => {
        storefrontInstance
          .post(apiUrl)
          .then((response) => {
            let displayBounds = {};
            if (
              get(response.data, 'data.trekMapBoundaries.top') &&
              Number.isFinite(response.data.data.trekMapBoundaries.top)
            ) {
              // These boundaries encase all the stores in the result set
              displayBounds = {
                north: response.data.data.trekMapBoundaries.top,
                east: response.data.data.trekMapBoundaries.right,
                south: response.data.data.trekMapBoundaries.bottom,
                west: response.data.data.trekMapBoundaries.left,
              };
            }
            resolve({
              stores: get(response.data, 'data.stores', []),
              pagination: merge(clone(paginationDefaults), get(response.data, 'data.storeSearchPagination', {})),
              displayBounds: displayBounds,
              geoPointForSearchTerm: get(response.data, 'data.geoPointForSearchTerm'),
            });
          })
          .catch((reason) => {
            reject(reason);
          });
      });
    },
  },
  data() {
    return {
      isLoading: false,
      stores: [],
      serviceFilters: [],
      saleFilters: [],
      pagination: clone(paginationDefaults),
      textSearchQuery: '',
      lastRequestTimestamp: 0,
      centerCoords: {},
      searchBounds: [],
    };
  },
};

export {LocatorApi};
