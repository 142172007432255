<template>
  <div qaid="productCompareTechSpecsWrapper">
    <div v-for="group in sectionData.specGroups" :key="group.label">
      <div class="prod-compare__table__subheader" qaid="productCompareTechSpecsSubheader">
        <span>{{ group.label }}</span>
      </div>
      <table
        class="sprocket__table sprocket__table--vstripe spec prod-compare__table"
        qaid="productCompareTechSpecsTable"
      >
        <caption class="sr-only">
          {{
            $t('product.details.nav.label.techSpecs')
          }}
        </caption>
        <tbody>
          <template v-for="item in group.compareItems">
            <tr :key="item.code" class="compare-chart__row" :qaid="`productCompareTechSpecsRow-${item.code}`">
              <th scope="row" :qaid="`productCompareTechSpecsRow-${item.code}`" v-text="item.label"></th>
              <td
                v-for="(product, sku) in metaData.products"
                :key="getKey(item.code, sku)"
                class="compare-chart__cell"
                :data-spec="item.label"
                :qaid="`productCompareTechSpecsCell-${item.code}-${sku}`"
              >
                <template v-if="typeof item.products[sku] === 'string'">
                  <div :qaid="`productCompareTechSpecsText-${item.code}-${sku}`">{{ item.products[sku] }}</div>
                </template>
                <template v-else-if="Array.isArray(item.products[sku])">
                  <div
                    v-for="(bomLine, index) in item.products[sku]"
                    :key="index"
                    :class="index > 0 ? 'mt-3' : ''"
                    :qaid="`productCompareTechSpecsBom-${item.code}-${sku}`"
                  >
                    <span v-if="bomLine.sizes" :qaid="`productCompareTechSpecsBomSizes-${item.code}-${sku}`">
                      {{ getBOMSizesText(bomLine.sizes) }}
                    </span>
                    <div v-if="bomLine.displayText" :qaid="`productCompareTechSpecsBomText-${item.code}-${sku}`">
                      {{ bomLine.displayText }}
                    </div>
                    <div
                      v-else-if="typeof bomLine === 'string'"
                      :qaid="`productCompareTechSpecsBomText-${item.code}-${sku}`"
                    >
                      {{ bomLine }}
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sectionData: {
      type: Object,
      default: () => ({
        url: '',
        name: '',
        useSpecItem: false,
        images: [],
      }),
    },
    sectionKey: {
      type: String,
      required: true,
    },
    metaData: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getKey(code, sku) {
      return `${sku}${code}`;
    },

    getBOMSizesText(sizes) {
      let result = '';

      if (Array.isArray(sizes) && sizes.length) {
        result = `${this.bp('specSizes')}: `;
        sizes.forEach((size, index) => {
          result += size;
          if (index + 1 < sizes.length) result += ', ';
        });
      }

      return result;
    },
  },
};
</script>
