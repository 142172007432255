<template>
  <div class="grid-container">
    <div v-if="viewBreakpointIs('small')" class="my-4">
      <pdl-collapse accordion :default-active="false" :value="['login']" qaid="login-collapse">
        <pdl-collapse-item name="login">
          <div slot="title" class="heading heading--lg">
            {{ $t('login.login') }}
          </div>
          <user-login :options="options" :error-messages="errorMessages" />
        </pdl-collapse-item>
        <pdl-collapse-item name="register">
          <div slot="title" class="heading heading--lg">
            {{ $t('register.new.customer') }}
          </div>
          <user-register :options="options" :distance-unit="distanceUnit">
            <template #privacy-notice>
              <slot name="register-privacy-notice" />
            </template>
          </user-register>
        </pdl-collapse-item>
      </pdl-collapse>
    </div>
    <div v-else class="flex my-5">
      <div class="w-1/2 border-r border-gray-10">
        <div class="max-w-sm md:mr-2 lg:mx-6">
          <pdl-section-header size="xl" class="mb-5">
            <template slot="content">
              <pdl-heading :level="1">{{ $t('login.login') }}</pdl-heading>
            </template>
          </pdl-section-header>
          <div class="text-lg font-semibold mb-2">{{ $t('login.title') }}</div>
          <user-login :options="options" :error-messages="errorMessages" />
        </div>
      </div>
      <div class="w-1/2">
        <div class="max-w-sm md:ml-2 lg:mx-6">
          <pdl-section-header size="xl" class="mb-5">
            <template slot="content">
              <pdl-heading :level="1">{{ $t('register.new.customer') }}</pdl-heading>
            </template>
          </pdl-section-header>
          <div class="text-lg font-semibold mb-2">{{ $t('guest.checkout') }}</div>
          <user-register :options="options" :distance-unit="distanceUnit">
            <template #privacy-notice>
              <slot name="register-privacy-notice" />
            </template>
          </user-register>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import UserLogin from '@/components/containers/my-account/UserLogin';
import UserRegister from '@/components/containers/my-account/UserRegister';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';
import {mapState, mapGetters} from 'vuex';

export default {
  name: 'LoginContainer',
  components: {PdlCollapse, PdlCollapseItem, PdlSectionHeader, PdlHeading, UserRegister, UserLogin},
  extends: UserAccountBase,
  props: {
    options: {
      type: Object,
      required: true,
    },
    distanceUnit: {
      type: String,
      default: '',
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      isLoginVisible: true,
    };
  },
  computed: {
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointIs']),
  },
};
</script>
