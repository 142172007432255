<template>
  <div class="grid-container">
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('text.company.manageusers.label') }}</pdl-heading>
      </template>
      <template slot="actions">
        <pdl-link button primary small icon="add" :href="addNewUserUrl" qaid="user-list-button-add">
          {{ $t('text.addNew') }}
        </pdl-link>
      </template>
    </pdl-section-header>
    <div>
      <div v-if="users">
        <div class="grid-x grid-margin-x">
          <div class="cell medium-6 text-gray-80 italic">{{ users.length }} {{ $t('text.usersFound') }}</div>
          <div class="cell medium-6 flex md:justify-end">
            <div class="form-group">
              <div class="form-group--inline">
                <label for="selectSortby" class="form-label">{{ $t('text.sortBy') }}</label>
                <div class="select select--clear">
                  <select id="selectSortby" v-model="sortKey" class="form-control">
                    <option
                      v-for="(column, colIndex) in tableHeaders"
                      :key="colIndex"
                      :value="column.key"
                      qaid="`sort-list.option-${column.key}`"
                    >
                      {{ column.label }}
                    </option>
                  </select>
                  <div class="focus-indicator"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="section pt-0">
          <users-grid :grid-data="sortedUsers" :columns="tableHeaders" :id-key="uid" />
        </section>
      </div>
      <div v-else>
        {{ $t('text.company.manageUser.noUser') }}
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import UsersGrid from '@/components/containers/my-company/UsersGrid';
import {PdlLink} from '@pedal/pdl-link';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {UsersGrid, PdlLink, PdlSectionHeader, PdlHeading},
  provide() {
    return {
      container: this,
    };
  },

  props: {
    userData: {
      type: Object,
      default: () => null,
    },
    textNoUserMessage: {
      type: String,
      default: '',
    },
    textUsersFound: {
      type: String,
      default: '',
    },
    textSortBy: {
      type: String,
      default: '',
    },
    uid: {
      type: String,
      default: '',
    },
    addNewUserUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      sortKey: 'name',
    };
  },

  computed: {
    users() {
      if (!this.userData.users) return [];
      return this.userData.users;
    },

    sortDirection() {
      return this.sortKey === 'userStatus' ? 'desc' : 'asc';
    },

    sortedUsers() {
      return orderBy(
        this.users,
        [(user) => (user[this.sortKey] ? user[this.sortKey].toLowerCase() : null)],
        [this.sortDirection]
      );
    },

    hasUsersWithRideClubRole() {
      if (!this.userData.users) return false;
      return this.userData.users.some((user) => user.rideClubRole);
    },

    tableHeaders() {
      const tableHeaders = [
        {label: this.$t('text.name'), key: 'name'},
        {label: this.$t('b2busergroup.B2BRole'), key: 'role'},
        {label: this.$t('b2busergroup.rideClubRole'), key: 'rideClubRole'},
        {label: this.$t('text.address.email'), key: 'emailAddress'},
        {label: this.$t('text.status'), key: 'userStatus'},
      ];
      if (this.hasUsersWithRideClubRole) return tableHeaders;
      return tableHeaders.filter((header) => header.key !== 'rideClubRole');
    },
  },
};
</script>
