import storefront from '@/api/instances/storefront';
import occInstance from '@/api/instances/occ';
import {ApiDefaults} from '@/constants/api.js';

const baseUrl = `/checkout/multi/delivery-address`;

const convertToJSON = (formData) => {
  const jsonFormData = {};
  formData.forEach((value, key) => {
    jsonFormData[key] = value;
  });
  return jsonFormData;
};

const DeliveryAddressApi = {
  async addDeliveryAddress(formData) {
    const url = `${baseUrl}/add`;
    return storefront.post(url, formData);
  },

  async getRegions(countryIsoCode, fields = ApiDefaults.FULL) {
    try {
      const url = `countries/${countryIsoCode}/regions`;
      const params = {
        fields,
      };
      const response = await occInstance.get(url, {params});
      return response?.data?.regions;
    } catch (error) {
      console.error('Failed to fetch the regions', error);
    }
  },

  async getAddressValidationConfig(fields = ApiDefaults.DEFAULT) {
    try {
      const url = `address/addressValidationConfigs`;
      const params = {
        fields,
      };
      const response = await occInstance.get(url, {params});
      return response?.data;
    } catch (error) {
      console.error('Failed to fetch the address validation config', error);
    }
  },

  async pdpDeliveryAddressValidation(data = {}, fields = ApiDefaults.DEFAULT) {
    const url = `address/validate`;
    const params = {
      fields,
    };
    const response = await occInstance.post(url, data, {params});
    return response;
  },
};

export {convertToJSON, DeliveryAddressApi};
