<template>
  <pdl-drawer size="23rem" show-close :is-open="isDrawerOpen" @close="handleClose">
    <template #content>
      <div class="space-y-8">
        <pdl-heading size="md" qaid="pricing-drawer-header">
          {{ $t('text.buyingzone.retailerPrice.details') }}
        </pdl-heading>
        <div class="mt-7">
          <p class="font-bold">{{ $t('text.buyingzone.retailerPrice') }}</p>
          <p class="mt-1">
            {{ $t('checkout.multi.pickupInStore.retailerPricing.desc') }}
          </p>
        </div>
        <div class="mt-7">
          <p class="font-bold">{{ $t('text.msrp') }}</p>
          <p class="mt-1">
            {{ $t('text.buyingzone.retailerPrice.details.message') }}
          </p>
        </div>
      </div>
    </template>
  </pdl-drawer>
</template>

<script>
import {PdlDrawer} from '@pedal/pdl-drawer';
import {PdlHeading} from '@pedal/pdl-section-header';
export default {
  components: {PdlDrawer, PdlHeading},
  props: {
    isDrawerOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.pdl-drawer {
  z-index: 16000009;
}
</style>
