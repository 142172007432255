import storefrontInstance from '@/api/instances/storefront';

/**
 * Force browser to download given data.
 * @param data
 * @param options
 */
const forceBrowserToDownload = (data, options) => {
  const blob = new Blob([data], {type: options.mimeType});

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE Fallback
    window.navigator.msSaveBlob(blob, options.filename);
  } else {
    const blobUrl = window.URL.createObjectURL(blob);
    const linkEl = document.createElement('a');

    linkEl.style.display = 'none';
    linkEl.href = blobUrl;
    linkEl.setAttribute('download', options.filename);

    if (typeof linkEl.download === 'undefined') {
      linkEl.setAttribute('target', '_blank');
    }

    document.body.appendChild(linkEl);
    linkEl.click();

    window.setTimeout(() => {
      document.body.removeChild(linkEl);
      window.URL.revokeObjectURL(blobUrl);
    }, 0);
  }
};

/**
 * Download by given URL.
 * @param url
 * @param filename
 * @param mimeType
 * @param axiosOptions
 * @return {Promise}
 */
const downloadFile = (url, filename, mimeType = 'application/octet-stream', axiosOptions = {}) => {
  // Make sure the URL doesn't contain part of the base URL
  url = url.replace(storefrontInstance.defaults.baseURL, '');

  const options = Object.assign(
    {},
    {
      url,
      method: 'GET',
      responseType: 'blob',
    },
    axiosOptions
  );

  return new Promise((resolve, reject) => {
    storefrontInstance(options)
      .then((response) => {
        forceBrowserToDownload(response.data, {mimeType, filename});
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export {downloadFile};

export function downloadCalendarFile(response, eventTitle) {
  const blob = new Blob([response.data], {type: 'text/calendar'});
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = `${eventTitle}.ics`;
  document.body.appendChild(link);
  link.click();
  window.setTimeout(() => {
    document.body.removeChild(link);
  }, 0);
}
