import occInstance from '@/api/instances/occ';
import {ApiDefaults} from '@/constants/api.js';

const BenefitMessagingApi = {
  async freeShippingThreshold(countryIsoCode, fields = ApiDefaults.DEFAULT) {
    try {
      const url = `benefit/message/${countryIsoCode}/determinantThreshold`;
      const params = {
        fields,
      };
      const response = await occInstance.get(url, {params});
      return response?.data?.determinantThreshold;
    } catch (error) {
      console.error('Failed to fetch the free shipping threshold', error);
    }
  },
};

export {BenefitMessagingApi};
