var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"mb-0"},[_c('caption',{staticClass:"sr-only"},[_vm._v("\n      "+_vm._s(_vm.$t('text.notifications.setting.breadcrumbs.title'))+"\n    ")]),_vm._v(" "),_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:`${column.key}-header`,staticClass:"b2b-grid__cell b2b-grid__head",class:[column.cssClasses],attrs:{"data-type":`${column.type}`}},[_vm._v("\n          "+_vm._s(column.label)+"\n        ")])}),0)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.items),function(item,itemIndex){return [_c('tr',{key:item.id},_vm._l((_vm.columns),function(column,index){return _c('td',{key:`${item.id}-${column.key}-cell`,staticClass:"b2b-grid__cell table-cell",class:[
              column.cssClasses,
              item.cssClasses,
              {
                'font-bold': item.selectAll,
                'bg-gray-02': (itemIndex + 1) % 2 === 0,
              },
            ]},[_c('div',{staticClass:"b2b-grid__content"},[(column.type === 'checkbox' && !item.selectAll)?_c('div',{staticClass:"text-right"},[_c('label',{staticClass:"checkbox justify-end"},[_c('input',{attrs:{"id":`${item.id}-${column.key}`,"type":"checkbox","disabled":column.key === 'online'},domProps:{"checked":item[column.key]}}),_vm._v(" "),_c('span',{staticClass:"control-indicator mr-0"})])]):(!item.selectAll || index === 0)?_c('span',[_vm._v(_vm._s(item[column.key]))]):_vm._e()])])}),0)]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }