<template>
  <pdl-callout role="alert" kind="error">
    <template #content>
      <section qaid="cart-error-message" class="mb-1">
        {{ $t('myCart.notAvailable.selectedRetailer') }}
      </section>
      <pdl-button button alert xsmall qaid="cart-unavailable-remove-button" @click="$emit('handle-click')">
        {{ $t('myCart.notAvailable.selectedRetailer.remove') }}
      </pdl-button>
    </template>
  </pdl-callout>
</template>

<script>
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlButton} from '@pedal/pdl-button';

export default {
  name: 'CartErrors',
  components: {
    PdlCallout,
    PdlButton,
  },
};
</script>
