import occInstance from '@/api/instances/occ';

const rootUrl = '/rcp/appintegrations';
const AppIntegrationApi = {
  async authorizeUserTo3rdPartyApp(appName) {
    const url = `${rootUrl}/${appName}/authorize`;
    const {data: authorizationURI} = await occInstance.post(url);
    return authorizationURI;
  },
  async deAuthorizeUserTo3rdPartyApp(appName) {
    const url = `${rootUrl}/${appName}/deauthorize`;
    const {data: deAuthorizationURI} = await occInstance.post(url);
    return deAuthorizationURI;
  },
  async fetchAppsIntegrationStatus() {
    const url = `${rootUrl}/status`;
    const {data: integrationStatus} = await occInstance.get(url);
    return integrationStatus;
  },
};

export default AppIntegrationApi;
