<template>
  <div class="space-y-1 p-2 bg-white" :qaid="`${benefit.benefitId}-benefit-messaging`">
    <div class="flex gap-x-1 items-center">
      <component :is="benefit.benefitIcon"></component>
      <span :qaid="`${benefit.benefitId}-benefit-header`" class="text-md font-bold">{{
        $t(benefit.benefitHeader)
      }}</span>
    </div>
    <p :key="updateKey" class="leading-normal text-sm">
      {{ dynamicBenefitMessage }}
    </p>
  </div>
</template>

<script>
import ShipToHomeIcon from '@/components/containers/pdp/b2c/fulfillment-options/home-shipping/ShipToHomeIcon.vue';
import PremiumHomeDeliveryIcon from '@/components/containers/pdp/b2c/fulfillment-options/premium-home-delivery/PremiumHomeDeliveryIcon.vue';

export default {
  components: {ShipToHomeIcon, PremiumHomeDeliveryIcon},
  props: {
    benefit: {
      type: Object,
      required: true,
    },
    freeShippingThreshold: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      updateKey: crypto.randomUUID(),
    };
  },
  computed: {
    dynamicBenefitMessage() {
      return this.$t(this.benefit.benefitDescription, [this.freeShippingThreshold]);
    },
  },
  watch: {
    dynamicBenefitMessage() {
      this.updateKey = crypto.randomUUID();
    },
  },
};
</script>
