<template>
  <tr
    v-show="row.expanded"
    class="table-row table-row--child order-item-details mt-0"
    :class="{'is-expanded': row.expanded}"
  >
    <td class="b2b-grid__cell block lg:table-cell lg:px-2 overflow-visible h-auto" :colspan="totalColumns">
      <template
        v-if="
          dataLoaded &&
          ((order.allocatedItems && order.allocatedItems.length) ||
            (order.notAllocatedItems && order.notAllocatedItems.length))
        "
      >
        <pdl-tabs :value="defaultTab">
          <pdl-tab-pane v-for="tab in tabs" :key="`${tab.id}-${order[tab.key].length}`" :name="tab.id">
            <div slot="label" class>{{ tab.label }} ({{ order[tab.key].length }})</div>
            <table class="b2b-grid b2b-grid--collapse">
              <caption class="sr-only">
                {{
                  $t('order.orderItems')
                }}
              </caption>
              <thead v-if="order[tab.key].length" class="b2b-grid__header">
                <tr>
                  <th class="b2b-grid__cell b2b-grid__head">
                    <!-- For Icons -->
                  </th>
                  <th
                    v-for="(column, index) in getColumns(tab.key)"
                    :key="column.key + index"
                    class="b2b-grid__cell b2b-grid__head"
                    :class="[column.cssClasses]"
                  >
                    <span v-html="column.label"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="order[tab.key].length">
                  <tr v-for="(item, itemIndex) in order[tab.key]" :key="item.id + itemIndex" class="table-row">
                    <td class="b2b-grid__cell lg:table-cell whitespace-nowrap">
                      <div class="flex flex-nowrap">
                        <pdl-icon v-if="item.isSplitWarehouse" name="call_split" size="18" />
                        <grid-note
                          v-show="item.lineComment || false"
                          :row-id="item.id"
                          :data="{
                            value: 'insert_drive_file',
                            content: item.lineComment,
                            editable: false,
                            cssClass: 'ml-1',
                          }"
                        />
                      </div>
                    </td>

                    <td
                      v-for="column in getColumns(tab.key)"
                      :key="`${item.id}-${column.key}`"
                      class="b2b-grid__cell lg:table-cell"
                      :class="[column.cssClasses]"
                    >
                      <div class="b2b-grid__label" v-html="column.label"></div>

                      <div class="b2b-grid__content">
                        <!-- Cancel Date cell -->
                        <div v-if="column.key === 'cancelDate'">
                          <pdl-icon v-if="item.showCancelFlag" class="text-warning" :name="warning" />
                          <span v-html="item[column.key]"></span>
                        </div>
                        <!-- All other cells -->
                        <span v-if="column.key !== 'cancelDate'" v-html="item[column.key]"></span>

                        <span v-if="column.key === 'sku'">{{ item.product.code }}</span>

                        <span v-if="column.key === 'itemName'">{{ item.product.itemName }}</span>

                        <span v-if="column.key === 'upc'">{{ item.product.upc }}</span>

                        <grid-eta-watch
                          v-if="column.key === 'etaWatch'"
                          :eta-item="item"
                          :update-eta-watch-payload="updateEtaWatchPayload(item)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-if="!order[tab.key].length">
                  <td class="bg-gray-05 p-3 text-center font-medium" :colspan="totalColumns">
                    <span v-if="tab.key === 'allocatedItems'" key="emptyText">
                      {{ $t('orderHistory.B2B.noAllocatedItems') }}
                    </span>
                    <span v-else key="emptyText">{{ $t('orderHistory.B2B.noBackorderedItems') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <section v-if="order[tab.key].length" class="mb-2">
              <div class="grid-x grid-margin-x">
                <div class="cell medium-6 large-8">
                  <!-- Pagination -->
                </div>

                <div v-if="row.subtotals && row.subtotals[tab.subtotalKey]" class="cell medium-6 large-4">
                  <ul v-if="!isConsumerFriendlyMode" class="block-list text-md border-b border-gray-10">
                    <li class="block-list__item text-base font-medium">
                      <span>{{ $t('text.shipmentTotal') }}</span>
                      <span v-if="row.subtotals[tab.subtotalKey]['prices']['subtotal']">{{
                        row.subtotals[tab.subtotalKey]['prices']['subtotal']['formattedValue']
                      }}</span>
                    </li>
                  </ul>
                  <div class="buttons buttons--right-for-md mt-2">
                    <trek-link button secondary small :href="grid.linkManageAllocatedItems">
                      {{ $t('text.manageAllocatedItems') }}
                    </trek-link>
                  </div>
                </div>
              </div>
            </section>
          </pdl-tab-pane>
        </pdl-tabs>
      </template>
    </td>
  </tr>
</template>

<script>
import {mapState} from 'vuex';
import storefrontInstance from '@/api/instances/storefront';
import TrekLink from '@/components/TrekLink';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import groupBy from 'lodash/groupBy';
import GridNote from '@/components/grid/GridNote';
import GridEtaWatch from '@/components/grid/GridEtaWatch';
import {ProductDeliveryStatus} from '@/constants/product';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {TrekLink, PdlTabs, PdlTabPane, GridNote, PdlIcon, GridEtaWatch},
  inject: ['grid'],

  props: {
    row: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dataLoaded: false,
    };
  },

  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),

    tabs() {
      return [
        {
          id: `${this.order.id}-tab-allocated`,
          label: this.$t('text.allocated'),
          key: 'allocatedItems',
          subtotalKey: ProductDeliveryStatus.ALLOCATED,
        },
        {
          id: `${this.order.id}-tab-notallocated`,
          label: this.$t('text.backordered'),
          key: 'notAllocatedItems',
          subtotalKey: ProductDeliveryStatus.BACKORDERED,
        },
      ];
    },

    order() {
      return this.row;
    },

    defaultTab() {
      if (this.order.allocatedItems.length) {
        return `${this.row.id}-tab-allocated`;
      } else {
        return `${this.row.id}-tab-notallocated`;
      }
    },

    totalColumns() {
      // We have one statically created column for the icons
      return this.grid.columns.length + 1;
    },

    totalBackorderedItems() {
      if (!this.order.allocatedItems && !this.order.notAllocatedItems) {
        return 0;
      }

      return this.order.allocatedItems.length + this.order.notAllocatedItems.length;
    },
  },

  watch: {
    row(newRow, oldRow) {
      if (newRow.id !== oldRow.id && (newRow.hasBackorderedItems || newRow.hasAllocatedItems)) {
        this.$nextTick(() => {
          this.fetchOrderDetails();
        });
      }
    },
  },

  created() {
    if (this.row.hasBackorderedItems || this.row.hasAllocatedItems) {
      this.fetchOrderDetails();
    }
  },

  methods: {
    getColumns(tabKey) {
      // The "Backordered" shouldn't have the Allocated or Cancel Date columns
      return this.grid.childColumns.filter((column) => this.filterColumns(column, tabKey));
    },

    filterColumns(column, tabKey) {
      if (tabKey === 'notAllocatedItems') {
        if (column.key === 'quantityAllocatedOrShipped' || column.key === 'cancelDate') return false;
      } else if (column.key === 'etaDate' || column.key === 'etaWatch') {
        return false;
      }
      return !(this.isConsumerFriendlyMode && (column.key === 'unitPrice' || column.key == 'lineSubtotal'));
    },

    fetchOrderDetails() {
      this.dataLoaded = false;

      const {orderNumber, orderType, orderCompany} = this.row;

      storefrontInstance
        .get(`/account/order/json/v2/${orderNumber}?company=${orderCompany}&ordertype=${orderType}`)
        .then(
          ({
            data: {
              data: {entries = {}, subtotals = {}},
            },
          }) => {
            let backorderedEntries = [];
            let allocatedEntries = [];

            let groupedObj = groupBy(entries, 'groupingType');

            for (const key in groupedObj) {
              if (key == ProductDeliveryStatus.BACKORDERED) {
                backorderedEntries = backorderedEntries.concat(groupedObj[key]);
              }
              if (key == ProductDeliveryStatus.ALLOCATED) {
                allocatedEntries = allocatedEntries.concat(groupedObj[key]);
              }
            }

            if (backorderedEntries) {
              backorderedEntries.forEach((element) => {
                element.lineSubtotal = element.totalPrice.formattedValue;
                element.unitPrice = element.basePrice.formattedValue;
                if (element.etaDate && element.etaDate === 'SOLD_OUT') {
                  element.etaDate = this.$t('text.buyingZone.soldOut');
                }
              });
            }

            if (allocatedEntries) {
              allocatedEntries.forEach(function (element) {
                element.lineSubtotal = element.totalPrice.formattedValue;
                element.unitPrice = element.basePrice.formattedValue;
              });
            }

            this.$set(this.order, 'allocatedItems', allocatedEntries);
            this.$set(this.order, 'notAllocatedItems', backorderedEntries);
            this.$set(this.order, 'subtotals', subtotals);

            this.dataLoaded = true;
          }
        );
    },

    updateEtaWatchPayload(item) {
      return {
        sku: item.product.code,
        warehouse: item.warehouse,
        orderLineNumber: item.orderLineNumber,
        orderNumber: this.row.orderNumber,
        orderType: this.row.orderType,
        orderCompany: this.row.orderCompany,
      };
    },
  },
};
</script>
