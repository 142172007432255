<template>
  <section>
    <pdl-callout v-if="errorMessage" kind="error" qaid="user-exists-alert">
      <template #content>
        <span>{{ errorMessage }}</span>
      </template>
    </pdl-callout>

    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('text.editUserDetails') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <div class="grid-x grid-margin-x mb-3">
      <div class="cell medium-6 large-5 max-w-sm">
        <fieldset>
          <div class="form-group">
            <label for="first-name" class="form-label">{{ $t('address.firstName') }} </label>
            <input
              id="first-name"
              type="text"
              readonly="true"
              name="first-name"
              qaid="first-name"
              required="required"
              :value="pickedFirstName"
              class="form-control"
            />
          </div>
          <div class="form-group is-required">
            <label for="last-name" class="form-label">{{ $t('profile.lastName') }} </label>
            <input
              id="last-name"
              type="text"
              readonly="true"
              name="last-name"
              required="required"
              qaid="last-name"
              :value="pickedLastName"
              class="form-control"
            />
          </div>
          <div class="form-group is-required">
            <label for="email-address" class="form-label">{{ $t('profile.email') }} </label>
            <input
              id="email-address"
              type="email"
              readonly="true"
              name="email-address"
              required="required"
              qaid="email-address"
              :value="pickedEmailAddress"
              class="form-control"
            />
          </div>
        </fieldset>

        <form ref="editForm" method="post" qaid="edit-user-details-form" @submit="submit">
          <fieldset class="form-fieldset">
            <input id="CSRFToken" type="hidden" :value="token" name="CSRFToken" />
            <legend class="form-fieldset__legend strong">{{ $t('b2busergroup.B2BRole') }}</legend>
            <label
              v-for="(item, index) in items.roles"
              :key="index"
              :qaid="`option-${index}`"
              :for="`option-${index}`"
              class="radio flex-wrap"
            >
              <input
                :id="`option-${index}`"
                v-model="b2bRole"
                type="radio"
                name="b2bUserRole"
                :value="item.id"
                :qaid="`option-${index}`"
                required="required"
                :disabled="!isTrekUUserEditFunctionalityEnabled"
              />
              <span class="control-indicator"></span>
              {{ $t(`${item.name}`) }}
              <span
                v-if="item.id === 'b2btrekuemployeepurchasegroup' && isTrekUEmployeePurchaseSelected"
                qaid="edit-employee-purchase-treku"
                class="form-help ml-5"
              >
                {{ $t('myCompany.trekU.updateUser') }}
              </span>
            </label>
          </fieldset>

          <fieldset class="form-fieldset" :disabled="isTrekUEmployeePurchaseSelected">
            <input id="CSRFToken" type="hidden" :value="token" name="CSRFToken" />
            <legend class="form-fieldset__legend strong">{{ $t('b2busergroup.update.B2BRole') }}</legend>
            <label qaid="updateAll" class="checkbox">
              <input v-model="isUpdatingB2bRoleAllStores" type="checkbox" name="b2bAllStores" :value="true" />
              <span class="control-indicator"></span>
              <div>
                {{ $t('b2busergroup.update.B2BRole.confirm') }}
              </div>
            </label>
          </fieldset>

          <fieldset v-if="items.rideClubRoles" class="form-fieldset">
            <legend class="form-fieldset__legend strong">{{ $t('b2busergroup.rideClubRole') }}</legend>
            <label
              v-for="(item, index) in items.rideClubRoles"
              :key="index"
              :qaid="`ride-club-option-${index}`"
              :for="`ride-club-option-${index}`"
              class="radio"
            >
              <input
                :id="`ride-club-option-${index}`"
                v-model="rideClubRole"
                type="radio"
                name="rideClubRole"
                :value="item.id"
                :qaid="`ride-club-option-${index}`"
                required="required"
              />
              <span class="control-indicator"></span>
              {{ $t(`${item.name}`) }}
            </label>
          </fieldset>

          <fieldset v-if="items.rideClubRoles" class="form-fieldset" :disabled="isTrekUEmployeePurchaseSelected">
            <legend class="form-fieldset__legend strong">{{ $t('b2busergroup.update.rideClubRole') }}</legend>
            <label qaid="updateAll" class="checkbox">
              <input v-model="isUpdatingRideClubRoleAllStores" type="checkbox" name="rideClubAllStores" :value="true" />
              <span class="control-indicator"></span>
              <div>
                {{ $t('b2busergroup.update.rideClubRole.confirm') }}
              </div>
            </label>
          </fieldset>

          <fieldset
            v-if="items.hasOwnProperty('isDstAuthorized')"
            qaid="dst-fieldset"
            class="form-fieldset"
            :disabled="isTrekUEmployeePurchaseSelected"
          >
            <input id="CSRFToken" type="hidden" :value="token" name="CSRFToken" />
            <legend qaid="dst-legend" class="form-fieldset__legend strong">{{ $t('myCompany.dstPermission') }}</legend>
            <label qaid="dst-permission" class="checkbox">
              <input v-model="isDstAuthorized" qaid="dst-checkbox" type="checkbox" name="dstPermission" :value="true" />
              <span class="control-indicator"></span>
              <div qaid="dst-label">
                {{ $t(`custom.saved.user`) }}
              </div>
            </label>
          </fieldset>

          <fieldset class="form-fieldset" :disabled="!isTrekUUserEditFunctionalityEnabled">
            <legend class="form-fieldset__legend strong">{{ $t('text.userStatus') }}</legend>
            <div class="sprocket__toggle-switch mr-2">
              <input id="active-user" v-model="pickedStatus" class="switch-input" type="checkbox" name="active" />
              <label class="switch-paddle" for="active-user"></label>
              <span class="text-black font-medium ml-1">{{ userStatusToggleIndicator }}</span>
            </div>
          </fieldset>

          <div class="buttons">
            <pdl-button primary qaid="save-edit-button" type="submit" :disabled="!isTrekUUserEditFunctionalityEnabled">
              {{ $t('text.button.save') }}
            </pdl-button>
            <pdl-link button secondary :href="cancelButtonRef" qaid="cancel-edit-button">
              {{ $t('text.account.profile.cancel') }}
            </pdl-link>
          </div>
        </form>
      </div>

      <pdl-dialog
        :visible.sync="dialogVisible"
        :title="$t('b2busergroup.updateAll.title')"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :z-index="16000011"
        qaid="update-role-all-stores-dialog"
      >
        <p>{{ $t('b2busergroup.updateAll.confirmation') }}</p>
        <template slot="footer">
          <div class="buttons buttons--right">
            <pdl-button primary @click.stop="confirmUpdateAll">
              {{ $t('b2busergroup.updateAll.yes') }}
            </pdl-button>
            <pdl-button secondary @click.stop="closeDialog">
              {{ $t('text.account.profile.cancel') }}
            </pdl-button>
          </div>
        </template>
      </pdl-dialog>
    </div>
  </section>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlLink} from '@pedal/pdl-link';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {useFunctionalityToggle} from '@/composables/functionality-toggle.js';
import {FunctionalityToggle} from '@/constants/functionality-toggle.js';
import {useIsTranslationLoaded} from '@/composables/is-translation-loaded.js';

export default {
  components: {PdlDialog, PdlLink, PdlButton, PdlSectionHeader, PdlHeading},

  props: {
    items: {
      type: Object,
      default: () => null,
    },
    token: {
      type: String,
      default: '',
    },
    cancelButtonRef: {
      type: String,
      default: '',
    },
  },

  setup() {
    const {isToggleActive: isTrekUUserMgmtFunctionalityEnabled} = useFunctionalityToggle(
      FunctionalityToggle.TREKU_EMPLOYEE_PURCHASE_USER_MGMT
    );
    return {isTrekUUserMgmtFunctionalityEnabled};
  },

  data() {
    return {
      b2bRole: '',
      rideClubRole: '',
      pickedFirstName: '',
      pickedLastName: '',
      pickedEmailAddress: '',
      pickedStatus: false,
      isUpdatingB2bRoleAllStores: false,
      isUpdatingRideClubRoleAllStores: false,
      dialogVisible: false,
      canSubmit: true,
      isDstAuthorized: false,
      errorMessage: '',
    };
  },

  computed: {
    userStatusToggleIndicator() {
      return this.$t(`${this.pickedStatus ? 'text.active' : 'text.inactive'}`);
    },

    isTrekUEmployeePurchaseSelected() {
      return this.b2bRole === 'b2btrekuemployeepurchasegroup';
    },

    isTrekUUserEditFunctionalityEnabled() {
      return this.items.selectedRole !== 'b2btrekuemployeepurchasegroup' || this.isTrekUUserMgmtFunctionalityEnabled;
    },
  },

  watch: {
    isUpdatingB2bRoleAllStores(value) {
      if (value === true) {
        this.canSubmit = false;
      }
    },
    isUpdatingRideClubRoleAllStores(value) {
      if (value === true) {
        this.canSubmit = false;
      }
    },
    b2bRole() {
      this.isUpdatingB2bRoleAllStores = this.isTrekUEmployeePurchaseSelected;
    },
  },

  created() {
    if (!this.items) return;
    this.b2bRole = this.items.selectedRole;
    this.rideClubRole = this.items.selectedRideClubRole;
    this.pickedStatus = this.items.userStatus;
    this.pickedFirstName = this.items.firstName;
    this.pickedLastName = this.items.lastName;
    this.pickedEmailAddress = this.items.emailAddress;
    this.isDstAuthorized = this.items.isDstAuthorized;

    useIsTranslationLoaded(() => {
      this.errorMessage = this.$t(this.items.userAlreadyExists);
      return this.errorMessage;
    });
  },

  methods: {
    submit(e) {
      if ((this.isUpdatingB2bRoleAllStores || this.isUpdatingRideClubRoleAllStores) && !this.canSubmit) {
        this.dialogVisible = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    },
    confirmUpdateAll() {
      this.dialogVisible = false;
      this.canSubmit = true;
      this.$refs.editForm.submit();
    },
    closeDialog() {
      this.updateAll = false;
      this.dialogVisible = false;
    },
  },
};
</script>
