'use strict';
//Link to the latest dependencies investigation results: https://trekbikes.atlassian.net/browse/TBN-7794?focusedCommentId=390092
import selectBox from '../modules/SelectBox';

export default function () {
  $(document).ready(function () {
    var retailerTargets = $('[data-options-target]');

    retailerTargets.on('click', function (event) {
      retailerTargets.removeClass('selected');
      $(event.currentTarget).addClass('selected');

      event.stopPropagation();
    });
  });

  function toggleRegisterButton(val) {
    if (val) {
      $('#register-button').removeAttr('disabled').removeClass('disabled');
    } else {
      $('#register-button').attr('disabled', 'disabled').addClass('disabled');
    }
  }

  $('#register-button').click(function (event) {
    event.preventDefault();
    $('#trekRegisterForm').submit();
  });

  if ($('#privacy-consent').length) {
    let privacyConsentCheckbox = $('#privacy-consent');
    toggleRegisterButton(privacyConsentCheckbox.is(':checked'));
    privacyConsentCheckbox.change(function () {
      toggleRegisterButton(privacyConsentCheckbox.is(':checked'));
    });
  }

  selectBox('titleCode', 'name');

  function defaultCheckboxValue(formField) {
    var optin = $('#' + formField + 'Val').val();
    if (optin == 'true') {
      $('#' + formField).attr('checked', 'checked');
    }
  }

  defaultCheckboxValue('optin');
  defaultCheckboxValue('j_remember');
}
