<template>
  <div class="space-y-12 bg-white retailer-select">
    <pdl-heading size="md" qaid="retailer-drawer-heading">{{ $t(headingDrawer) }}</pdl-heading>
    <product-card v-if="outOfStockProductSku" />
    <form class="space-y-2" @submit.prevent>
      <label for="retailer-search" class="font-medium" qaid="retailer-drawer-pickup-location">
        {{ $t('fulfillment.storePickup.selectLocation') }}
      </label>
      <div class="form-group--inline gap-x-4">
        <div class="relative grow">
          <form-group
            id="retailer-search"
            ref="retailerDrawerInput"
            v-model="input"
            :show-error-messages="true"
            :force-display-error="true"
            :class="{'has-error': Boolean(fieldError)}"
            name="retailer-search"
            :placeholder="$t('storelocator.postcode.city.search')"
            qaid="retailer-drawer-input"
            class="h-full"
            autocomplete="off"
            @input="handleInput"
            @keyup="handleKeyup"
            @keydown="handleKeydown"
          />
          <ul
            v-if="suggestionList.length"
            class="absolute w-full bg-white border-solid border-gray-20 border border-t-0 divide-y divide-gray-20 z-10"
            qaid="retailer-drawer-autocomplete-container"
          >
            <li
              v-for="(suggestion, i) in suggestionList"
              :key="`autocomplete-suggestion-${i}`"
              :qaid="`autocomplete-suggestion-${i}`"
              class="border-0 h-full form-control hover:bg-gray-05 cursor-pointer focus:bg-gray-05 text-sm"
            >
              <button @click="handleSearchFromSuggestion(suggestion)">
                {{ suggestion.value }}
              </button>
            </li>
          </ul>
        </div>
        <pdl-button
          primary
          small
          class="whitespace-nowrap button--inline"
          qaid="retailer-drawer-search"
          @click="handleSearch"
          >{{ $t('text.search') }}</pdl-button
        >
      </div>
      <span v-show="fieldError" class="text-red" qaid="retailer-drawer-error">{{ fieldError }}</span>
    </form>
  </div>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import {PdlHeading} from '@pedal/pdl-section-header';
import FormGroup from '@/components/FormGroup.vue';
import {
  MapboxGeocodeTypes,
  getSearchSuggestionList,
  computeGeocodedLocation,
} from '@/components/mapbox/utils/mapbox-api.js';
import debounce from 'lodash/debounce';
import {mapGetters, mapState} from 'vuex';
import ProductCard from '@/components/drawer/retailer/ProductCard.vue';

const INPUT_DELAY = 150;

export default {
  components: {PdlButton, FormGroup, PdlHeading, ProductCard},
  props: {
    initialValue: {
      type: String,
      default: () => '',
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
    search: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      input: this.initialValue,
      previousSearchValue: '',
      fieldError: undefined,
      suggestionList: [],
      selectedSuggestion: undefined,
    };
  },
  computed: {
    ...mapState('backend', ['mapboxApiKey', 'locale']),
    ...mapState('user', ['selectedLocation']),
    ...mapState('cart', ['outOfStockProductSku']),
    ...mapGetters('backend', ['countryCode']),
    headingDrawer() {
      return this.outOfStockProductSku ? 'basket.page.shipping.change.store' : 'basket.page.shipping.find.store';
    },
  },
  methods: {
    handleKeydown(event) {
      event.stopImmediatePropagation();
    },
    handleKeyup(event) {
      if (event.key !== 'Enter') return;
      this.handleSearch();
    },
    clearError() {
      this.fieldError = undefined;
    },
    displayError() {
      this.fieldError = this.$t('storefinder.searchterm.invalid');
    },
    handleSearch() {
      if (!this.input) return this.displayError();
      if (this.input === this.previousSearchValue) return;
      this.selectedSuggestion = this.suggestionList?.[0];
      this.search({
        latitude: this.selectedSuggestion?.data?.center[1],
        longitude: this.selectedSuggestion?.data?.center[0],
      });
      this.previousSearchValue = this.input;
      this.suggestionList = [];
      if (!this.selectedLocation?.postcode) this.getLocationCoordinatesFromUserInput();
    },
    handleInput() {
      this.clearError();
      this.fetchAutofillResults();
    },
    async getLocationCoordinatesFromUserInput() {
      try {
        const coordinates = await computeGeocodedLocation(this.input, this.mapboxApiKey);
        this.$emit('coordinates-from-input', coordinates);
      } catch (error) {
        console.error('Error attempting to get coordinates from search', error);
      }
    },
    async handleSearchFromSuggestion(suggestion) {
      this.selectedSuggestion = suggestion;
      this.input = suggestion.value;
      if (this.input === this.previousSearchValue) return;
      this.$refs.retailerDrawerInput.$el.firstElementChild.focus();
      if (!this.selectedLocation?.postcode) this.getLocationCoordinatesFromUserInput();
      this.search({
        latitude: this.selectedSuggestion?.data?.center[1],
        longitude: this.selectedSuggestion?.data?.center[0],
      });
      this.previousSearchValue = this.input;
    },
    fetchAutofillResults: debounce(async function () {
      const shouldFetchResults = this.input !== this.selectedSuggestion?.value && this.input !== this.initialValue;

      if (!shouldFetchResults) {
        this.suggestionList = [];
        return;
      }

      try {
        const suggestionData = await getSearchSuggestionList({
          search: this.input,
          accessToken: this.mapboxApiKey,
          country: this.countryCode,
          types: [MapboxGeocodeTypes.PLACE, MapboxGeocodeTypes.POSTCODE, MapboxGeocodeTypes.REGION].join(','),
        });
        this.suggestionList = suggestionData;
      } catch (error) {
        this.suggestionList = [];
      }
    }, INPUT_DELAY),
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0;
}

.retailer-select {
  --spacing: 1.5rem;

  row-gap: var(--spacing);
  margin: calc(var(--spacing) * -1);
  margin-bottom: 0;
  padding: var(--spacing);
}
</style>
