<template>
  <main class="grid-container pb-5">
    <!-- VIEW PAGE -->
    <div v-if="step === 'read'" id="tcrm-details-view">
      <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1">{{ $t('tcrm.B2B.title') }}</pdl-heading>
        </template>
        <template slot="actions">
          <pdl-button text icon="edit" @click="enableEditStep">
            <span>{{ $t('text.edit') }}</span>
          </pdl-button>
        </template>
      </pdl-section-header>

      <div class="max-w-md">
        <p class="mb-3">{{ $t('tcrm.B2B.legal.1') }}</p>
        <p class="mb-3">{{ $t('tcrm.B2B.legal.2') }}</p>
        <p class="mb-3">
          <span class="font-bold">{{ $t('tcrm.B2B.legal.3') }}</span>
          {{ $t('tcrm.B2B.legal.4') }}
        </p>
      </div>

      <pdl-section-header size="md" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('tcrm.B2B.marketingContact') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <section class="mb-3">
        <table class="b2b-table b2b-table--narrow b2b-table--borderless max-w-sm">
          <caption class="sr-only">
            {{
              $t('tcrm.B2B.title')
            }}
          </caption>
          <tbody>
            <tr>
              <th scope="row">{{ $t('address.firstName') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmContactFirstName }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('address.surname') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmContactLastName }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('text.address.phone') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmContactPhone }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('text.address.email') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmContactEmail }}</td>
            </tr>
          </tbody>
        </table>
      </section>

      <pdl-section-header size="md" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('tcrm.B2B.marketingChannels') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <section class="mb-3">
        <table class="b2b-table b2b-table--narrow b2b-table--borderless max-w-sm">
          <caption class="sr-only">
            {{
              $t('tcrm.B2B.marketingChannels')
            }}
          </caption>
          <tbody>
            <tr>
              <th scope="row">{{ $t('tcrm.B2B.replyToAddress') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmReplyToEmail }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('text.facebookURL') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmFacebookUrl }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('text.googlePlusURL') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmGoogleUrl }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('text.twitterURL') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmTwitterUrl }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('myCompany.campaignLandingURL') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmCampaignLandingUrl }}</td>
            </tr>
          </tbody>
        </table>
      </section>

      <pdl-section-header size="md" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('tcrm.B2B.emailPromotions') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <section class="mb-3">
        <table class="b2b-table b2b-table--narrow b2b-table--borderless max-w-sm">
          <caption class="sr-only">
            {{
              $t('tcrm.B2B.emailPromotions')
            }}
          </caption>
          <tbody>
            <tr>
              <th scope="row">{{ $t('myCompany.thankYouEmail') }}</th>
              <template v-for="(item, index) in localTcrmData.tcrm.tcrmThankYouEmail">
                <td v-if="item.active" :key="index">{{ item.name }}</td>
              </template>
            </tr>
            <tr>
              <th scope="row">{{ $t('tcrm.B2B.bikeBirthdayEmail') }}</th>
              <template v-for="(item, index) in localTcrmData.tcrm.tcrmBikeBirthdayEmail">
                <td v-if="item.active" :key="index">{{ item.name }}</td>
              </template>
            </tr>
            <tr>
              <th scope="row">{{ $t('myCompany.serviceReminderEmail') }}</th>
              <template v-for="(item, index) in localTcrmData.tcrm.tcrmSixMonthServiceReminderEmail">
                <td v-if="item.active" :key="index">{{ item.name }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </section>

      <pdl-section-header size="md" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('tcrm.B2B.catalogPromotions') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <section class="mb-3">
        <table class="b2b-table b2b-table--narrow b2b-table--borderless max-w-sm">
          <caption class="sr-only">
            {{
              $t('tcrm.B2B.catalogPromotions')
            }}
          </caption>
          <tbody>
            <tr>
              <th scope="row">{{ $t('myCompany.catalogCoupons') }}</th>
              <template v-for="(item, index) in localTcrmData.tcrm.tcrmCatalogCoupons">
                <td v-if="item.active" :key="index">{{ item.name }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </section>

      <pdl-section-header size="md" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('myCompany.paidSearch.optional') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <section class="mb-3">
        <table class="b2b-table b2b-table--narrow b2b-table--borderless max-w-sm">
          <caption class="sr-only">
            {{
              $t('scorecardReport.B2B.tcrmMarketingReport')
            }}
          </caption>
          <tbody>
            <tr>
              <th scope="row">{{ $t('tcrm.B2B.participatePaidSearch') }}</th>
              <template v-for="(item, index) in localTcrmData.tcrm.tcrmPaidSearchParticipation">
                <td v-if="item.active" :key="index">{{ bp(item.name) }}</td>
              </template>
            </tr>
            <tr>
              <th scope="row">{{ $t('myCompany.paidSearch.budget') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmPaidSearchBudget }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('myCompany.paidSearch.zipCode') }}</th>
              <td>{{ tcrmPaidSearchZipCodes }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('tcrm.B2B.findShopURL') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmPaidSearchFindLocalBikeShopUrl }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('tcrm.B2B.serviceURL') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmPaidSearchServiceAndRepairUrl }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('myCompany.bikeFittingURL') }}</th>
              <td>{{ localTcrmData.tcrm.tcrmPaidSearchBikeFittingUrl }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <!-- EDIT PAGE  -->
    <div v-else id="tcrm-details-edit">
      <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1">{{ $t('tcrm.B2B.title') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <div class="max-w-md">
        <p class="mb-3">{{ $t('tcrm.B2B.legal.1') }}</p>
        <p class="mb-3">{{ $t('tcrm.B2B.legal.2') }}</p>
        <p class="mb-3">
          <span class="font-bold">{{ $t('tcrm.B2B.legal.3') }}</span>
          {{ $t('tcrm.B2B.legal.4') }}
        </p>
      </div>

      <pdl-section-header size="md" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('tcrm.B2B.marketingContact') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <form
        ref="TCRMDetailsForm"
        :action="editUrl"
        modelAttribute="tcrmData"
        method="POST"
        @submit.prevent="validateAndSubmit"
      >
        <section class="mb-3 max-w-sm">
          <input id="code" v-model="code" type="hidden" name="code" />

          <form-group
            id="tcrmContactFirstName"
            v-model="localTcrmData.tcrm.tcrmContactFirstName"
            :label="$t('address.firstName')"
            type="text"
            name="tcrmContactFirstName"
            qaid="tcrm-edit-firstName"
          />
          <form-group
            id="tcrmContactLastName"
            v-model="localTcrmData.tcrm.tcrmContactLastName"
            :label="$t('address.surname')"
            type="text"
            name="tcrmContactLastName"
            qaid="tcrm-edit-lastName"
          />

          <form-group
            id="tcrmContactEmail"
            v-model="localTcrmData.tcrm.tcrmContactEmail"
            :label="$t('text.address.email')"
            type="email"
            name="tcrmContactEmail"
            qaid="tcrm-edit-email"
            :error="getErrorMessage('localTcrmData.tcrm.tcrmContactEmail')"
            show-error-messages
            force-display-error
          />

          <form-group
            id="tcrmContactPhone"
            v-model="localTcrmData.tcrm.tcrmContactPhone"
            :label="$t('text.address.phone')"
            type="text"
            name="tcrmContactPhone"
            qaid="tcrm-edit-phone"
          />
        </section>

        <pdl-section-header size="md" :is-dividing="true" class="mt-3">
          <template slot="content">
            <pdl-heading :level="3">{{ $t('tcrm.B2B.marketingChannels') }}</pdl-heading>
          </template>
        </pdl-section-header>

        <div class="max-w-sm">
          <section class="mb-3 max-w-sm">
            <form-group
              id="tcrmReplyToEmail"
              v-model="localTcrmData.tcrm.tcrmReplyToEmail"
              :label="$t('tcrm.B2B.replyToAddress')"
              type="email"
              name="tcrmReplyToEmail"
              qaid="tcrm-edit-replyToEmail"
              :error="getErrorMessage('localTcrmData.tcrm.tcrmReplyToEmail')"
              show-error-messages
              force-display-error
            />

            <form-group
              id="tcrmFacebookUrl"
              v-model="localTcrmData.tcrm.tcrmFacebookUrl"
              :label="$t('text.facebookURL')"
              :show-error-messages="true"
              type="text"
              name="tcrmFacebookUrl"
              qaid="tcrm-edit-facebookUrl"
            />

            <form-group
              id="tcrmGoogleUrl"
              v-model="localTcrmData.tcrm.tcrmGoogleUrl"
              :label="$t('text.googlePlusURL')"
              :show-error-messages="true"
              type="text"
              name="tcrmGoogleUrl"
              qaid="tcrm-edit-googleUrl"
            />

            <form-group
              id="tcrmTwitterUrl"
              v-model="localTcrmData.tcrm.tcrmTwitterUrl"
              :label="$t('text.twitterURL')"
              :show-error-messages="true"
              type="text"
              name="tcrmTwitterUrl"
              qaid="tcrm-edit-twitterUrl"
            />

            <form-group
              id="tcrmCampaignLandingUrl"
              v-model="localTcrmData.tcrm.tcrmCampaignLandingUrl"
              :label="$t('myCompany.campaignLandingURL')"
              :show-error-messages="true"
              type="text"
              name="tcrmCampaignLandingUrl"
              qaid="tcrm-edit-campaignLandingUrl"
            />
          </section>
        </div>

        <pdl-section-header size="md" :is-dividing="true" class="mt-3">
          <template slot="content">
            <pdl-heading :level="3">{{ $t('tcrm.B2B.marketingChannels') }}</pdl-heading>
          </template>
        </pdl-section-header>

        <div class="max-w-sm">
          <section class="mb-3">
            <legend class="form-fieldset__legend">{{ $t('myCompany.thankYouEmail') }}</legend>
            <label
              v-for="(item, index) in localTcrmData.tcrm.tcrmThankYouEmail"
              :id="item.code"
              :key="index"
              class="radio"
            >
              <input
                :id="item.code"
                v-model="tcrmThankYouEmail"
                type="radio"
                name="tcrmThankYouEmailValue"
                :value="item.code"
              />
              <span class="control-indicator"></span>
              {{ item.name }}
            </label>
          </section>

          <section class="mb-3">
            <legend class="form-fieldset__legend">{{ $t('tcrm.B2B.bikeBirthdayEmail') }}</legend>
            <label
              v-for="(item, index) in localTcrmData.tcrm.tcrmBikeBirthdayEmail"
              :id="item.code"
              :key="index"
              class="radio"
            >
              <input
                :id="item.code"
                v-model="tcrmBikeBirthdayEmail"
                type="radio"
                name="tcrmBikeBirthdayEmailValue"
                :value="item.code"
              />
              <span class="control-indicator"></span>
              {{ item.name }}
            </label>
          </section>

          <section class="mb-3">
            <legend class="form-fieldset__legend">{{ $t('myCompany.serviceReminderEmail') }}</legend>
            <label
              v-for="(item, index) in localTcrmData.tcrm.tcrmSixMonthServiceReminderEmail"
              :id="item.code"
              :key="index"
              class="radio"
            >
              <input
                :id="item.code"
                v-model="tcrmSixMonthServiceReminderEmail"
                type="radio"
                name="tcrmSixMonthServiceReminderEmailValue"
                :value="item.code"
              />
              <span class="control-indicator"></span>
              {{ item.name }}
            </label>
          </section>
        </div>

        <pdl-section-header size="md" :is-dividing="true" class="mt-3">
          <template slot="content">
            <pdl-heading :level="3">{{ $t('tcrm.B2B.catalogPromotions') }}</pdl-heading>
          </template>
        </pdl-section-header>

        <section class="mb-3">
          <legend class="form-fieldset__legend">{{ $t('tcrm.B2B.catalogPromotions') }}</legend>
          <label
            v-for="(item, index) in localTcrmData.tcrm.tcrmCatalogCoupons"
            :id="item.code"
            :key="index"
            class="radio"
          >
            <input
              :id="item.code"
              v-model="tcrmCatalogCoupons"
              type="radio"
              name="tcrmCatalogCouponsValue"
              :value="item.code"
            />
            <span class="control-indicator"></span>
            {{ item.name }}
          </label>
        </section>

        <pdl-section-header size="md" :is-dividing="true" class="mt-3">
          <template slot="content">
            <pdl-heading :level="3">{{ $t('myCompany.paidSearch.optional') }}</pdl-heading>
          </template>
        </pdl-section-header>

        <div class="max-w-sm">
          <section class="mb-3">
            <legend class="form-fieldset__legend">{{ $t('tcrm.B2B.participatePaidSearch') }}</legend>
            <label
              v-for="(item, index) in localTcrmData.tcrm.tcrmPaidSearchParticipation"
              :id="item.code"
              :key="index"
              class="radio"
            >
              <input
                :id="item.code"
                v-model="tcrmPaidSearchParticipation"
                type="radio"
                name="tcrmPaidSearchParticipationValue"
                :value="item.code"
                @change="searchParticipationDialog"
              />
              <span class="control-indicator"></span>
            </label>
          </section>

          <form-group
            id="tcrmPaidSearchBudget"
            v-model="tcrmPaidSearchBudget"
            :disabled="NoPaidParticipate"
            :label="$t('myCompany.paidSearch.budget')"
            type="text"
            name="tcrmPaidSearchBudget"
            qaid="tcrm-edit-budget"
            :error="getErrorMessage('tcrmPaidSearchZipCodes')"
            :required="NoPaidParticipate ? false : true"
            show-error-messages
            force-display-error
          />

          <form-group
            id="tcrmPaidSearchZipCodes"
            v-model="tcrmPaidSearchZipCodes"
            :disabled="NoPaidParticipate"
            :label="$t('myCompany.paidSearch.zipCode')"
            type="textarea"
            name="tcrmPaidSearchZipCodes"
            qaid="tcrm-edit-zipCode"
            :error="getErrorMessage('tcrmPaidSearchZipCodes')"
            :required="NoPaidParticipate ? false : true"
            show-error-messages
            force-display-error
          />

          <form-group
            id="tcrmPaidSearchFindLocalBikeShopUrl"
            v-model="tcrmPaidSearchFindLocalBikeShopUrl"
            :disabled="NoPaidParticipate"
            :label="$t('tcrm.B2B.findShopURL')"
            type="text"
            name="tcrmPaidSearchFindLocalBikeShopUrl"
            qaid="tcrm-edit-findShopURL"
            :error="getErrorMessage('tcrmPaidSearchFindLocalBikeShopUrl')"
            :required="PaidParticipationOneOfThreeRequiredChecker ? true : false"
            show-error-messages
            force-display-error
          />

          <form-group
            id="tcrmPaidSearchServiceAndRepairUrl"
            v-model="tcrmPaidSearchServiceAndRepairUrl"
            :disabled="NoPaidParticipate"
            :label="$t('tcrm.B2B.serviceURL')"
            type="text"
            name="tcrmPaidSearchServiceAndRepairUrl"
            qaid="tcrm-edit-serviceURL"
            :error="getErrorMessage('tcrmPaidSearchServiceAndRepairUrl')"
            :required="PaidParticipationOneOfThreeRequiredChecker ? true : false"
            show-error-messages
            force-display-error
          />

          <form-group
            id="tcrmPaidSearchBikeFittingUrl"
            v-model="tcrmPaidSearchBikeFittingUrl"
            :disabled="NoPaidParticipate"
            :label="$t('myCompany.bikeFittingURL')"
            type="text"
            name="tcrmPaidSearchBikeFittingUrl"
            qaid="tcrm-edit-bikeFittingURL"
            :error="getErrorMessage('tcrmPaidSearchBikeFittingUrl')"
            :required="PaidParticipationOneOfThreeRequiredChecker ? true : false"
            show-error-messages
            force-display-error
          />
        </div>

        <hr class="rule mt-5" />

        <div class="buttons">
          <pdl-button primary type="submit" qaid="tcrm-edit-submit">
            <span>{{ $t('text.button.save') }}</span>
          </pdl-button>
          <pdl-link button secondary qaid="tcrm-edit-cancel" :href="cancelUrl">
            <span>{{ $t('text.cancel') }}</span>
          </pdl-link>
        </div>
      </form>
      <pdl-dialog
        :visible.sync="dialogVisible"
        :title="$t('myCompany.paidSearch.changes')"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :z-index="16000011"
        qaid="tcrm-change-paid-search-dialog"
        @close="closeDialog"
      >
        <template slot="footer">
          <div class="buttons buttons--right">
            <pdl-button alert qaid="tcrm-edit-modal-yes" @click="confirmNoParticipation">
              <span>{{ $t('profile.optIn.yes') }}</span>
            </pdl-button>
            <pdl-button secondary qaid="tcrm-edit-modalno" @click="cancelNoParticipation">
              <span>{{ $t('profile.optIn.no') }}</span>
            </pdl-button>
          </div>
        </template>
      </pdl-dialog>
    </div>
  </main>
</template>

<script>
import {mapState} from 'vuex';
import get from 'lodash/get';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import {PdlDialog} from '@pedal/pdl-dialog';
import FormGroup from '@/components/FormGroup';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {scrollToElement} from '@/utils/scroll-to';

export default {
  components: {PdlDialog, FormGroup, PdlButton, PdlLink, PdlSectionHeader, PdlHeading},
  mixins: [TrekValidationMixin],
  props: {
    tcrmData: {
      type: Object,
      required: true,
    },
    editUrl: {
      type: String,
      default: '',
    },
    cancelUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dialogVisible: false,
      step: this.tcrmData.step,
      code: this.tcrmData.tcrm.code,
      tcrmPaidSearchBikeFittingUrl: this.tcrmData.tcrm.tcrmPaidSearchBikeFittingUrl || '',
      tcrmPaidSearchServiceAndRepairUrl: this.tcrmData.tcrm.tcrmPaidSearchServiceAndRepairUrl || '',
      tcrmPaidSearchFindLocalBikeShopUrl: this.tcrmData.tcrm.tcrmPaidSearchFindLocalBikeShopUrl || '',
      tcrmPaidSearchBudget: this.tcrmData.tcrm.tcrmPaidSearchBudget || '',
      tcrmThankYouEmail: '',
      tcrmBikeBirthdayEmail: '',
      tcrmSixMonthServiceReminderEmail: '',
      tcrmCatalogCoupons: '',
      tcrmPaidSearchParticipation: '',
      tcrmPaidSearchZipCodes: '',
      encodedContextPath: ACC.config.encodedContextPath,
      localTcrmData: this.tcrmData,
    };
  },

  validations() {
    return {
      localTcrmData: {
        tcrm: {
          tcrmContactEmail: {
            email: this.trekValidators.email('text.address.email'),
          },
          tcrmReplyToEmail: {
            email: this.trekValidators.email('tcrm.B2B.replyToAddress'),
          },
        },
      },
      tcrmPaidSearchBudget: {
        requiredUnless: this.trekValidators.requiredUnless('myCompany.paidSearch.zipCode', this.NoPaidParticipate),
      },
      tcrmPaidSearchZipCodes: {
        requiredUnless: this.trekValidators.requiredUnless('myCompany.paidSearch.budget', this.NoPaidParticipate),
      },
      tcrmPaidSearchFindLocalBikeShopUrl: {
        requiredUnlessWithCustomMessage: this.trekValidators.requiredUnlessWithCustomMessage(
          'tcrm.B2B.mustEnterURL',
          !this.PaidParticipationOneOfThreeRequiredChecker
        ),
      },
      tcrmPaidSearchServiceAndRepairUrl: {
        requiredUnlessWithCustomMessage: this.trekValidators.requiredUnlessWithCustomMessage(
          'tcrm.B2B.mustEnterURL',
          !this.PaidParticipationOneOfThreeRequiredChecker
        ),
      },
      tcrmPaidSearchBikeFittingUrl: {
        requiredUnlessWithCustomMessage: this.trekValidators.requiredUnlessWithCustomMessage(
          'tcrm.B2B.mustEnterURL',
          !this.PaidParticipationOneOfThreeRequiredChecker
        ),
      },
    };
  },

  computed: {
    ...mapState('global', ['headerHeight']),
    NoPaidParticipate() {
      return this.tcrmPaidSearchParticipation !== 'enumY';
    },

    // For bikeFittingURL, serviceURL, and findShopURL, only one of the three needs to be not empty
    PaidParticipationOneOfThreeRequiredChecker() {
      if (
        this.NoPaidParticipate ||
        this.tcrmPaidSearchBikeFittingUrl ||
        this.tcrmPaidSearchServiceAndRepairUrl ||
        this.tcrmPaidSearchFindLocalBikeShopUrl
      )
        return false;
      else return true;
    },

    tcrmThankYouEmailInit() {
      let filteredData = get(this.tcrmData, 'tcrm.tcrmThankYouEmail')
        .filter((item) => item.active === true)
        .map((item) => item.code);
      return filteredData.length ? filteredData[0] : '';
    },

    tcrmBikeBirthdayEmailInit() {
      let filteredData = get(this.tcrmData, 'tcrm.tcrmBikeBirthdayEmail')
        .filter((item) => item.active === true)
        .map((item) => item.code);
      return filteredData.length ? filteredData[0] : '';
    },

    tcrmSixMonthServiceReminderEmailInit() {
      let filteredData = get(this.tcrmData, 'tcrm.tcrmSixMonthServiceReminderEmail')
        .filter((item) => item.active === true)
        .map((item) => item.code);
      return filteredData.length ? filteredData[0] : '';
    },

    tcrmCatalogCouponsInit() {
      let filteredData = get(this.tcrmData, 'tcrm.tcrmCatalogCoupons')
        .filter((item) => item.active === true)
        .map((item) => item.code);
      return filteredData.length ? filteredData[0] : '';
    },
    tcrmPaidSearchParticipationInit() {
      let filteredData = get(this.tcrmData, 'tcrm.tcrmPaidSearchParticipation')
        .filter((item) => item.active === true)
        .map((item) => item.code);
      return filteredData.length ? filteredData[0] : '';
    },

    tcrmPaidSearchZipCodesInit() {
      let filteredData = get(this.tcrmData, 'tcrm.tcrmPaidSearchZipCodes');
      return filteredData
        ? filteredData.map((item, index) => (index === 0 ? item : `, ${item}`)).reduce((acc, item) => acc.concat(item))
        : '';
    },
  },

  created() {
    this.tcrmPaidSearchParticipation = this.tcrmPaidSearchParticipationInit;
    this.tcrmCatalogCoupons = this.tcrmCatalogCouponsInit;
    this.tcrmSixMonthServiceReminderEmail = this.tcrmSixMonthServiceReminderEmailInit;
    this.tcrmBikeBirthdayEmail = this.tcrmBikeBirthdayEmailInit;
    this.tcrmThankYouEmail = this.tcrmThankYouEmailInit;
    this.tcrmPaidSearchZipCodes = this.tcrmPaidSearchZipCodesInit;
  },

  mounted() {
    setTimeout(() => {
      if (this.tcrmData.cancelTcrm === true) {
        this.$notify({
          type: PdlToastType.WARNING,
          message: this.$t('tcrm.B2B.changesNotSaved'),
        });
      } else if (this.tcrmData.savedSuccessfully === true) {
        this.$notify({
          type: PdlToastType.SUCCESS,
          message: this.$t('tcrm.B2B.changesSaved'),
        });
      }
    }, 2000);

    this.localTcrmData = this.tcrmData;
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
    },

    closeDialog() {
      this.dialogVisible = false;
    },

    cancelNoParticipation() {
      this.closeDialog();
      this.tcrmPaidSearchParticipation = 'enumY';
    },

    confirmNoParticipation() {
      this.closeDialog();
      this.tcrmPaidSearchBudget = '';
      this.tcrmPaidSearchZipCodes = '';
      this.tcrmPaidSearchFindLocalBikeShopUrl = '';
      this.tcrmPaidSearchServiceAndRepairUrl = '';
      this.tcrmPaidSearchBikeFittingUrl = '';
    },

    enableEditStep() {
      this.step = 'write';
    },

    searchParticipationDialog() {
      if (
        // Only need to show the dialog if at least one of the optional fields is not empty
        this.NoPaidParticipate &&
        (this.tcrmPaidSearchBikeFittingUrl ||
          this.tcrmPaidSearchServiceAndRepairUrl ||
          this.tcrmPaidSearchFindLocalBikeShopUrl ||
          this.tcrmPaidSearchZipCodes ||
          this.tcrmPaidSearchBudget)
      ) {
        this.openDialog();
      }
    },

    validateAndSubmit() {
      this.v$.$validate().then((isValid) => {
        if (isValid) {
          this.$refs.TCRMDetailsForm.submit();
        } else {
          scrollToElement('.has-error', this.headerHeight);
        }
      });
    },
  },
};
</script>
