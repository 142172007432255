import {computed} from 'vue';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

export const useFulfillmentOptionMessaging = (fulfillmentOption) => {
  const hasMessaging = computed(() => {
    return !isEmpty(fulfillmentOption.value?.messaging);
  });

  const hasRichMessaging = computed(() => {
    return !isEmpty(fulfillmentOption.value?.richMessaging);
  });

  const richMessaging = computed(() => {
    return fulfillmentOption.value?.richMessaging;
  });

  const startDate = computed(() => {
    return fulfillmentOption.value?.deliveryWindow?.start;
  });

  const endDate = computed(() => {
    return fulfillmentOption.value?.deliveryWindow?.end;
  });

  const isSingleDate = computed(() => {
    return dayjs(startDate).isSame(endDate);
  });

  const baseMessage = computed(() => {
    return fulfillmentOption.value?.messaging?.message;
  });

  const shippingDetailsMessage = computed(() => {
    return fulfillmentOption.value?.messaging?.shippingDetailsMessage;
  });

  const locallyMessage = computed(() => {
    return fulfillmentOption.value?.messaging?.locallyMessage;
  });

  const buildTimeMessage = computed(() => {
    return fulfillmentOption.value?.messaging?.buildTimeMessage;
  });

  const dealerDeliveryMessage = computed(() => {
    return fulfillmentOption.value?.messaging?.dealerDeliveryMessage;
  });

  const locallyPrice = computed(() => {
    return fulfillmentOption.value?.retailerPrice;
  });

  const hasLocallyPrice = computed(() => {
    return !isEmpty(fulfillmentOption.value?.retailerPrice);
  });

  return {
    hasMessaging,
    hasRichMessaging,
    richMessaging,
    startDate,
    endDate,
    locallyPrice,
    hasLocallyPrice,
    isSingleDate,
    baseMessage,
    shippingDetailsMessage,
    locallyMessage,
    buildTimeMessage,
    dealerDeliveryMessage,
  };
};
