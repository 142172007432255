<template>
  <div v-if="!isKioskModeEnabled" class="pdp-add-to-cart">
    <pdl-callout v-if="isStockStatusVisible" qaid="stock-status-message" :kind="stockStatusCalloutType">
      <template #content>
        <span>{{ stockStatusText }}</span>
      </template>
    </pdl-callout>
    <fulfillment-options-container v-if="variantCode" :variant-code="variantCode" />
    <div v-if="isRetailerOnly || !options.eCommAvailable" class="mb-1">
      <div v-if="isRetailerOnly" class="alert negative">{{ $t('product.instore.only') }}</div>
      <trek-link
        button
        primary
        block
        icon="pin_drop"
        :href="withContextPath('/store-finder/')"
        data-ga-event="find-a-store-cta"
        data-ga-category="Find a Store CTA"
        data-ga-action="Item Not Available for Purchase"
      >
        <span>{{ $t('product.find.retailer.button') }}</span>
      </trek-link>
    </div>
    <div v-else-if="isAddToCartVisible" class="mb-1">
      <trek-button
        id="addToCartButton"
        primary
        block
        icon="shopping_cart"
        :disabled="!isAddToCartEnabled"
        type="submit"
        @click.prevent="addToCart"
      >
        <span id="add-button" v-html="addToCartButtonCaption" />
      </trek-button>
      <pdp-add-to-cart-status :message="message" />
    </div>

    <pdp-replacement v-if="product.isArchived && variantCode" :replacement-product="replacementProduct" />

    <slot name="locally-button"></slot>

    <!-- CMS consumer message slot -->
    <slot name="consumer-messages"></slot>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import trackPdpAddToCart from '@/mixins/tracking/pdp/track-pdp-add-to-cart.js';
import PdpAddToCartStatus from '@/components/containers/pdp/PdpAddToCartStatus.vue';
import FulfillmentOptionsContainer from '@/components/containers/pdp/b2c/fulfillment-options/FulfillmentOptionsContainer.vue';
import {Url} from '@/utils/url';
import get from 'lodash/get';
import PdpReplacement from '@/components/containers/pdp/PdpReplacement.vue';
import {isBtoCode, isInStockByCode} from '@/components/containers/pdp/utils/retailer-helpers.js';
import TrekButton from '@/components/TrekButton.vue';
import TrekLink from '@/components/TrekLink.vue';
import Variant from '@/utils/variant-helpers.js';

export default {
  name: 'PdpAddToCart',
  components: {PdpReplacement, PdpAddToCartStatus, TrekButton, TrekLink, FulfillmentOptionsContainer},
  mixins: [trackPdpAddToCart],
  props: {
    addToCartButtonCaption: {
      type: String,
      default: '',
    },
    variantCode: {
      type: String,
      default: '',
    },
    isAddingToCart: {
      type: Boolean,
      default: false,
    },
    stockMessage: {
      type: Object,
      default: () => null,
    },
    product: {
      type: Object,
      required: true,
    },
    isPurchasable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
    trackEventData: {
      type: Object,
      default: () => ({}),
    },
    isLocallyStockAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantity: 1,
    };
  },
  computed: {
    ...mapState('pdp', ['isECommAvailable', 'isLocallyActive']),
    ...mapState('backend', ['b2b']),

    isStockStatusVisible() {
      return (
        (isBtoCode(this.currentVariant?.globalStockStatus) || !this.currentVariant) &&
        Object.keys(this.stockMessage).length > 0 &&
        this.stockStatusText &&
        !this.isRetailerOnly &&
        !this.isCartRestrictionVisible
      );
    },

    stockStatusText() {
      if (this.b2b || this.isECommAvailable) {
        return this.$t(`product.stockStatus.${this.stockMessage.description}`);
      } else {
        if (this.isLocallyActive) {
          return this.$t(`product.stockStatus.${this.stockMessage.nonECommDescription}`);
        } else {
          return this.$t(`product.stockStatus.${this.stockMessage.nonECommNonLocallyDescription}`);
        }
      }
    },

    stockStatusCalloutType() {
      if (this.isECommAvailable) {
        return this.stockMessage.calloutType;
      } else {
        return 'default';
      }
    },

    isKioskModeEnabled() {
      return this.options.kioskModeEnabledOnSite;
    },

    currentVariant() {
      return Variant(this.variantCode, this.product).get();
    },

    isAddToCartVisible() {
      return !this.product.isArchived;
    },

    isAddToCartEnabled() {
      let isWareHouseStockAvailable = this.isPurchasable && isInStockByCode(this.currentVariant?.globalStockStatus);
      return !this.isAddingToCart && (this.isLocallyStockAvailable || isWareHouseStockAvailable);
    },

    isCartRestrictionVisible() {
      return isInStockByCode(this.currentVariant?.globalStockStatus);
    },

    replacementProduct() {
      if (!this.variantCode) {
        return null;
      }

      return get(
        this.product.variants.find((variant) => variant.code === this.variantCode),
        'replacementProduct'
      );
    },

    isRetailerOnly() {
      return this.product.retailerOnly;
    },
  },

  methods: {
    withContextPath: (url) => Url.withContextPath(url),
    addToCart() {
      this.$emit('add-to-cart', this.quantity);
      this.trackPdpAddToCart();
    },
  },
};
</script>
