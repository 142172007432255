<template>
  <div class="content">
    <pdl-heading :level="1" size="xl" class="mb-2">{{ $t('checkout.user') }}</pdl-heading>
    <checkout-personal-information :phone-max-length="phoneMaxLength" />
    <delivery-address
      :postcode-format="postcodeFormat"
      :postcode-max-length="postcodeMaxLength"
      :is-postcode-lookup-enabled="isPostcodeLookupEnabled"
      :regions="regions"
      :is-address-autofill-enabled="isAddressAutofillEnabled"
      @address-fields-showing="handleIsAddressFieldsShowing"
    >
      <template v-if="isPostcodeLookupEnabled" #postcode-autofill>
        <slot name="postcode-autofill" />
      </template>
    </delivery-address>
    <form-group
      v-if="isAddressFieldsShowing && isUserLoggedIn"
      type="checkbox"
      :multi-items="defaultAddressCheckbox"
      label-classes="text-sm"
      name="defaultAddress"
      show-label
      :required="false"
      @change="handleCheckboxChange"
    />
    <div class="grid grid-flow-row gap-y-2 md:hidden">
      <pdl-button block primary type="submit" qaid="choose-payment-method-button">
        {{ $t('checkout.summary.select.payment.method') }}
      </pdl-button>
      <pdl-link
        button
        tertiary
        block
        icon="chevron_right"
        icon-right
        :href="continueShoppingUrl"
        qaid="checkout-continue-link"
      >
        {{ $t('text.continue.shopping') }}
      </pdl-link>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {mapState, mapMutations} from 'vuex';
import CheckoutPersonalInformation from '@/components/checkout/forms/CheckoutPersonalInformation';
import DeliveryAddress from '@/components/checkout/forms/delivery/DeliveryAddress.vue';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import {PdlHeading} from '@pedal/pdl-section-header';
import {DeliveryAddressApi, convertToJSON} from '@/api/checkout/delivery-address';
import {scrollToElement} from '@/utils/scroll-to';
import FormGroup from '@/components/FormGroup.vue';
import {getRegionMap} from '@/utils/cart/delivery-address-util';
import {MapboxLocation} from '@/components/mapbox/mixins/mapbox-location';

const FORM_ID = 'trekAddressForm';

export default {
  components: {CheckoutPersonalInformation, DeliveryAddress, PdlButton, PdlLink, PdlHeading, FormGroup},
  mixins: [MapboxLocation],
  inject: ['validationErrors', 'setValidationErrors', 'clearValidationErrors'],
  props: {
    formAddressId: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    postcodeFormat: {
      type: String,
      required: true,
    },
    postcodeMaxLength: {
      type: Number,
      required: true,
    },
    previousPage: {
      type: String,
      required: true,
    },
    isPhoneRequired: {
      type: Boolean,
      default: true,
    },
    isFormInvalid: {
      type: String,
      default: '',
    },
    isPostcodeLookupEnabled: {
      type: Boolean,
      default: false,
    },
    addressValidationConfig: {
      type: Object,
      default: () => null,
    },
    phoneMaxLength: {
      type: Number,
      default: () => null,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    regions: {
      type: Array,
      default: () => [],
    },
    isAddressAutofillEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      shouldSetDefaultAddress: false,
      isDefaultAddressChecked: false,
      isAddressFieldsShowing: !this.isAddressAutofillEnabled,
      regionMap: null,
    };
  },
  computed: {
    ...mapState('checkout', ['continueShoppingUrl']),
    ...mapState('backend', ['encodedContextPath', 'isUserLoggedIn']),
    defaultAddressCheckbox() {
      return [
        {
          name: 'defaultAddress',
          label: 'default-address-checkbox',
          displayName: this.$t('text.default.address'),
          checked: this.isDefaultAddressChecked,
        },
      ];
    },
  },
  mounted() {
    const form = document.getElementById(FORM_ID);
    form.addEventListener('submit', this.handleSubmit);
    this.regionMap = getRegionMap(this.regions);
  },
  beforeDestroy() {
    document.getElementById(FORM_ID).removeEventListener('submit', this.handleSubmit);
  },
  methods: {
    ...mapMutations('checkout', ['setOutOfDealerDeliveryRangeToast']),
    ...mapMutations('user', ['setDrawerDeliveryAddress', 'setSelectedLocation']),
    handleIsAddressFieldsShowing(newVal) {
      this.isAddressFieldsShowing = newVal;
    },
    handleCheckboxChange(event) {
      this.shouldSetDefaultAddress = event.target.checked;
    },
    scrollToError() {
      const firstErrorElement = document.querySelector('.has-error');
      if (firstErrorElement) {
        scrollToElement(firstErrorElement, 20);
      }
    },
    redirectToCartPage() {
      window.location = `${this.encodedContextPath}/cart`;
    },
    async handleSubmit(event) {
      event.preventDefault();

      if (this.validationErrors.length > 0) {
        this.clearValidationErrors();
      }

      const formData = new FormData(document.getElementById(FORM_ID));
      formData.append('titleCode', 'mr');
      formData.append('fireTag', true);
      this.shouldSetDefaultAddress && formData.append('defaultAddress', this.shouldSetDefaultAddress);
      this.countries.forEach((country) => {
        formData.append('countryIso', country.isocode);
      });

      let isValid = true;

      try {
        isValid = await this.v$.$validate();
        if (!isValid) {
          this.scrollToError();
          return;
        }

        const {dataToSubmit, deliveryAddress} = this.deliveryAddressDetails(formData);
        const location = await this.mapboxLocation(deliveryAddress);
        if (!this.validateLocation(location, deliveryAddress)) return;

        await DeliveryAddressApi.addDeliveryAddress(dataToSubmit);
        this.setSelectedLocation(location);
        this.setDrawerDeliveryAddress(deliveryAddress);
      } catch (error) {
        const {errorFields} = error.response?.data?.meta?.feedback ?? {
          errorFields: this.mapboxFailureError(),
        };
        this.setValidationErrors(errorFields);

        if (
          error.response?.data?.meta?.feedback?.message?.basePropertyKey === 'checkout.multi.deliveryMethod.outOfRange'
        ) {
          this.setOutOfDealerDeliveryRangeToast(true);
          this.redirectToCartPage();
        }
        return;
      }
      this.setOutOfDealerDeliveryRangeToast(false);
      const REDIRECT_URL = 'next';
      window.location.href = REDIRECT_URL;
    },
    deliveryAddressDetails(formData) {
      const dataToSubmit = convertToJSON(formData);
      if (dataToSubmit.regionIso) {
        const getRegionName = this.regionMap.get(dataToSubmit.regionIso).name;
        dataToSubmit.regionName = getRegionName;
      }
      const {line1, line2, postcode, regionIso, townCity, regionName} = dataToSubmit;
      const deliveryAddress = {line1, line2, postcode, regionIso, townCity, regionName};
      return {dataToSubmit, deliveryAddress};
    },
  },
};
</script>
