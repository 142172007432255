<template>
  <div :id="id" class="text-base" :qaid="qaidPanel">
    <pdl-loading :is-loading="isLoading">
      <div class="p-1 md:p-2 text-left">
        <trek-button
          text
          icon-only
          icon="close"
          :aria-label="$t('popup.close')"
          class="p-25 absolute top-0 right-0 mt-2 mr-2"
          @click="closeTooltip"
        >
        </trek-button>

        <pdl-section-header size="md" class="mb-2">
          <template slot="content">
            <pdl-heading :qaid="qaidTitle">{{ $t('text.buyingZone.B2B.moveToList') }}</pdl-heading>
          </template>
        </pdl-section-header>

        <div v-if="userLists && userLists.length" class="b2b-scroll-list-container md:min-w-xs" :qaidList="qaidList">
          <div class="b2b-scroll-list">
            <div class="b2b-scroll-list__item b2b-scroll-list__item--category font-medium">{{ textMyLists }}</div>

            <button
              v-for="list in userLists"
              :key="list.id"
              class="b2b-scroll-list__item w-full"
              :qaid="`${qaidListItem}-${list.id}`"
              @click="onUpdate(list.id)"
              v-text="`${list.name} (${list.totalEntries})`"
            />
          </div>
        </div>

        <div v-else>
          <pdl-callout>
            <template #content>
              <span>{{ $t('text.account.savedLists.zeroLists') }}</span>
            </template>
          </pdl-callout>
        </div>

        <form class="mt-4" @submit.prevent="onCreate(listName)">
          <div class="form-group">
            <label class="form-label" for="create-new">{{ $t('text.buyingZone.B2B.createNewList') }}</label>
            <input
              id="create-new"
              v-model="listName"
              class="form-control"
              type="text"
              maxlength="40"
              :placeholder="$t('text.enterName')"
            />
            <span id="create-new-help" class="form-help">{{ textMaxChars }}</span>
          </div>

          <div class="buttons buttons--right">
            <trek-button primary type="submit" qaid="create-and-save-list-button" :disabled="!listName">
              <span>{{ $t('text.buyingZone.B2B.createSave') }}</span>
            </trek-button>
          </div>
        </form>
      </div>
    </pdl-loading>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import {getToast} from '@/utils/axios';
import TrekButton from '@/components/TrekButton';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlLoading} from '@pedal/pdl-loading';
import {checkMetaFeedbackType, MetaFeedbackType, resolveToastType} from '@/constants/meta-feedback-type';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {TrekButton, PdlCallout, PdlLoading, PdlSectionHeader, PdlHeading},
  props: {
    textMaxChars: {
      type: String,
      default: '',
    },
    textMyLists: {
      type: String,
      default: '',
    },
    textOtherLists: {
      type: String,
      default: '',
    },
    textMovedToList: {
      type: String,
      default: '',
    },
    textUndo: {
      type: String,
      default: '',
    },
    tippyId: {
      type: String,
      default: '',
    },
    btnId: {
      type: String,
      default: '',
    },
    qaidPanel: {
      type: String,
      default: '',
    },
    qaidTitle: {
      type: String,
      default: '',
    },
    qaidList: {
      type: String,
      default: '',
    },
    qaidListItem: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
    showMinQtyToasts: Boolean,
    isBuyingZone: Boolean,
    id: {
      type: String,
      default: 'saved-lists',
    },
    partTypeKey: {
      type: String,
      default: '',
    },
    isCart: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listName: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapState('savedLists', ['userLists', 'accountLists', 'entries', 'activeEntries', 'isFetchedAllOnce']),
  },

  created() {
    // Prevent multiple fetching of saved lists.
    if (!this.isFetchedAllOnce) {
      this.fetchAllLists();
    }
  },

  methods: {
    onCreate(name) {
      this.isLoading = true;
      this.setIsBuyingZone(this.isBuyingZone);
      this.identifyEntriesList();
      const csrfToken = this.token;

      this.createList({name, csrfToken, isToast: false})
        .then((data) => {
          const failedQtySKUs = (data?.data?.failedSKUs ?? []).map((item) => item.sku);
          const failedQtyMessages = (data?.data?.failedSKUs ?? []).map((item) => item.message.basePropertyValue);

          this.$emit('failed-skus', failedQtySKUs, failedQtyMessages);

          const {type, message} = getToast(data.meta.feedback);

          const {id, entries} = data.data;
          const undoProps = {
            actionClickText: this.textUndo,
            actionClick: () => this.undoCreate(id),
          };
          this.listName = null;

          let qtyToastMessage = this.showMinQtyToasts && failedQtyMessages ? failedQtyMessages[0] : null;

          // Since for BBD-4667, min Qty error messages arrive in data.failedSkus rather than meta,
          // we cannot use the routine meta message checker.
          if (!checkMetaFeedbackType(data, MetaFeedbackType.ERROR)) {
            this.$notify({
              type: resolveToastType(type),
              message,
              ...undoProps,
            });
          } else if (qtyToastMessage) {
            this.$notify({
              type: resolveToastType(type),
              message: qtyToastMessage,
            });
          } else if (message) {
            this.$notify({
              type: resolveToastType(type),
              message,
            });
          }

          this.clearQty();
          this.closeTooltip();
          this.isLoading = false;

          if (!failedQtySKUs?.length) {
            this.$emit('submit', this.partTypeKey || entries);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },

    undoCreate(id) {
      this.deleteList(id).then(({data}) => {
        this.$emit('submit', data.data.entries);
        this.$emit('undo', this.activeEntries);
        if (this.isCart) {
          this.undoRemove(this.activeEntries);
        }
      });
    },

    onUpdate(listId) {
      this.isLoading = true;
      this.setIsBuyingZone(this.isBuyingZone);
      this.identifyEntriesList();
      this.updateListEntries({id: listId, entries: this.activeEntries, isToast: false})
        .then((data) => {
          const failedQtySKUs = (data?.data?.failedSKUs ?? []).map((item) => item.sku);
          const failedQtyMessages = (data?.data?.failedSKUs ?? []).map((item) => item.message.basePropertyValue);

          this.$emit('failed-skus', failedQtySKUs, failedQtyMessages);

          const {type, message} = getToast(data.meta.feedback);
          const {id, entries} = data.data;

          const undoProps = {
            actionClickText: this.textUndo,
            actionClick: () => this.undoUpdate(id, entries),
          };

          let qtyToastMessage = this.showMinQtyToasts && failedQtyMessages ? failedQtyMessages[0] : null;

          // Since for BBD-4667, min Qty error messages arrive in data.failedSkus rather than meta,
          // we cannot use the routine meta message checker.

          if (!checkMetaFeedbackType(data, MetaFeedbackType.ERROR)) {
            this.$notify({
              type: resolveToastType(type),
              message,
              ...undoProps,
            });
          } else if (qtyToastMessage) {
            this.$notify({
              type: resolveToastType(type),
              message: qtyToastMessage,
            });
          } else if (message) {
            this.$notify({
              type: resolveToastType(type),
              message,
            });
          }

          this.clearQty();
          this.closeTooltip();
          this.isLoading = false;

          if (!failedQtySKUs?.length) {
            this.$emit('submit', this.partTypeKey || entries);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },

    undoUpdate(id, undoEntries) {
      this.fetchList({id, isToast: false}).then((data) => {
        const existingEntries = [...data.data.bikesEntries, ...data.data.partsEntries];
        const updatedEntries = existingEntries.reduce((acc, entry) => {
          const matched = undoEntries.find((undoEntry) => undoEntry.sku === entry.sku);

          if (matched) {
            const newQty = entry.orderedQty - matched.qty;

            acc.push({
              sku: matched.sku,
              qty: newQty <= 0 ? 0 : newQty,
            });
          }

          return acc;
        }, []);

        this.updateUserLists({id, totalEntries: data.data.totalEntries});
        this.updateSelectedEntries({id, entries: updatedEntries}).then((respData) => {
          const dataEntries = respData.data.entries || [];
          this.$emit('submit', dataEntries);
          this.clearSelectedList();
          this.$emit('undo', this.activeEntries);
          if (this.isCart) {
            this.undoRemove(this.activeEntries);
          }
        });
      });
    },

    closeTooltip() {
      this.listName = null;
      const tippyEl = document.getElementById(this.btnId);

      if (tippyEl && tippyEl._tippy) {
        tippyEl._tippy.hide();
      }
    },

    ...mapMutations('savedLists', ['clearQty', 'setIsBuyingZone', 'identifyEntriesList', 'updateUserLists']),
    ...mapActions('savedLists', [
      'fetchAllLists',
      'fetchList',
      'createList',
      'updateListEntries',
      'updateSelectedEntries',
      'deleteList',
      'clearSelectedList',
    ]),
    ...mapActions('cart', ['undoRemove']),
  },
};
</script>
