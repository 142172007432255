<template>
  <pdl-drawer size="23rem" :is-open="isDrawerOpen" show-close @close="handleClose">
    <template #content>
      <div class="space-y-8 delivery-address-form bg-white">
        <pdl-heading size="md" qaid="address-drawer-header">{{ $t('fulfillment.address.confirm') }}</pdl-heading>
        <form :id="formId" class="space-y-2" @submit.prevent>
          <div class="gap-x-4">
            <delivery-address
              :postcode-format="postcodeFormat"
              :postcode-max-length="postcodeMaxLength"
              :regions="regions"
              :is-address-autofill-enabled="isAddressAutofillEnabled"
              @checkbox-change="handleCheckboxChange"
            />
            <div class="flex justify-end">
              <pdl-button
                primary
                small
                class="whitespace-nowrap button--inline"
                qaid="address-drawer-update-button"
                @click="handleAddressSelected"
              >
                {{ $t('fulfillment.address.update') }}
              </pdl-button>
            </div>
          </div>
        </form>
      </div>
      <benefit-messaging />
    </template>
  </pdl-drawer>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {PdlDrawer} from '@pedal/pdl-drawer';
import {PdlHeading} from '@pedal/pdl-section-header';
import {PdlButton} from '@pedal/pdl-button';
import {mapGetters, mapMutations, mapState} from 'vuex';
import DeliveryAddress from '@/components/checkout/forms/delivery/DeliveryAddress.vue';
import {useFunctionalityToggle} from '@/composables/functionality-toggle.js';
import {FunctionalityToggle} from '@/constants/functionality-toggle.js';
import {convertToJSON, DeliveryAddressApi} from '@/api/checkout/delivery-address';
import BenefitMessaging from '@/components/drawer/benefit-messaging/BenefitMessaging.vue';
import {getRegionMap, populateAddress} from '@/utils/cart/delivery-address-util.js';
import {MapboxLocation} from '@/components/mapbox/mixins/mapbox-location';
import {CartApi} from '@/api/cart';

const parseBillingErrorKey = (key) => {
  const billingErrorPrefix = 'billingAddress.';
  if (!key.includes(billingErrorPrefix)) return key;
  return key.split(billingErrorPrefix)?.[1] ?? key;
};

const FORM_ID = 'trekUserAddressForm';

export default {
  components: {PdlDrawer, PdlButton, PdlHeading, DeliveryAddress, BenefitMessaging},
  mixins: [MapboxLocation],
  provide() {
    return {
      validationErrors: this.validationErrors,
      setValidationErrors: this.setValidationErrors,
      clearValidationErrors: this.clearValidationErrors,
    };
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const isAddressAutofillEnabled = useFunctionalityToggle(FunctionalityToggle.ADDRESS_AUTOFILL).isToggleActive;
    return {isAddressAutofillEnabled, v$: useVuelidate()};
  },
  data() {
    return {
      shouldSetDefaultAddress: false,
      validationErrors: [],
      formId: FORM_ID,
      regions: [],
      regionMap: null,
      postcodeFormat: '',
      postcodeMaxLength: 0,
    };
  },
  computed: {
    ...mapState('backend', ['languageISO']),
    ...mapGetters('backend', ['countryCode', 'occUrlParams']),
    ...mapState('user', ['selectedLocation']),
    ...mapState('cart', ['cartData']),
  },
  async mounted() {
    const addressValidationConfig = await DeliveryAddressApi.getAddressValidationConfig();
    this.postcodeMaxLength = addressValidationConfig?.postcode?.maxLength;
    this.postcodeFormat = addressValidationConfig?.postcode?.postcodeFormat || '';

    this.regions = await DeliveryAddressApi.getRegions(this.languageISO.split('_')[1]);
    this.regionMap = getRegionMap(this.regions);
  },
  methods: {
    ...mapMutations('user', ['setDrawerDeliveryAddress', 'setSelectedLocation']),
    handleCheckboxChange(isChecked) {
      this.shouldSetDefaultAddress = isChecked;
    },
    async handleAddressSelected() {
      this.clearFormErrors();
      const {formData, formObject} = this.deliveryAddressDetails();

      let isValid = true;

      try {
        isValid = await this.v$.$validate();
        if (!isValid) {
          return;
        }
        const location = await this.mapboxLocation(formObject);
        if (!this.validateLocation(location, formObject)) return;

        const addressPayload = await populateAddress(this.countryCode, location, convertToJSON(formData));
        this.cartData
          ? await CartApi.updateCartAddress(this.occUrlParams, addressPayload)
          : await DeliveryAddressApi.pdpDeliveryAddressValidation(addressPayload);
        this.setSelectedLocation(location);
        this.setDrawerDeliveryAddress(formObject);
        this.handleClose();
      } catch (error) {
        const errorFields = this.fetchErrorFields(error) ?? this.mapboxFailureError();

        this.setValidationErrors(errorFields);
        return;
      }
    },
    handleClose() {
      this.$emit('close');
    },
    clearValidationErrors() {
      this.validationErrors.splice(0);
    },
    setValidationErrors(errorFields) {
      if (!errorFields) return;
      Object.entries(errorFields).forEach(([key, value]) => {
        const parsedKey = parseBillingErrorKey(key);

        const error = {
          field: parsedKey,
          msg: value,
        };

        this.validationErrors.push(error);
      });
    },
    deliveryAddressDetails() {
      const formData = new FormData(document.getElementById(this.formId));
      const formObject = convertToJSON(formData);

      if (formObject.regionIso) {
        const getRegionName = this.regionMap.get(formObject.regionIso).name;
        formObject.regionName = getRegionName;
      }

      return {formData, formObject};
    },
    clearFormErrors() {
      if (!this.validationErrors.length) return;
      this.clearValidationErrors();
    },
    fetchErrorFields(error) {
      return error?.cause?.data?.errors.reduce((acc, errorField) => {
        acc[errorField.subject] = this.$t(errorField.message);
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.pdl-drawer {
  z-index: 16000009;
}

.form-group {
  margin-bottom: 0;
}

::v-deep .pdl-drawer__content {
  background-color: var(--gray-05);
}

.delivery-address-form {
  --spacing: 1.5rem;

  row-gap: var(--spacing);
  margin: calc(var(--spacing) * -1);
  margin-bottom: 0;
  padding: var(--spacing);
}
</style>
