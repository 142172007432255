<template>
  <div :qaid="`cart-entry-${entryNumber}-no-fulfillment-option-container`" class="flex flex-col gap-y-2">
    <pdl-link standalone small icon="reply" :href="entry.product.url" class="hover:bg-gray-10">
      {{ $t('myCart.notAvailable.options') }}
    </pdl-link>
    <pdl-button tertiary small icon="store" @click="openDrawer">
      {{ $t('basket.page.shipping.change.store') }}
    </pdl-button>
  </div>
</template>
<script>
import {mapMutations} from 'vuex';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
export default {
  components: {
    PdlButton,
    PdlLink,
  },
  props: {
    entry: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    entryNumber() {
      return this.entry.entryNumber;
    },
  },
  methods: {
    ...mapMutations('cart', ['setOutOfStockProductSku']),
    openDrawer() {
      this.setOutOfStockProductSku(this.entry.product.code);
    },
  },
};
</script>
<style lang="scss" scoped>
.pdl-link.pdl-link--standalone {
  padding: 7.5px 18px;

  span {
    text-decoration: none;
  }
}

.button.button--tertiary {
  justify-content: start;
}
</style>
