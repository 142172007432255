<template>
  <main class="my-4">
    <div class="grid-container">
      <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1">{{ $t('bulkOrder.upload') }}</pdl-heading>
        </template>
        <template slot="actions">
          <div class="buttons">
            <trek-link id="bulkOrderDownloadButton" button secondary small icon="get_app" :href="downloadtemplateUrl">
              {{ $t('bulkOrder.template') }}
            </trek-link>
          </div>
        </template>
      </pdl-section-header>

      <div v-show="!postUploadState" class="section pt-3">
        <pdl-file-upload
          button-id="bulkOrderFileUploadButton"
          input-id="bulk-upload-file"
          accept="text/csv"
          extensions="csv"
          :click-to-upload-msg="$t('bulkOrder.upload.cta')"
          :wrong-file-type-msg="$t('orderUpload.B2B.fileTypeError')"
          :csrf-token="token"
          aspect-ratio="4"
          @response="setDataAsResponse"
          @activate-upload="startLoadingIndicator"
        >
          <template slot="help">
            {{ $t('bulkOrder.fileFormat') }}
          </template>
        </pdl-file-upload>
      </div>

      <div v-show="failureLinesList.length > 0 && postUploadState" qaid="bulk-upload-failure-list">
        <p class="mt-3">
          {{ $t('orderUpload.B2B.failed') }}
        </p>
        <table class="b2b-grid b2b-grid--collapse is-compact" qaid="bulk-upload-failure-table">
          <caption class="sr-only">
            {{
              $t('product.details.nav.label.skuGrid')
            }}
          </caption>
          <thead class="b2b-grid__header">
            <tr>
              <th class="b2b-grid__cell b2b-grid__head">
                {{ $t('product.variants.sku') }}
              </th>
              <th class="b2b-grid__cell b2b-grid__head">
                {{ $t('order.item') }}
              </th>
              <th class="b2b-grid__cell b2b-grid__head">
                {{ $t('text.account.savedLists.qty') }}
              </th>
              <th class="b2b-grid__cell b2b-grid__head">
                {{ $t('orderUpload.B2B.issue') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- Parse failure messages -->
            <tr v-for="(row, index) in failureLinesList" :key="`${row.sku}-${index}`" class="table-row">
              <td class="b2b-grid__cell lg:table-cell">
                <div class="b2b-grid__label">
                  {{ $t('product.variants.sku') }}
                </div>
                <div class="b2b-grid__content">
                  {{ row.sku }}
                </div>
              </td>
              <td class="b2b-grid__cell lg:table-cell">
                <div class="b2b-grid__label">
                  {{ $t('order.item') }}
                </div>
                <div class="b2b-grid__content">
                  {{ row.itemName }}
                </div>
              </td>
              <td class="b2b-grid__cell lg:table-cell">
                <div class="b2b-grid__label">
                  {{ $t('text.account.savedLists.qty') }}
                </div>
                <div class="b2b-grid__content">
                  {{ row.qty }}
                </div>
              </td>
              <td class="b2b-grid__cell lg:table-cell">
                <div class="b2b-grid__label">
                  {{ $t('orderUpload.B2B.issue') }}
                </div>
                <div class="b2b-grid__content">
                  <div class="flex items-center lg:justify-start">
                    <pdl-icon class="text-alert mr-25" size="18" name="error" />
                    {{ $t(`${row.issue}`) }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-show="postUploadState" class="buttons buttons--right-for-md">
        <trek-link button primary :href="cartpageUrl">
          {{ $t('cart.view') }}
        </trek-link>
        <trek-button secondary @click="startAnotherUpload">
          {{ $t('orderUpload.B2B.uploadAnother') }}
        </trek-button>
      </div>
      <pdl-loading page :is-loading="loadingIndicator" />
    </div>
  </main>
</template>

<script>
import {mapActions} from 'vuex';
import TrekLink from '@/components/TrekLink';
import TrekButton from '@/components/TrekButton';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlFileUpload} from '@pedal/pdl-file-upload';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {TrekLink, TrekButton, PdlFileUpload, PdlLoading, PdlSectionHeader, PdlHeading, PdlIcon},
  props: {
    downloadtemplateUrl: {
      type: String,
      default: undefined,
    },
    cartpageUrl: {
      type: String,
      default: undefined,
    },
    token: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      loadingIndicator: false,
      uploadedFile: {},
      failureLinesList: [],
      postUpload: false,
    };
  },

  computed: {
    postUploadState() {
      return this.postUpload;
    },
  },

  methods: {
    setDataAsResponse(response) {
      this.loadingIndicator = false;
      this.failureLinesList = response.failureLinesList;
      this.postUpload = true;
      this.fetchMiniCart();
    },

    startAnotherUpload() {
      this.postUpload = false;
    },

    startLoadingIndicator() {
      this.loadingIndicator = true;
    },
    ...mapActions('miniCart', ['fetchMiniCart']),
  },
};
</script>
