<template>
  <pdl-dialog
    v-bind="modalProps"
    :visible="isVisible"
    :title="title"
    qaid="fulfillment-option-dialog"
    :close-dialog-tool-tip="$t('popup.close')"
    :z-index="16000011"
    class="pointer-events-auto"
    :show-close="true"
    :close-on-press-escape="true"
    :close-on-click-modal="true"
    @close="$emit('close')"
  >
    <template #header>
      <pdl-heading v-if="title" size="md" qaid="fulfillment-option-dialog-title">{{ title }}</pdl-heading>
    </template>
    <pdl-loading :is-loading="isLoading">
      <div class="mb-3 text-sm" qaid="fulfillment-option-dialog-content" v-html="content" />
      <image-component
        v-if="componentData.editorialImage"
        qaid="fulfillment-option-dialog-image"
        :asset-id="componentData.editorialImage"
        :is-responsive="true"
      />
    </pdl-loading>
  </pdl-dialog>
</template>

<script setup>
import {PdlLoading} from '@pedal/pdl-loading';
import {useStore} from '@/store';
import {PdlDialog} from '@pedal/pdl-dialog';
import ImageComponent from '@/components/ImageComponent.vue';
import {PdlHeading} from '@pedal/pdl-section-header';
import {useCmsComponent} from '@/composables/cms-component.js';
import {computed} from 'vue';
import {ViewSize} from '@/constants/view-breakpoints';

const props = defineProps({
  componentId: {
    type: String,
    default: '',
  },
  isVisible: {
    type: Boolean,
    required: true,
  },
});

const {componentData, isLoading} = useCmsComponent(props.componentId);
const store = useStore();

const title = computed(() => {
  return componentData.value?.editorialTitle;
});

const content = computed(() => {
  return componentData.value?.content;
});

const modalProps = computed(() => {
  return store.getters['viewSize/viewBreakpointMoreThan']?.(ViewSize.MEDIUM) ? {} : {fullscreen: true};
});
</script>
