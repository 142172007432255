<template>
  <section v-if="!isRetailerLoading" class="pb-3 pt-1">
    <postal-code-drawer :is-drawer-open="isPostalCodeDrawerOpen" @close="isPostalCodeDrawerOpen = false" />
    <retailer-drawer :is-drawer-open="isRetailerDrawerOpen" @close="isRetailerDrawerOpen = false" />
    <div :key="updateKey" class="grid md:grid-cols-2 gap-8 md:gap-6">
      <div>
        <pdl-label qaid="selected-retailer-store-pickup-label" kind="default" class="mb-1">
          {{ $t('checkout.multi.pickupInStore.location') }}
        </pdl-label>

        <template v-if="!Object.keys(selectedRetailer).length">
          <div qaid="selected-retailer-no-retailer-message" class="font-bold mb-1">
            {{ $t('checkout.multi.retailer.error') }}
          </div>
        </template>
        <template v-else>
          <div qaid="selected-retailer-name" class="font-bold">
            {{ selectedRetailer.displayName }}
            <span
              v-if="selectedRetailer.formattedDistance"
              qaid="selected-retailer-formatted-distance"
              class="font-normal text-gray-60"
            >
              {{ `(${selectedRetailer.formattedDistance} ${unitAbbreviation})` }}
            </span>
          </div>
        </template>

        <address-summary
          v-if="selectedRetailer.address"
          custom-classes="mb-1"
          :address="selectedRetailer.address"
          qaid="selected-retailer-store-pickup-address"
        />

        <pdl-link qaid="selected-retailer-store-pickup-edit" @click.prevent="handleRetailerClick">
          {{ $t('basket.page.shipping.change.store') }}
        </pdl-link>
      </div>
      <div>
        <pdl-label qaid="selected-retailer-postcode-label" kind="default" class="mb-1">
          {{ $t('checkout.summary.deliveryAddress') }}
        </pdl-label>
        <address-summary
          v-if="hasDrawerDeliveryAddress"
          custom-classes="mb-1"
          :address="drawerDeliveryAddress"
          qaid="selected-retailer-delivery-address"
        />
        <div v-else>
          <span class="mb-1 text-orange-100 bg-orange-10 inline-flex gap-x-1 items-center px-1">
            <pdl-icon name="error" size="18"></pdl-icon>
            <span class="text-orange-140">{{ errorMessage }}</span>
          </span>
        </div>
        <div
          v-if="selectedPostcode && !hasDrawerDeliveryAddress"
          class="mb-1"
          qaid="selected-retailer-ship-to-postal-code"
        >
          {{ selectedPostcode }}
        </div>
        <pdl-link qaid="selected-retailer-delivery-address-edit" @click.prevent="handlePostcodeClick">
          {{ $t('text.edit') }}
        </pdl-link>
      </div>
    </div>
  </section>
  <section v-else class="h-20">
    <pdl-loading is-loading />
  </section>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import {PdlLabel} from '@pedal/pdl-label';
import {PdlLink} from '@pedal/pdl-link';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlIcon} from '@pedal/pdl-icon';
import AddressSummary from '@/components/AddressSummary';
import RetailerDrawer from '@/components/drawer/retailer/RetailerDrawer.vue';
import PostalCodeDrawer from '@/components/drawer/address/PostalCodeDrawer.vue';
import {getFormattedAddress, populateAddress} from '@/utils/cart/delivery-address-util.js';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  name: 'SelectedRetailer',

  components: {
    PdlLabel,
    AddressSummary,
    PdlLink,
    RetailerDrawer,
    PostalCodeDrawer,
    PdlLoading,
    PdlIcon,
  },

  data() {
    return {
      isRetailerDrawerOpen: false,
      isPostalCodeDrawerOpen: false,
    };
  },

  computed: {
    ...mapState('user', ['selectedRetailer', 'selectedLocation', 'isRetailerLoading', 'drawerDeliveryAddress']),
    ...mapState('checkout', ['billingAddress']),
    ...mapGetters('backend', ['unitAbbreviation', 'countryCode']),
    ...mapGetters('cart', ['cartEntriesQuantityMap']),
    selectedPostcode() {
      return this.selectedLocation?.postcode;
    },
    updateKey() {
      return `${this.selectedPostcode}-${this.selectedRetailer?.name}-${getFormattedAddress(
        this.drawerDeliveryAddress
      )}`;
    },
    hasDrawerDeliveryAddress() {
      return (
        Object.keys(this.drawerDeliveryAddress).length &&
        Object.values(this.drawerDeliveryAddress).some((value) => value !== null)
      );
    },
    errorMessage() {
      if (!this.hasDrawerDeliveryAddress) {
        return this.selectedPostcode
          ? this.$t('checkout.multi.shipToHome.address.detailsNeeded2')
          : this.$t('checkout.multi.shipToHome.address.detailsNeeded1');
      }
      return '';
    },
  },
  watch: {
    async selectedRetailer(newVal, oldVal) {
      if (newVal?.name === oldVal?.name) return;
      const response = await this.updateCartDealerAndCartEntries(this.selectedRetailer.name);
      if (!response) return;
      this.showCartQuantityUpdateToast(response?.entries);
    },
    async selectedLocation(newVal, oldVal) {
      if (newVal?.postcode === oldVal?.postcode) return;
      this.updateCartAddress(await populateAddress(this.countryCode, this.selectedLocation, this.billingAddress));
    },
  },
  methods: {
    ...mapActions('cart', ['updateCartDealerAndCartEntries', 'updateCartAddress']),
    handlePostcodeClick() {
      this.isPostalCodeDrawerOpen = !this.isPostalCodeDrawerOpen;
    },
    handleRetailerClick() {
      this.isRetailerDrawerOpen = !this.isRetailerDrawerOpen;
    },
    showCartQuantityUpdateToast(updatedCartEntries) {
      const hasQuantityChanged = updatedCartEntries.some(({entryNumber, quantity}) => {
        return this.cartEntriesQuantityMap.get(entryNumber) !== quantity;
      });

      if (!hasQuantityChanged) return;

      this.$notify({
        type: PdlToastType.INFO,
        message: this.$t('basket.information.quantity.adjusted'),
        showClose: true,
      });
    },
  },
};
</script>
