<template>
  <div id="cart-details">
    <Transition name="fade">
      <section v-if="errorMessages" class="my-4">
        <payment-init-errors :error-messages="errorMessages" />
      </section>
    </Transition>
    <slot name="promotions" />
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-6 large-8">
        <selected-retailer />
        <pdl-heading v-if="cartEntries.length > 0" size="md" class="mb-1" qaid="choose-delivery-method-header">
          {{ $t('checkout.multi.deliveryMethod.choose.how') }}
        </pdl-heading>
        <pdl-callout v-if="isChooseDeliveryMethodCalloutShown" qaid="choose-delivery-method-callout" kind="error">
          <template #content>
            <span>{{ $t('checkout.multi.deliveryMethod.choose.error') }}</span>
          </template>
        </pdl-callout>
        <cart-unavailable-item-list
          v-if="outOfStockCartItems.length"
          :cart-data="cartData"
          :is-ecomm-available="isEcommAvailable"
          :cart-entries="outOfStockCartItems"
          :is-quantity-control-enabled="true"
        />
        <cart-item-list
          :cart-data="cartData"
          :is-ecomm-available="isEcommAvailable"
          :cart-entries="inStockCartItems"
          :is-quantity-control-enabled="true"
        />
      </div>
      <div class="cell auto">
        <div class="self-end w-full">
          <cart-summary
            :cart-data="cartData || {}"
            :checkout-url="checkoutUrl"
            :home-page-url="homePageUrl"
            :is-apple-pay-enabled-on-cart="isApplePayEnabledOnCart"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {populateAddress} from '@/utils/cart/delivery-address-util.js';
import {hasMatchingDeliveryModeForEntry} from '@/utils/cart/fulfillment-options-util.js';
import trackCart from '@/mixins/tracking/track-cart.js';
import CartItemList from '@/components/cart/b2c/CartItemList.vue';
import CartSummary from '@/components/cart/b2c/CartSummary.vue';
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex';
import {scrollToTop} from '@/utils/scroll-to.js';
import {useIsTranslationLoaded} from '@/composables/is-translation-loaded.js';
import {useStore} from '@/store';
import SelectedRetailer from '@/components/cart/b2c/SelectedRetailer';
import {UserStatus} from '@/constants/backend';
import {PdlToastType} from '@/constants/pdl-toast-type';
import PaymentInitErrors from '@/components/PaymentInitErrors.vue';
import {PdlHeading} from '@pedal/pdl-section-header';
import {PdlCallout} from '@pedal/pdl-callout';
import CartUnavailableItemList from '@/components/cart/b2c/CartUnavailableItemList.vue';

export default {
  components: {
    CartItemList,
    CartSummary,
    SelectedRetailer,
    PaymentInitErrors,
    PdlHeading,
    PdlCallout,
    CartUnavailableItemList,
  },
  mixins: [trackCart],
  provide() {
    const trackProceedCheckout = (function (component) {
      return component.trackProceedCheckout;
    })(this);

    return {
      trackProceedCheckout,
    };
  },
  props: {
    isEcommAvailable: {
      type: Boolean,
      default: () => false,
    },
    isApplePayEnabledOnCart: {
      type: Boolean,
      default: () => false,
    },
    homePageUrl: {
      type: String,
      default: () => '',
    },
    checkoutUrl: {
      type: String,
      default: () => '',
    },
  },
  setup() {
    const store = useStore();
    useIsTranslationLoaded(() => store.dispatch('cart/getCartDataWithValidation'));
    // Using a composable function to solve the translations loaded race condition
  },
  computed: {
    ...mapState('checkout', ['errorMessages', 'outOfDealerDeliveryRangeToast']),
    ...mapState('cart', [
      'cartData',
      'cartFulfillmentOptions',
      'hasClickedProceedWithoutDeliveryModeSelected',
      'hasCartNotSoldErrorToast',
    ]),
    ...mapState('user', ['selectedRetailer', 'selectedLocation', 'drawerDeliveryAddress']),
    ...mapState('backend', ['isUserLoggedIn']),
    ...mapGetters('backend', ['countryCode']),
    ...mapGetters('cart', ['hasDeliveryModeSelectionsMade', 'outOfStockCartItems', 'inStockCartItems']),

    cartEntries() {
      return this.cartData?.entries || [];
    },
    isConvertedToGuest() {
      return this.cartData?.user?.uid && this.cartData.user.uid !== UserStatus.ANONYMOUS_USER;
    },
    isChooseDeliveryMethodCalloutShown() {
      return (
        this.hasClickedProceedWithoutDeliveryModeSelected &&
        !this.hasDeliveryModeSelectionsMade &&
        this.cartEntries.length > 0
      );
    },
  },
  watch: {
    errorMessages(value) {
      if (!value) return;
      scrollToTop();
    },
    async cartData(value) {
      if (!value) return;

      if (this.isPostcodeOnlyNoRetailer(value.deliveryAddress?.postalCode)) {
        await this.loadNearestRetailer();
        return;
      }

      if (this.outOfDealerDeliveryRangeToast) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t('checkout.multi.deliveryMethod.outOfRange'),
          showClose: true,
        });
        this.setOutOfDealerDeliveryRangeToast(false);
      }

      // ***** Various API calls to set info on cart, required context in order to GET fulfillmentOptions *****
      if (!this.isUserLoggedIn && !this.isConvertedToGuest) {
        // Cannot have Anonymous cart, set email to convert to Guest cart & avoid auth error
        await this.updateCartEmail();
      }

      if (
        this.selectedRetailer?.name &&
        (this.isRetailerDifferent(value.retailerForDelivery) ||
          this.isPostcodeDifferent(value.deliveryAddress?.postalCode))
      ) {
        await this.updateCartDealerAndAddress({
          dealer: this.selectedRetailer.name,
          address: await populateAddress(this.countryCode, this.selectedLocation, this.drawerDeliveryAddress),
        });
      }

      if (this.isRetailerSame(value.retailerForDelivery) && this.isPostcodeSame(value.deliveryAddress?.postalCode)) {
        await this.getFulfillmentOptions();

        const invalidEntries = this.getCartEntriesWithInvalidSelections();
        if (invalidEntries.length) {
          this.updateCartEntryOptions({
            dealerCode: this.selectedRetailer.name,
            entries: invalidEntries,
          });
        }
      }
      // ***** End of fulfillmentOptions required context setup *****
    },

    hasCartNotSoldErrorToast(newValue) {
      if (newValue) {
        this.$notify({
          type: PdlToastType.DEFAULT,
          message: this.$t('checkout.multi.pickupInStore.notSoldMessage'),
          showClose: true,
          duration: 0,
        });
        this.setCartNotSoldErrorToast(false);
      }
    },
  },

  methods: {
    ...mapMutations('checkout', ['setOutOfDealerDeliveryRangeToast']),
    ...mapMutations('cart', ['setCartNotSoldErrorToast']),
    ...mapActions('cart', [
      'getCartData',
      'updateCartEmail',
      'updateCartDealerAndAddress',
      'getFulfillmentOptions',
      'updateCartEntryOptions',
    ]),
    ...mapActions('user', ['loadNearestRetailer']),

    isPostcodeOnlyNoRetailer(cartPostcode) {
      return !cartPostcode ? false : !this.selectedRetailer?.name;
    },
    isRetailerDifferent(cartRetailer) {
      return this.selectedRetailer?.name && this.selectedRetailer?.name !== cartRetailer?.name;
    },
    isPostcodeDifferent(cartPostcode) {
      return this.selectedLocation?.postcode && this.selectedLocation?.postcode !== cartPostcode;
    },
    isRetailerSame(cartRetailer) {
      return this.selectedRetailer?.name && cartRetailer?.name === this.selectedRetailer?.name;
    },
    isPostcodeSame(cartPostcode) {
      return this.selectedLocation?.postcode && cartPostcode === this.selectedLocation?.postcode;
    },
    getCartEntriesWithInvalidSelections() {
      return this.cartEntries.filter(
        (entry) => entry.deliveryMode && !hasMatchingDeliveryModeForEntry(entry, this.cartFulfillmentOptions)
      );
    },
  },
};
</script>
