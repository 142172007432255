<template>
  <section>
    <retailer-drawer :is-drawer-open="isRetailerDrawerOpen" @close="handleDrawerClose" />
    <pdl-media-object-list :class="[dynamicClasses]">
      <cart-item
        v-for="(entry, i) of cartEntries"
        :key="`cart-item-${entry.product.code}-${i}`"
        :is-ecomm-available="isEcommAvailable"
        :entry="entry"
        :is-quantity-control-enabled="isQuantityControlEnabled"
        :has-mixed-promo-items="hasMixedPromoItems"
      />
    </pdl-media-object-list>
  </section>
</template>

<script>
import CartItem from '@/components/cart/b2c/CartItem.vue';
import {PdlMediaObjectList} from '@pedal/pdl-media-object';
import RetailerDrawer from '../../drawer/retailer/RetailerDrawer.vue';
import {mapMutations, mapState} from 'vuex';

export default {
  components: {
    CartItem,
    PdlMediaObjectList,
    RetailerDrawer,
  },
  props: {
    cartData: {
      type: Object,
      required: true,
    },
    cartEntries: {
      type: Array,
      required: true,
    },
    isEcommAvailable: {
      type: Boolean,
      required: true,
    },
    isQuantityControlEnabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('cart', ['outOfStockProductSku']),
    dynamicClasses() {
      return {
        'border-b': !this.cartEntries.length,
        'border-gray-10': !this.cartEntries.length,
      };
    },
    hasMixedPromoItems() {
      return (
        this.cartData?.entries?.length > 1 &&
        !!this.cartData?.appliedProductPromotions?.length &&
        !this.cartData?.entries?.every((entry) => entry.isLineLevelPromotionPresent)
      );
    },
    isRetailerDrawerOpen() {
      return !!this.outOfStockProductSku;
    },
  },
  methods: {
    ...mapMutations('cart', ['setOutOfStockProductSku']),
    handleDrawerClose() {
      this.setOutOfStockProductSku(null);
    },
  },
};
</script>
