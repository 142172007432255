<template>
  <section class="mb-3">
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('text.userDetails') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <table class="b2b-table b2b-table--narrow b2b-table--borderless max-w-sm">
      <caption class="sr-only">
        {{
          $t('text.userDetails')
        }}
      </caption>
      <tbody v-if="items">
        <tr>
          <th scope="row">{{ $t('text.name') }}</th>
          <td qaid="view-user-name">{{ items?.name }}</td>
        </tr>
        <tr>
          <th scope="row">{{ $t('text.address.email') }}</th>
          <td qaid="view-user-email">{{ items?.emailAddress }}</td>
        </tr>
        <tr>
          <th scope="row">{{ $t('text.storeName') }}</th>
          <td qaid="view-user-store">{{ items?.businessUnit }}</td>
        </tr>
        <tr>
          <th scope="row">{{ $t('b2busergroup.B2BRole') }}</th>
          <td qaid="view-user-role">{{ $t(`${items.role.name}`) }}</td>
        </tr>
        <tr v-if="items.rideClubRole">
          <th scope="row">{{ $t('b2busergroup.rideClubRole') }}</th>
          <td qaid="view-user-ride-club-role">{{ $t(`${items.rideClubRole.name}`) }}</td>
        </tr>
        <tr v-if="items.hasOwnProperty('isDstAuthorized')">
          <th scope="row">{{ $t('myCompany.dstPermission') }}</th>
          <td qaid="view-dst-permission">{{ dstStatusText }}</td>
        </tr>
        <tr>
          <th scope="row">{{ $t('text.userStatus') }}</th>
          <td qaid="view-user-userstatus">{{ userStatusText() }}</td>
        </tr>
      </tbody>
    </table>
    <div class="buttons my-4">
      <pdl-link
        id="editUserButton"
        button
        primary
        :href="`${editButtonRef}?userPk=${items.uid}`"
        qaid="edit-user-button"
      >
        {{ $t('text.editUser') }}
      </pdl-link>
    </div>
  </section>
</template>

<script>
import {PdlLink} from '@pedal/pdl-link';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {PdlLink, PdlSectionHeader, PdlHeading},
  props: {
    editButtonRef: {
      type: String,
      default: '',
    },
    items: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    dstStatusText() {
      return this.items.isDstAuthorized ? this.$t('profile.optIn.yes') : this.$t('profile.optIn.no');
    },
  },

  methods: {
    userStatusText() {
      return this.items.userStatus ? this.$t('text.active') : this.$t('text.inactive');
    },
  },
};
</script>
