<template>
  <pdl-collapse-item :key="`${type}-${warehouse.id}`" :name="`${type}-${warehouse.id}`">
    <div v-if="isCustomWaterBottle" slot="title" class="heading heading--sm" qaid="custom-water-bottle-heading">
      <span>{{ $t('checkout.custom.shippingCost') }} </span>
    </div>
    <div v-else-if="!isProjectOne" slot="title" class="heading heading--sm" qaid="distributionCenter-heading">
      {{ warehouse.name }} ({{ warehouseSubtotals.quantity }})
    </div>

    <!-- Callout for Current Availability -->
    <pdl-callout
      v-if="isUnavailable && !isCustomWaterBottle && !isProjectOne"
      kind="warning"
      qaid="distributionCenter-backorders-warning"
    >
      <template #content>
        <span>{{ $t('checkout.B2B.backordered.primaryWarehouse') }}</span>
      </template>
    </pdl-callout>

    <div v-if="showShippingInfo" class="mt-2 mb-5" qaid="distributionCenter-shipOptions">
      <fieldset class="form-fieldset">
        <legend class="form-fieldset__legend text-md">{{ $t('checkout.B2B.shipOrBackorderPrimary') }}</legend>
        <label class="radio text-md">
          <input v-model="isBackordered" type="radio" :value="true" />
          <span class="control-indicator"></span>
          {{ $t('text.backorderInPrimary') }}
        </label>
        <label class="radio text-md">
          <input v-model="isBackordered" type="radio" :value="false" />
          <span class="control-indicator"></span>
          {{ $t('text.shipLines') }}
        </label>
      </fieldset>
    </div>
    <pdl-tabs>
      <!-- SKU Tab -->
      <pdl-tab-pane :label="$t('text.account.savedLists.sku')">
        <!-- SKU GRID -->
        <table
          aria-label="saved-list-sku-grid"
          class="b2b-grid b2b-grid--collapse is-compact"
          qaid="saved-list-sku-grid"
        >
          <caption class="sr-only">
            {{
              $t('product.details.nav.label.skuGrid')
            }}
          </caption>
          <!-- SKU Grid Headers -->
          <thead class="b2b-grid__header">
            <tr>
              <th scope="col" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-qty">
                {{ $t('text.account.savedLists.qty') }}
              </th>
              <th scope="col" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-split"></th>

              <th scope="col" class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid-sku">
                {{ $t('text.account.savedLists.sku') }}
              </th>

              <th scope="col" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-item">{{ $t('order.item') }}</th>

              <th
                v-if="!isProjectOne"
                scope="col"
                class="b2b-grid__cell b2b-grid__head uppercase"
                qaid="sku-grid-upc-ean"
              >
                {{ $t('text.account.savedLists.upc') }}
              </th>

              <th
                v-if="isCustomWaterBottle || isProjectOne"
                scope="col"
                class="b2b-grid__cell b2b-grid__head lg:text-right"
                qaid="sku-grid-custom-id"
              >
                <span>{{ $t('checkout.custom.ID') }}</span>
              </th>
              <template v-else>
                <th scope="col" class="b2b-grid__cell b2b-grid__head lg:text-right" qaid="sku-grid-allocated">
                  <span
                    v-tippy="{
                      html: '#abbreviation-text',
                      trigger: 'mouseenter',
                    }"
                  >
                    {{ $t('backordersAllocated.B2B.alloc') }}
                  </span>
                </th>

                <th
                  v-if="!isProjectOne"
                  scope="col"
                  class="b2b-grid__cell b2b-grid__head lg:text-right"
                  qaid="sku-grid-backordered"
                >
                  <span
                    v-tippy="{
                      html: '#bo-abbreviation-text',
                      trigger: 'mouseenter',
                    }"
                  >
                    {{ $t('backordersAllocated.B2B.bo') }}
                  </span>
                </th>
              </template>
              <th
                v-if="!isConsumerFriendlyMode && !isProjectOne"
                scope="col"
                class="b2b-grid__cell b2b-grid__head"
                qaid="sku-grid-order-price"
              >
                {{ $t('order.price') }}
              </th>

              <th
                v-if="!isConsumerFriendlyMode"
                scope="col"
                class="b2b-grid__cell b2b-grid__head text-right"
                qaid="sku-grid-subtotal"
              >
                {{ $t('text.order.subtotal') }}
              </th>
            </tr>
          </thead>
          <span id="abbreviation-text" class="hidden">{{ $t('text.allocated') }}</span>
          <span id="bo-abbreviation-text" class="hidden">{{ $t('text.backordered') }}</span>
          <!-- SKU Grid Body/Rows -->
          <tbody>
            <!-- For each Product/Row -->
            <checkout-grid-row
              v-for="(item, index) in paginatedData"
              :key="item.sku + '_' + index"
              :item="item"
              :row-index="index"
              :is-custom-water-bottle="isCustomWaterBottle"
              :is-project-one="isProjectOne"
            />
          </tbody>
        </table>

        <div class="grid-x grid-margin-x mb-5">
          <div class="cell medium-6 large-8">
            <pagination-controls
              v-if="pageCount > 1"
              ref="pagination"
              :page-number="pageNumber"
              :page-count="pageCount"
              @page-change="pageChange"
              @prev-page="prevPage"
              @next-page="nextPage"
            ></pagination-controls>
          </div>
          <div v-if="!isConsumerFriendlyMode" class="cell medium-6 large-4">
            <!-- Shipment Summary -->
            <ul class="block-list text-md">
              <li class="block-list__item" qaid="shipmentSummary-subtotal">
                <span>{{ $t('checkout.B2B.warehouseSubtotal') }}</span>
                <span>{{ warehouseSubtotals.prices.subtotal.formattedValue }}</span>
              </li>
            </ul>
          </div>
        </div>
      </pdl-tab-pane>
      <pdl-tab-pane v-if="displayAppleLabels" :label="$t('appleLabel.title')">
        <pdl-callout v-if="!cartToggle" kind="error">
          <template #content>
            <span>{{ $t('text.systemDown.cannotSubmit') }}</span>
          </template>
        </pdl-callout>

        <apple-label-grid :key="warehouse.id" :warehouse-id="warehouse.id" :type="type"></apple-label-grid>
      </pdl-tab-pane>
    </pdl-tabs>
  </pdl-collapse-item>
</template>

<script>
import pagination from '../../../mixins/pagination';
import {mapState, mapGetters, mapMutations} from 'vuex';
import {PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import CheckoutGridRow from '@/components/checkout/v2/CheckoutGridRow';
import PaginationControls from '@/components/PaginationControls';
import AppleLabelGrid from '@/components/checkout/v2/AppleLabelGrid';
import {PdlCallout} from '@pedal/pdl-callout';
import {ProductType, ProductDeliveryStatus} from '@/constants/product';

export default {
  components: {
    PdlCollapseItem,
    PdlTabs,
    PdlTabPane,
    CheckoutGridRow,
    PaginationControls,
    AppleLabelGrid,
    PdlCallout,
  },

  mixins: [pagination],

  props: {
    size: {
      type: Number,
      default: ACC.checkoutItemsPageSize || 50,
    },
    type: {
      type: String,
      default: '',
    },
    entriesByWarehouse: {
      type: Array,
      default: () => [],
    },
    warehouse: {
      type: Object,
      required: true,
    },
    warehouseId: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState('backend', ['cartToggle', 'isConsumerFriendlyMode']),
    ...mapState('shipments', ['warehousesSubtotals', 'areQuantitiesPristine', 'entries']),
    ...mapGetters('shipments', ['appleLabelAccess', 'areQuantitiesUpdated']),

    isBackordered: {
      get() {
        return this.entries.find((entry) => entry.warehouse === this.warehouse.id).isBackorderInPrimary;
      },
      set(value) {
        this.updateBackorderStatus({warehouse: this.warehouse.id, value});
      },
    },

    warehouseSubtotals() {
      return this.warehousesSubtotals[`${this.type}_${this.warehouse.id}`];
    },

    isUnavailable() {
      return this.warehouse.id === ProductDeliveryStatus.BACKORDERED;
    },

    displayAppleLabels() {
      return this.appleLabelAccess[this.type];
    },

    showShippingInfo() {
      return !this.warehouse.isPrimary && !this.isUnavailable;
    },

    listData() {
      return this.entriesByWarehouse;
    },

    isCustomWaterBottle() {
      return this.type === ProductType.CustomWaterBottles;
    },

    isProjectOne() {
      return this.type === ProductType.ProjectOne;
    },
  },
  mounted() {
    if (this.areQuantitiesUpdated) {
      this.resetBackorderState(true);
    } else {
      const singleEntrySubtotalsByWarehouse = this.entriesByWarehouse[0].subtotalsByWarehouse[this.warehouse.id];
      const areEntriesForShip = singleEntrySubtotalsByWarehouse.availableQuantity > 0;
      if (areEntriesForShip && this.areQuantitiesPristine) {
        if (singleEntrySubtotalsByWarehouse.quantity === singleEntrySubtotalsByWarehouse.availableQuantity) {
          this.updateBackorderStatus({warehouse: this.warehouse.id, value: false});
        } else {
          this.resetBackorderState(true);
          this.setQuantitiesPristineState(false);
        }
      }
    }
  },

  methods: {
    ...mapMutations('shipments', ['resetBackorderState', 'setQuantitiesPristineState', 'updateBackorderStatus']),
  },
};
</script>
