const AddressFormComponents = Object.freeze({
  FIRST_NAME: {
    field: 'firstName',
    qaid: 'checkout-delivery-address__first-name-container',
    component: 'FirstNameField',
  },
  SURNAME: {
    field: 'surname',
    qaid: 'checkout-delivery-address__last-name-container',
    component: 'SurnameField',
  },
  PHONE: {
    field: 'phone',
    qaid: 'checkout-delivery-address__contact-container',
    component: 'PhoneField',
  },
  LINE1: {
    field: 'line1',
    qaid: 'checkout-delivery-address__address-line1-container',
    component: 'Line1Field',
  },
  LINE2: {
    field: 'line2',
    qaid: 'checkout-delivery-address__address-line2-container',
    component: 'Line2Field',
  },
  TOWN_CITY: {
    field: 'townCity',
    qaid: 'checkout-delivery-address__city-container',
    component: 'TownCityField',
  },
  REGION: {
    field: 'region',
    qaid: 'checkout-delivery-address__region-container',
    component: 'RegionField',
  },
  POSTCODE: {
    field: 'postcode',
    qaid: 'checkout-delivery-address__postcode-container',
    component: 'PostcodeField',
  },
});

const AddressFormConfig = {
  default: {
    personalDetails: [AddressFormComponents.FIRST_NAME, AddressFormComponents.SURNAME, AddressFormComponents.PHONE],
    addressDetails: [
      AddressFormComponents.LINE1,
      AddressFormComponents.LINE2,
      AddressFormComponents.TOWN_CITY,
      AddressFormComponents.REGION,
      AddressFormComponents.POSTCODE,
    ],
  },
  kr: {
    personalDetails: [AddressFormComponents.SURNAME, AddressFormComponents.FIRST_NAME, AddressFormComponents.PHONE],
    addressDetails: [AddressFormComponents.LINE1, AddressFormComponents.LINE2, AddressFormComponents.POSTCODE],
  },
  de: {
    personalDetails: [AddressFormComponents.FIRST_NAME, AddressFormComponents.SURNAME, AddressFormComponents.PHONE],
    addressDetails: [
      AddressFormComponents.LINE1,
      AddressFormComponents.LINE2,
      AddressFormComponents.POSTCODE,
      AddressFormComponents.TOWN_CITY,
    ],
  },
  deelectra: {
    personalDetails: [AddressFormComponents.FIRST_NAME, AddressFormComponents.SURNAME, AddressFormComponents.PHONE],
    addressDetails: [
      AddressFormComponents.LINE1,
      AddressFormComponents.LINE2,
      AddressFormComponents.POSTCODE,
      AddressFormComponents.TOWN_CITY,
    ],
  },
  at: {
    personalDetails: [AddressFormComponents.FIRST_NAME, AddressFormComponents.SURNAME, AddressFormComponents.PHONE],
    addressDetails: [
      AddressFormComponents.LINE1,
      AddressFormComponents.LINE2,
      AddressFormComponents.POSTCODE,
      AddressFormComponents.TOWN_CITY,
    ],
  },
  atelectra: {
    personalDetails: [AddressFormComponents.FIRST_NAME, AddressFormComponents.SURNAME, AddressFormComponents.PHONE],
    addressDetails: [
      AddressFormComponents.LINE1,
      AddressFormComponents.LINE2,
      AddressFormComponents.POSTCODE,
      AddressFormComponents.TOWN_CITY,
    ],
  },
  jp: {
    personalDetails: [AddressFormComponents.SURNAME, AddressFormComponents.FIRST_NAME, AddressFormComponents.PHONE],
    addressDetails: [
      AddressFormComponents.POSTCODE,
      AddressFormComponents.REGION,
      AddressFormComponents.LINE1,
      AddressFormComponents.LINE2,
    ],
  },
};

const getAddressFormConfig = (locale) => {
  return AddressFormConfig[locale] ?? AddressFormConfig.default;
};

const isNameField = (fieldName) => {
  return [AddressFormComponents.FIRST_NAME.field, AddressFormComponents.SURNAME.field].includes(fieldName);
};

export {getAddressFormConfig, isNameField, AddressFormComponents};
