<template>
  <div class="space-y-1" qaid="premium-home-delivery-fulfillment-block">
    <div class="flex gap-x-1 items-center">
      <premium-home-delivery-icon />
      <span qaid="premium-home-delivery-header" class="text-md font-bold">{{ $t(option.name) }}</span>
    </div>
    <div v-if="isInRange" class="gap-x-1 text-green-140">
      <pdl-icon name="check_circle" size="18" />
      <span qaid="premium-inrange-message">{{ $t(dealerDeliveryMessage) }}</span>
    </div>
    <div v-else class="gap-x-1 text-red-100">
      <pdl-icon name="cancel" size="18" />
      <span qaid="premium-not-inrange-message">{{ $t(dealerDeliveryMessage) }}</span>
    </div>
    <pdl-button qaid="premium-change-address-button" small underline text @click="setIsDrawerOpen(true)">
      {{ $t('fulfillment.premium.change.address') }}
    </pdl-button>
  </div>
</template>

<script>
import PremiumHomeDeliveryIcon from '@/components/containers/pdp/b2c/fulfillment-options/premium-home-delivery/PremiumHomeDeliveryIcon.vue';
import {toRefs} from 'vue';
import {useFulfillmentOptionMessaging} from '@/composables/fulfillment-option-messaging.js';
import {PdlButton} from '@pedal/pdl-button';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PremiumHomeDeliveryIcon,
    PdlButton,
    PdlIcon,
  },
  props: {
    setIsDrawerOpen: {
      type: Function,
      default: () => {},
    },
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {option} = toRefs(props);
    const {dealerDeliveryMessage} = useFulfillmentOptionMessaging(option);

    return {
      dealerDeliveryMessage,
    };
  },
  computed: {
    isInRange() {
      return this.option?.inPremiumDeliveryRange;
    },
  },
};
</script>
