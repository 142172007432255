<template>
  <div class="form-group--inline">
    <label :for="id" class="form-label">
      {{ label }}
    </label>
    <div class="select select--inline select--clear w-auto">
      <select :id="id" v-model="currentValue" class="form-control">
        <option v-for="(option, i) in options" :key="`option-${id}-${i}`" :value="option.code">
          {{ $t(option.name) }}
        </option>
      </select>
      <div class="focus-indicator"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewOptionSelectBox',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentValue: null,
    };
  },
  computed: {
    currentValueDisplayText() {
      if (!this.currentValue || !this.options) {
        return '';
      }
      for (let option of this.options) {
        if (option.value === this.currentValue) {
          return option.text;
        }
      }
      return '';
    },
  },
  watch: {
    value(value) {
      if (value !== this.currentValue) {
        this.currentValue = value;
      }
    },
    currentValue(value) {
      this.$emit('updated', value);
    },
  },
  mounted() {
    this.currentValue = this.value;
  },
};
</script>
