<template>
  <div>
    <div class="heading heading--sm mt-4 mb-2" qaid="distributionCenter-heading">
      {{ warehouse.name }} ({{ warehouse.count }})
    </div>

    <!-- Callout for Current Availability -->
    <pdl-callout v-if="hasBackorders" kind="warning" qaid="distributionCenter-backorders-warning">
      <template #content>
        <span>{{ $t('checkout.B2B.backordered.primaryWarehouse') }}</span>
      </template>
    </pdl-callout>

    <div v-if="showShippingInfo" class="mt-2 mb-5" qaid="distributionCenter-shipOptions">
      <fieldset class="form-fieldset">
        <legend class="form-fieldset__legend text-md">{{ $t('checkout.B2B.shipOrBackorderPrimary') }}</legend>
        <label class="radio text-md">
          <input v-model="isBackordered" type="radio" :value="true" />
          <span class="control-indicator"></span>
          {{ $t('text.backorderInPrimary') }}
        </label>
        <label class="radio text-md">
          <input v-model="isBackordered" type="radio" :value="false" />
          <span class="control-indicator"></span>
          {{ $t('text.shipLines') }}
        </label>
      </fieldset>
    </div>

    <!-- SKU GRID -->
    <table class="b2b-grid b2b-grid--collapse is-compact" qaid="saved-list-sku-grid">
      <caption class="sr-only">
        {{
          $t('product.details.nav.label.skuGrid')
        }}
      </caption>
      <!-- SKU Grid Headers -->
      <thead class="b2b-grid__header">
        <tr>
          <th id="sku-grid-split" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-split"></th>

          <th id="sku-grid-sku" class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid-sku">
            {{ $t('text.account.savedLists.sku') }}
          </th>

          <th id="sku-grid-item" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-item">{{ $t('order.item') }}</th>

          <th id="sku-grid-upc-ean" class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid-upc-ean">
            {{ $t('text.account.savedLists.upc') }}
          </th>

          <th id="sku-grid-order-price" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-order-price">
            {{ $t('order.price') }}
          </th>

          <th id="sku-grid-qty" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-qty">
            {{ $t('text.account.savedLists.qty') }}
          </th>

          <th id="sku-grid-subtotal" class="b2b-grid__cell b2b-grid__head text-right" qaid="sku-grid-subtotal">
            {{ $t('text.order.subtotal') }}
          </th>
        </tr>
      </thead>

      <!-- SKU Grid Body/Rows -->
      <tbody>
        <!-- For each Product/Row -->
        <template v-for="item in listData">
          <checkout-grid-row
            :key="item[itemIdKey]"
            :item="item"
            :is-backorder="hasBackorders"
            :for-print="forPrint"
            :checkout-step="checkoutStep"
          />

          <checkout-notes-row :key="`${partTypeKey}-${item[itemIdKey]}-notes`" :item="item" :for-print="forPrint" />
          <checkout-promo-row :key="`${partTypeKey}-${item[itemIdKey]}-promo`" :item="item" :for-print="forPrint" />
        </template>
      </tbody>
    </table>

    <div class="grid-x grid-margin-x mb-5">
      <div class="cell medium-6 large-8"></div>
      <div class="cell medium-6 large-4">
        <!-- Shipment Summary -->
        <ul class="block-list text-md">
          <li class="block-list__item" qaid="shipmentSummary-subtotal">
            <span>{{ $t('text.order.subtotal') }}</span>
            <span>{{ warehouse.subTotal }}</span>
          </li>
          <li v-if="!hasBackorders && !isDistributor" class="block-list__item" qaid="shipmentSummary-estimatedShipping">
            <span>{{ $t('text.estimatedShipping') }}</span>
            <span>{{ warehouse.formattedEstimatedShipping }}</span>
          </li>
          <li class="block-list__item" qaid="shipmentSummary-paymentTerms">
            <span class="grow shrink-0">{{ $t('text.paymentTerms') }}</span>
            <span class="shrink grow-0 text-right">{{ warehouse.paymentTerms }}</span>
          </li>
          <li class="block-list__item text-base font-medium" qaid="shipmentSummary-total">
            <span>{{ $t('text.warehouseTotal') }}</span>
            <span>{{ warehouse.total }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from '../../mixins/pagination';
import {mapState} from 'vuex';
import {PdlCallout} from '@pedal/pdl-callout';
import CheckoutGridRow from '@/components/checkout/CheckoutGridRow';
import {ProductDeliveryStatus} from '@/constants/product';

export default {
  components: {
    PdlCallout,
    CheckoutGridRow,
  },
  mixins: [pagination],

  props: {
    hasShippingOptions: {
      type: Boolean,
      default: false,
    },
    itemIdKey: {
      type: String,
      default: '',
    },
    partTypeKey: {
      type: String,
      default: '',
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    checkoutStep: {
      type: String,
      default: '',
    },
    forPrint: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      required: false,
      default: ACC.checkoutItemsPageSize || 50,
    },
    type: {
      type: String,
      default: '',
    },
    warehouse: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      isBackordered: true,
    };
  },

  computed: {
    hasBackorders() {
      return this.warehouse.id === ProductDeliveryStatus.BACKORDERED;
    },

    showShippingInfo() {
      return this.hasShippingOptions && !this.warehouse.isPrimary && !this.hasBackorders;
    },

    listData() {
      return this.warehouse.entries;
    },

    ...mapState('backend', ['isDistributor']),
  },

  watch: {
    isBackordered: {
      immediate: true,
      handler(value) {
        this.$emit('update-is-backordered', value);
      },
    },
  },

  methods: {
    getCollapseItemName(partTypeKey, itemIdKey, index) {
      return `${partTypeKey}-${itemIdKey}-${index}`;
    },
  },
};
</script>
