<template>
  <div>
    <form v-if="localItems.length" qaid="apple-label-form" class="apple-label-form" @submit.prevent="saveAppleLabels">
      <table qaid="apple-label-table" class="apple-label-table b2b-grid b2b-grid--collapse is-compact">
        <caption class="sr-only">
          {{
            $t('appleLabel.bulk')
          }}
        </caption>
        <!-- Headers -->
        <thead class="b2b-grid__header">
          <tr>
            <th
              v-for="column in getColumns()"
              :key="column.key"
              class="b2b-grid__cell b2b-grid__head"
              :qaid="`apple-label-heading.${column.key}`"
              :v-if="column.key == 'advertisedPrice' ? `${column.isVisible}` : true"
              :class="[column.cssClasses, column.isVisible]"
            >
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <!-- Apple Labels Grid Body/Rows -->
        <tbody>
          <!-- For Each Product Row -->
          <template v-for="(item, itemIndex) in uniqSkuLocalItems">
            <apple-label-grid-item
              :key="item.sku + '_' + itemIndex"
              :errors="errors"
              :item="item"
              :columns="columns"
              :disable-toggles="!cartToggle"
              :item-index="itemIndex"
              @update-item="updateItem($event)"
              @update-is-dirty="updateIsDirty($event, item)"
            />
          </template>
        </tbody>
      </table>

      <div class="buttons buttons--right-for-md">
        <trek-button
          primary
          small
          type="submit"
          :disabled="!isFormDirty || saveInProgress || formHasErrors"
          qaid="apple-label-button.save"
        >
          {{ $t('text.saveChanges') }}
        </trek-button>
        <trek-button secondary small qaid="apple-label-button.clear" @click="dialogVisible = true">
          {{ $t('search.nav.clear') }}
        </trek-button>
      </div>

      <pdl-dialog
        :visible.sync="dialogVisible"
        :title="$t('appleLabel.confirmClearChanges')"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
        :z-index="16000011"
        :close-dialog-tooltip="$t('popup.close')"
        qaid="apple-label-change-dialog"
      >
        <p>{{ $t('text.allChangesWillBeLost') }}</p>

        <template slot="footer">
          <div class="buttons buttons--right">
            <trek-button alert qaid="yes-clear-changes-button" @click="clearChanges">
              {{ $t('text.yesClearChanges') }}
            </trek-button>
            <trek-button secondary qaid="go-back-button" @click="dialogVisible = false">
              {{ $t('text.noGoBack') }}
            </trek-button>
          </div>
        </template>
      </pdl-dialog>
    </form>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import uniqBy from 'lodash/uniqBy';
import {mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import AppleLabelGridItem from '@/components/checkout/AppleLabelGridItem';
import {PdlDialog} from '@pedal/pdl-dialog';
import {currencyToString} from '../../utils/util';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {AppleLabelGridItem, PdlDialog, TrekButton},

  mixins: [TrekValidationMixin],

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    baseProduct: {
      type: String,
      default: '',
    },
    textClearButton: {
      type: String,
      default: 'Clear',
    },
    textSaveButton: {
      type: String,
      default: 'Save Changes',
    },
    textCannotSave: {
      type: String,
      default: '....',
    },
    vvScope: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      localItems: this.getLocalItems(),
      saveInProgress: false,
      formDirty: false,
      dialogVisible: false,
      keyCounter: 0,
    };
  },

  computed: {
    ...mapState('backend', ['cartToggle', 'isConsumerFriendlyMode']),

    activeItems() {
      return this.uniqSkuLocalItems.filter((item) => {
        return item.dirty || item.retailLabelStatus;
      });
    },

    itemsToUpdate() {
      return this.activeItems.map(
        ({
          id,
          sku,
          retailLabelStatus: status,
          retailLabelProductId: partNumber,
          retailLabelDescription: description,
          retailLabelPrice: price,
        }) => {
          return {
            id,
            sku,
            status,
            partNumber,
            description,
            price,
          };
        }
      );
    },

    formHasErrors() {
      return !!this.v$.$errors.length;
    },

    hasToggledOnRows() {
      return this.localItems.some((item) => item.dirty);
    },

    hasDirtyValidatedFields() {
      return this.v$.$anyDirty;
    },

    isFormDirty() {
      return this.hasDirtyValidatedFields || this.hasToggledOnRows;
    },

    uniqSkuLocalItems() {
      return uniqBy(this.localItems, 'sku');
    },

    columns() {
      return [
        {
          label: this.$t('appleLabel.skus'),
          key: 'sku',
          cssClasses: '',
        },
        {
          label: this.$t('appleLabel.label'),
          key: 'retailLabelStatus',
          cssClasses: '',
        },
        {
          label: this.$t('appleLabel.description'),
          key: 'retailLabelDescription',
          cssClasses: '',
        },
        {
          label: this.$t('appleLabel.partNumber'),
          key: 'retailLabelProductId',
          cssClasses: 'lg:w-64',
        },
        {
          label: this.$t('appleLabel.price'),
          key: 'retailLabelPrice',
          cssClasses: 'lg:w-64',
        },
        {
          label: this.$t('text.msrp'),
          key: 'advertisedPrice',
          cssClasses: 'lg:w-14',
        },
      ];
    },
  },

  watch: {
    items() {
      this.localItems = this.getLocalItems();
    },
    isConsumerFriendlyMode() {
      this.getColumns();
    },
  },

  mounted() {
    window.addEventListener('beforeunload', this.warnUser);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.warnUser);
  },

  methods: {
    getColumns() {
      this.columns.forEach((column) => {
        if (column.key == 'advertisedPrice') {
          column['isVisible'] = '';
          if (this.isConsumerFriendlyMode) {
            column.isVisible = 'hidden';
          } else {
            column.isVisible = 'table-cell';
          }
        }
      });
      return this.columns;
    },
    getLocalItems() {
      return structuredClone(this.items).map((item) => {
        this.$set(item, 'initialRetailLabelStatus', item.retailLabelStatus);
        this.$set(item, 'initialRetailLabelPrice', currencyToString(item.retailLabelPrice));
        this.$set(item, 'dirty', false);
        return item;
      });
    },
    resetLocalItems() {
      // return localItems to their initial state
      const initialItems = this.getLocalItems();

      this.localItems.forEach((item, index) =>
        Object.keys(item).forEach((key) => {
          item[key] = initialItems[index][key];
        })
      );
    },

    saveAppleLabels() {
      // If the b2bCartToggle has turned off asyncronously,
      // send a toast, and don't attempt to save any data.
      if (!this.cartToggle) {
        this.clearChanges();

        return this.$notify({
          type: PdlToastType.ERROR,
          message: this.textCannotSave,
        });
      }

      this.saveInProgress = true;

      this.v$.$validate().then((isValid) => {
        if (!isValid) {
          this.saveInProgress = false;
          return;
        }

        storefrontInstance
          .patch('/apple-labels/', this.itemsToUpdate)
          .then(({data: {data}}) => {
            data.forEach((item) => {
              let targetRow = this.items.findIndex((localitem) => {
                return localitem.sku == item.sku;
              });
              this.$set(this.localItems[targetRow], 'initialRetailLabelPrice', item.price);
              this.$set(this.localItems[targetRow], 'initialRetailLabelStatus', item.status);
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // After sending is done, we restore the formatted pricing for rows being toggled off
            this.localItems.map((item) => {
              this.$set(item, 'retailLabelPrice', item.initialRetailLabelPrice);
              return item;
            });
            this.saveInProgress = false;
            this.v$.$reset();
            this.resetDirtyItems();
          });
      });
    },

    clearErrors(scope) {
      this.$children.forEach((child) => {
        if (child.$options._componentTag === 'apple-label-grid-item') {
          child.errors.clear(scope);
        }
      });
    },

    clearChanges() {
      this.keyCounter++;
      this.resetLocalItems();
      this.dialogVisible = false;
      this.v$.$reset();
      return this.errors.clear();
    },

    warnUser(e) {
      if (this.isFormDirty) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },

    resetDirtyItems() {
      this.localItems.forEach((item) => (item.dirty = false));
    },

    updateItem(item) {
      const itemIndex = this.localItems.findIndex((localItem) => localItem.sku === item.sku);
      this.localItems[itemIndex] = item;
    },

    updateIsDirty(value, item) {
      const itemIndex = this.localItems.findIndex((localItem) => localItem.sku === item.sku);
      this.localItems[itemIndex].dirty = value;
    },
  },
};
</script>
