<template>
  <div class="my-3 space-y-3">
    <benefit-messaging-card
      v-for="benefit in benefitMessagingArr"
      :key="benefit.benefitId"
      :benefit="benefit"
      :free-shipping-threshold="freeShippingThreshold"
    />
  </div>
</template>

<script>
import BenefitMessagingCard from '@/components/drawer/benefit-messaging/BenefitMessagingCard.vue';
import {BenefitMessagingApi} from '@/api/benefit-messaging/drawer-benefit-messaging';
import {mapState} from 'vuex';

export default {
  components: {BenefitMessagingCard},
  data() {
    return {
      benefitMessagingArr: [
        {
          benefitIcon: 'ShipToHomeIcon',
          benefitHeader: 'fulfillment.shipToHome.heading',
          benefitDescription: 'fulfillment.shipToHome.address.message',
          benefitId: 'ship-to-home',
        },
        {
          benefitIcon: 'PremiumHomeDeliveryIcon',
          benefitHeader: 'fulfillment.premium.heading',
          benefitDescription: 'fulfillment.premium.address.confirm',
          benefitId: 'premium-home-delivery',
        },
      ],
      freeShippingThreshold: '',
    };
  },
  computed: {
    ...mapState('backend', ['languageISO']),
  },
  async mounted() {
    this.freeShippingThreshold = await BenefitMessagingApi.freeShippingThreshold(this.languageISO.split('_')[1]);
  },
};
</script>
