<template>
  <div v-if="paymentInfo" qaid="payment-method-section">
    <pdl-label :qaid="`${qaid}-title`" class="mb-1">{{ $t('checkout.summary.billing_details') }}</pdl-label>
    <address-summary :address="paymentInfo.billingAddress" :qaid="qaid" />
    <div v-if="isThirdPartyPaymentOption" :qaid="`${qaid}-payment-info`">
      <p v-if="hasLogo" class="flex items-center">
        <span :qaid="`${qaid}-payment-method-name`">{{ paymentMethodName }}</span>
        <span class="ml-1">
          <pdl-image
            v-if="isIdeal"
            :src="paymentOptionImgSrc"
            alt=""
            class="h-6"
            :qaid="`${qaid}-payment-method-logo`"
          />
          <apple-pay-icon v-else :qaid="`${qaid}-payment-method-logo`" class="ml-1" />
        </span>
      </p>
      <p v-if="hasBankName">
        <span :qaid="`${qaid}-bank-name-label`">{{ $t('text.bank') }}: </span>
        <span :qaid="`${qaid}-bank-name-value`">{{ paymentInfo.paymentOptionName }}</span>
      </p>
      <div v-if="isShowingPaymentMethod && isCardDataAvailable" :qaid="`${qaid}-payment-info`" class="mt-2">
        <p>{{ $t('checkout.multi.paymentMethod.cardEnding', [paymentInfo.cardNumber]) }}</p>
        <p>{{ $t('checkout.summary.expiry_date') }} {{ paymentInfo.expiryMonth }}/{{ paymentInfo.expiryYear }}</p>
      </div>
    </div>
    <div v-else-if="isCardDataAvailable" :qaid="`${qaid}-payment-info`" class="mt-2">
      <p>{{ paymentInfo.cardTypeData.name }}: {{ paymentInfo.cardNumber }}</p>
      <p>{{ $t('checkout.summary.expiry_date') }} {{ paymentInfo.expiryMonth }}/{{ paymentInfo.expiryYear }}</p>
    </div>
    <form v-if="isSecurityCodeRequired" class="form-group required" method="post">
      <div class="form__item form__item-holder--small">
        <label for="cvvNumber" class="form__label text">
          {{ $t('checkout.payment.cvv.security.code') }}
          <span class="text-red ml-25">
            *
            <span class="show-for-sr">(required)</span>
          </span>
        </label>
        <input
          id="cvvNumber"
          type="text"
          qaid="billing-details-payment-info-cvv"
          pattern="([0-9]{3,4})"
          maxlength="4"
          class="form__input text security form-control"
        />
        <span
          qaid="billing-details-payment-cvv-error"
          aria-hidden="true"
          class="hidden form__message text-small-emphasized cvv_code--error form-feedback is-invalid"
        >
          {{ $t('payment.cvvNumber.invalid') }}
        </span>
      </div>
      <button type="submit" class="hidden" disabled aria-hidden="true"></button>
    </form>
    <checkout-edit-link :qaid="qaid" :link-config="linkConfig" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlImage} from '@pedal/pdl-image';
import {PdlLabel} from '@pedal/pdl-label';
import AddressSummary from '@/components/AddressSummary';
import ApplePayIcon from '@/icons/ApplePayIcon.vue';
import {PaymentProvider} from '@/constants/payment-provider.js';
import CheckoutEditLink from '@/components/checkout/CheckoutEditLink.vue';

export default {
  name: 'PaymentMethod',
  components: {PdlImage, PdlLabel, AddressSummary, ApplePayIcon, CheckoutEditLink},
  props: {
    isShowingPaymentMethod: {
      type: Boolean,
      default: false,
    },
    linkConfig: {
      type: Object,
      default: () => ({}),
    },
    paymentInfo: {
      type: Object,
      default: () => ({}),
    },
    qaid: {
      type: String,
      default: '',
    },
    isSecurityCodeRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('backend', ['cdnStaticFilesRoot']),
    isIdeal() {
      return this.paymentInfo.paymentOption?.includes('ideal');
    },
    isThirdPartyPaymentOption() {
      return this.paymentInfo.paymentOption !== PaymentProvider.CYBERSOURCE;
    },
    isCardDataAvailable() {
      return Boolean(this.paymentInfo.cardTypeData);
    },
    hasLogo() {
      return (
        this.isIdeal ||
        this.paymentInfo.paymentOption === PaymentProvider.CYBERSOURCE_APPLE ||
        this.paymentInfo.paymentOption === PaymentProvider.GR4VY_APPLE
      );
    },
    hasBankName() {
      return (
        (this.isIdeal && this.paymentInfo.paymentOptionName) ||
        this.paymentInfo.paymentOption === PaymentProvider.CYBERSOURCE_KCP ||
        this.paymentInfo.paymentOption === PaymentProvider.GR4VY_KCP
      );
    },
    paymentMethodName() {
      return this.isIdeal ? this.$t('checkout.multi.paymentMethod.ideal') : this.$t('checkout.financing.applePay');
    },
    paymentOptionImgSrc() {
      return this.isIdeal ? `${this.cdnStaticFilesRoot}/_ui/desktop/theme-trek/img/ideal-logo.svg` : null;
    },
  },
};
</script>
<style scoped>
.form__item {
  margin-bottom: unset;
}

.form__item--error::after {
  top: 2.5rem;
  right: 1rem;
}
</style>
