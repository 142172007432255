<script>
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import {mapMutations} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlSectionHeader, PdlHeading, TrekButton, PdlCallout, PdlIcon},

  props: {
    accountList: {
      type: Array,
      default: () => [],
    },
    tooltipId: {
      type: String,
      default: null,
    },
    shippingDays: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      search: '',
      accountSelected: {
        accountNumber: '',
      },
    };
  },

  computed: {
    filteredList() {
      const result = this.accountList.filter((account) => this.matchesSearch(account, this.search));
      return sortBy(result, [(account) => account.name.toLowerCase(), 'accountNumber']);
    },
  },

  mounted() {
    if (this.accountList.length) {
      const activeAccount = find(this.accountList, (account) => {
        return account.active === true;
      });
      this.accountSelected = activeAccount || this.accountList[0];
      this.accountSelected.shippingDays = this.shippingDays;
      this.setB2bUnit(this.accountSelected);
      window.localStorage.setItem('currentUnitId', this.accountSelected.accountNumber);
    }
  },

  methods: {
    displayName(account) {
      return account.name;
    },

    matchesSearch({name, accountNumber}, search) {
      return name.toLowerCase().includes(search.toLowerCase()) || accountNumber.includes(search);
    },

    selectAccount(account) {
      this.accountSelected = account;
      this.setB2bUnit(account);
      this.$nextTick(() => this.$refs.form.submit());
    },

    closeTooltip() {
      let el = this.$refs[`tippy_${this.tooltipId}`];

      if (el && el._tippy) {
        el._tippy.hide();
      }
    },

    ...mapMutations('user', ['setB2bUnit']),
  },
};
</script>
