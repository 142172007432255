<template>
  <pdl-drawer size="23rem" show-close :is-open="isDrawerOpen" @close="handleClose">
    <template #content>
      <retailer-select-input
        :initial-value="geolocation.address"
        :handle-close="handleClose"
        :search="fetchInitialRetailers"
        @coordinates-from-input="handleLocationUpdate"
      />
      <pdl-loading :is-loading="isLoading">
        <div class="my-3 space-y-6">
          <div v-if="!retailerList.length" class="text-center" qaid="retailer-drawer-no-results">
            {{ $t('text.search.noResults') }}
          </div>
          <template v-else>
            <p class="text-xs flex justify-center gap-x-1" qaid="retailer-pricing-note">
              <span class="font-bold"> {{ $t('tcrm.B2B.legal.3') }} </span>
              {{ $t('fulfillment.retailer.change.pricing') }}
            </p>
            <retailer-card
              v-for="(retailer, i) in retailerList"
              :key="`retailer-card-${i}`"
              :retailer="retailer"
              :is-selected="retailer.name === selectedRetailer.name"
              :set-selected-retailer="handleRetailerSelected"
              :qaid="`retailer-card-${i}`"
            />
          </template>
        </div>
        <pdl-loading ref="loadMoreTarget" :is-loading="isLoadingMore" />
      </pdl-loading>
    </template>
  </pdl-drawer>
</template>

<script>
import {PdlDrawer} from '@pedal/pdl-drawer';
import {PdlLoading} from '@pedal/pdl-loading';
import RetailerSelectInput from '@/components/drawer/retailer/RetailerSelectInput.vue';
import RetailerCard from '@/components/drawer/retailer/RetailerCard.vue';
import RetailerApi from '@/api/retailer-api';
import {mapMutations, mapState, mapActions} from 'vuex';
import {convertPreferredUnitToMeters} from '@/utils/unit-converter';
import {UnitTypes} from '@/constants/unit-types';

const PAGE_SIZE = 15;
const RADIUS_IN_MILES = 200;

export default {
  components: {PdlDrawer, PdlLoading, RetailerSelectInput, RetailerCard},
  props: {
    isDrawerOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      retailerList: [],
      currentPage: 0,
      totalPages: undefined,
      currentSearchCoordinates: {},
      isLoading: false,
      isLoadingMore: false,
      observer: null,
      coordinatesFromInput: [],
    };
  },
  computed: {
    ...mapState('backend', ['geolocation']),
    ...mapState('user', ['selectedRetailer']),
    ...mapState('pdp', ['pdpSku']),
    ...mapState('cart', ['outOfStockProductSku']),
  },
  watch: {
    pdpSku(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchInitialRetailers(this.currentSearchCoordinates);
      }
    },
    outOfStockProductSku(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.fetchInitialRetailers(this.currentSearchCoordinates);
      }
    },
  },
  mounted() {
    if (Object.keys(this.geolocation).length) {
      this.fetchInitialRetailers({latitude: this.geolocation.latitude, longitude: this.geolocation.longitude});
    }
    const options = {
      root: null,
      rootMargin: '0px',
    };
    this.observer = new IntersectionObserver(this.handleLoadMore, options);
    this.observer.observe(this.$refs.loadMoreTarget.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
  methods: {
    ...mapMutations('user', ['setSelectedRetailer']),
    ...mapActions('user', ['setSelectedLocationFromCoordinates']),
    ...mapActions('pdp', ['setlocallyStockAvailableRetailers']),
    handleRetailerSelected(retailer) {
      this.setSelectedRetailer(retailer);
      if (this.coordinatesFromInput.length) {
        this.setSelectedLocationFromCoordinates({
          longitude: this.coordinatesFromInput[0],
          latitude: this.coordinatesFromInput[1],
        });
      }
      this.handleClose();
    },
    handleClose() {
      this.$emit('close');
    },
    async handleLoadMore(intersectionEvent) {
      if (!intersectionEvent[0]?.isIntersecting || !this.retailerList.length) return;
      this.fetchMoreRetailers();
    },
    async fetchInitialRetailers({latitude, longitude}) {
      this.isLoading = true;
      try {
        const radius = convertPreferredUnitToMeters(RADIUS_IN_MILES, UnitTypes.IMPERIAL.milesString);
        this.currentPage = 0;
        const params = {
          radius,
          pageSize: PAGE_SIZE,
          currentPage: this.currentPage,
          latitude,
          longitude,
          ecommEnabled: true,
        };
        if (this.pdpSku) {
          params.product = this.pdpSku;
        }
        if (this.outOfStockProductSku) {
          params.product = this.outOfStockProductSku;
        }
        const {data: retailerData} = await RetailerApi.getRetailers(params);

        this.totalPages = retailerData.pagination?.totalPages;
        this.currentSearchCoordinates = {latitude, longitude};

        this.retailerList = retailerData.stores;
        this.setlocallyStockAvailableRetailers(this.retailerList?.filter((retailer) => retailer?.retailerStock));
      } catch (error) {
        this.retailerList = [];
      } finally {
        this.isLoading = false;
      }
    },
    async fetchMoreRetailers() {
      if (this.currentPage + 1 >= this.totalPages) return;
      this.currentPage = this.currentPage + 1;
      this.isLoadingMore = true;
      try {
        const radius = convertPreferredUnitToMeters(RADIUS_IN_MILES, UnitTypes.IMPERIAL.milesString);
        const params = {
          radius,
          pageSize: PAGE_SIZE,
          currentPage: this.currentPage,
          latitude: this.currentSearchCoordinates.latitude,
          longitude: this.currentSearchCoordinates.longitude,
          ecommEnabled: true,
        };
        if (this.pdpSku) {
          params.product = this.pdpSku;
        }
        const {data: retailerData} = await RetailerApi.getRetailers(params);

        this.retailerList = [...this.retailerList, ...retailerData.stores];
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingMore = false;
      }
    },
    handleLocationUpdate(event) {
      this.coordinatesFromInput = event;
    },
  },
};
</script>

<style lang="scss" scoped>
.pdl-drawer {
  z-index: 1600000001;
}

::v-deep .pdl-drawer__content {
  background-color: var(--gray-05);
}
</style>
