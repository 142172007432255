<template>
  <pdl-collapse-item
    :key="getCollapseItemName(partTypeKey, itemIdKey, itemIndex)"
    :name="getCollapseItemName(partTypeKey, itemIdKey, itemIndex)"
  >
    <div
      v-if="partTypeKey === 'customWaterBottles'"
      slot="title"
      class="heading heading--sm"
      qaid="custom-water-bottle-heading"
    >
      {{ $t('checkout.custom.shippingCost') }}
    </div>
    <div v-else-if="!isProjectOne" slot="title" class="heading heading--sm" qaid="distributionCenter.heading">
      {{ warehouse.name }} ({{ warehouse.count }})
    </div>

    <!-- Callout for Current Availability -->
    <pdl-callout
      v-if="isBackorder && !isCustomWaterBottle && !isProjectOne"
      kind="warning"
      qaid="distributionCenter.backorders.warning"
    >
      <template #content>
        {{ $t('checkout.B2B.backordered.primaryWarehouse') }}
      </template>
    </pdl-callout>

    <pdl-tabs>
      <!-- SKU Tab -->
      <pdl-tab-pane :label="$t('text.account.savedLists.sku')">
        <!-- SKU GRID -->
        <table class="b2b-grid b2b-grid--collapse is-compact" qaid="saved-list-sku-grid">
          <caption class="sr-only">
            {{
              $t('product.details.nav.label.skuGrid')
            }}
          </caption>
          <!-- SKU Grid Headers -->
          <thead class="b2b-grid__header">
            <tr>
              <th
                v-if="isBackorder && !isRestrictedCustomer"
                id="sold"
                class="b2b-grid__cell b2b-grid__head"
                qaid="sku-grid.sold"
              >
                {{ $t('text.sold') }}
              </th>
              <th
                v-if="isBackorder && !isRestrictedCustomer"
                id="sold-qty"
                class="b2b-grid__cell b2b-grid__head"
                qaid="sku-grid-sold-qty"
              >
                {{ $t('text.checkout.soldQuantity') }}
              </th>
              <th id="qty" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.qty">
                {{ $t('text.account.savedLists.qty') }}
              </th>

              <th id="split" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.split"></th>

              <th id="sku" class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid.sku">
                {{ $t('text.account.savedLists.sku') }}
              </th>

              <th id="note" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.note"></th>

              <th id="item" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.item">{{ $t('order.item') }}</th>

              <th
                v-if="!isProjectOne"
                id="upc-ean"
                class="b2b-grid__cell b2b-grid__head uppercase"
                qaid="sku-grid.upc-ean"
              >
                {{ $t('text.account.savedLists.upc') }}
              </th>

              <th
                v-if="isCustomWaterBottle || isProjectOne"
                id="custom-id"
                class="b2b-grid__cell b2b-grid__head lg:text-right"
                qaid="sku-grid-custom-id"
              >
                {{ $t('checkout.custom.ID') }}
              </th>
              <template v-else>
                <th
                  v-if="!isConfirmationStep"
                  id="allocated"
                  class="b2b-grid__cell b2b-grid__head lg:text-right"
                  qaid="sku-grid.allocated"
                >
                  <span
                    v-tippy="{
                      html: '#abbreviation-text',
                      trigger: 'mouseenter',
                    }"
                  >
                    {{ $t('backordersAllocated.B2B.alloc') }}
                  </span>
                </th>

                <th
                  v-if="!isConfirmationStep && !isProjectOne"
                  id="backordered"
                  class="b2b-grid__cell b2b-grid__head lg:text-right"
                  qaid="sku-grid.backordered"
                >
                  <span
                    v-tippy="{
                      html: '#bo-abbreviation-text',
                      trigger: 'mouseenter',
                    }"
                  >
                    {{ $t('backordersAllocated.B2B.bo') }}
                  </span>
                </th>
              </template>
              <th
                v-if="!isConsumerFriendlyMode && !isProjectOne"
                id="order-price"
                class="b2b-grid__cell b2b-grid__head"
                qaid="sku-grid.order-price"
              >
                {{ $t('order.price') }}
              </th>

              <th
                v-if="!isConsumerFriendlyMode"
                id="subtotal"
                class="b2b-grid__cell b2b-grid__head text-right"
                qaid="sku-grid.subtotal"
              >
                {{ $t('text.order.subtotal') }}
              </th>
            </tr>
          </thead>
          <span id="abbreviation-text" class="hidden">{{ $t('text.allocated') }}</span>
          <span id="bo-abbreviation-text" class="hidden">{{ $t('text.backordered') }}</span>
          <!-- SKU Grid Body/Rows -->
          <tbody>
            <!-- For each Product/Row -->
            <checkout-grid-row
              v-for="(item, index) in paginatedData"
              :key="item[itemIdKey] + '_' + index"
              :row-index="index"
              :item-key="item[itemIdKey]"
              :warehouse="warehouse.id"
              :is-backorder="isBackorder"
              :checkout-step="checkoutStep"
              :part-type-key="partTypeKey"
              :is-custom-water-bottle="isCustomWaterBottle"
              :is-project-one="isProjectOne"
              @update-item-bto-data="updateItemBTOData"
            />
          </tbody>
        </table>

        <div class="grid-x grid-margin-x mb-5">
          <div class="cell medium-6 large-8">
            <pagination-controls
              v-if="pageCount > 1"
              ref="pagination"
              :page-number="pageNumber"
              :page-count="pageCount"
              @page-change="pageChange"
              @prev-page="prevPage"
              @next-page="nextPage"
            ></pagination-controls>
          </div>
          <div v-if="!isConsumerFriendlyMode" class="cell medium-6 large-4">
            <!-- Shipment Summary -->
            <ul class="block-list text-md">
              <li class="block-list__item" qaid="shipmentSummary.subtotal">
                <span>{{ $t('text.order.subtotal') }}</span>
                <span>{{ warehouse.subTotal }}</span>
              </li>

              <li
                v-if="!isBackorder && !isDistributor"
                class="block-list__item"
                qaid="shipmentSummary.estimatedShipping"
              >
                <span>{{ $t('text.estimatedShipping') }}</span>
                <span>{{ warehouse.formattedEstimatedShipping }}</span>
              </li>
              <li class="block-list__item" qaid="shipmentSummary.paymentTerms">
                <span>{{ $t('text.paymentTerms') }}</span>
                <span>{{ warehouse.paymentTerms }}</span>
              </li>
              <li class="block-list__item text-base font-medium" qaid="shipmentSummary.total">
                <span>{{ $t('text.warehouseTotal') }}</span>
                <span>{{ warehouse.total }}</span>
              </li>
            </ul>
          </div>
        </div>
      </pdl-tab-pane>
      <pdl-tab-pane v-if="showAppleLabels" :label="$t('appleLabel.title')">
        <pdl-callout v-if="!cartToggle" kind="error">
          <template #content>
            {{ $t('text.systemDown.cannotSubmit') }}
          </template>
        </pdl-callout>

        <apple-label-grid
          :key="warehouse.id"
          :items="appleLabelData"
          :text-save-button="$t('text.saveChanges')"
          :text-clear-button="$t('search.nav.clear')"
          :text-cannot-save="$t('text.systemDown.cannotSubmit')"
        ></apple-label-grid>
      </pdl-tab-pane>
    </pdl-tabs>
  </pdl-collapse-item>
</template>

<script>
import pagination from '../../mixins/pagination';
import {mapState, mapGetters} from 'vuex';
import {PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import CheckoutGridRow from '@/components/checkout/CheckoutGridRow';
import PaginationControls from '@/components/PaginationControls';
import AppleLabelGrid from '@/components/checkout/AppleLabelGrid';
import {PdlCallout} from '@pedal/pdl-callout';
import {formatAppleData} from '@/utils/product-info';
import {ProductDeliveryStatus} from '@/constants/product';
import {CheckoutStep} from '@/constants/checkout';

export default {
  components: {
    PdlCollapseItem,
    PdlTabs,
    PdlTabPane,
    CheckoutGridRow,
    PaginationControls,
    AppleLabelGrid,
    PdlCallout,
  },

  mixins: [pagination],

  props: {
    itemIdKey: {
      type: String,
      default: '',
    },
    partTypeKey: {
      type: String,
      default: '',
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      required: false,
      default: ACC.checkoutItemsPageSize || 50,
    },
    type: {
      type: String,
      default: '',
    },
    checkoutStep: {
      type: String,
      default: '',
    },
    warehouseKey: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState('backend', ['isDistributor', 'isEmployee', 'cartToggle', 'appleLabelAccess', 'isConsumerFriendlyMode']),
    ...mapGetters('checkout', ['bikes', 'parts', 'customWaterBottles', 'projectOneBikes']),
    ...mapGetters('backend', ['isRestrictedCustomer']),

    warehouse() {
      return this[this.partTypeKey]?.warehouses.find((warehouse) => warehouse.id === this.warehouseKey);
    },

    isBackorder() {
      return this.warehouse.id === ProductDeliveryStatus.BACKORDERED;
    },

    isConfirmationStep() {
      return this.checkoutStep === CheckoutStep.Confirmation;
    },

    displayAppleLabels() {
      if (this.partTypeKey === 'bikes') {
        return this.appleLabelAccess.bikes;
      }
      if (this.partTypeKey === 'parts') {
        return this.appleLabelAccess.parts;
      }

      return false;
    },

    appleLabelData() {
      if (!this.displayAppleLabels) return [];
      if (!this.warehouse.entries) return [];
      let appleLabelPaginatedData = this.paginatedData;
      return formatAppleData(appleLabelPaginatedData);
    },

    listData() {
      return this.warehouse.entries ?? [];
    },

    isCustomWaterBottle() {
      return this.partTypeKey === 'customWaterBottles';
    },
    isProjectOne() {
      return this.partTypeKey === 'projectOneBikes';
    },

    showAppleLabels() {
      return this.displayAppleLabels && !this.isConfirmationStep;
    },
  },

  mounted() {
    this.$emit('update-is-backordered', true);
  },

  methods: {
    getCollapseItemName(partTypeKey, itemIdKey, index) {
      return `${partTypeKey}-${itemIdKey}-${index}`;
    },

    updateItemBTOData(payload) {
      payload.warehouseId = this.warehouse.id;
      this.$emit('update-item-bto-data', payload);
    },
  },
};
</script>
