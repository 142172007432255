<template>
  <fieldset>
    <legend class="text-lg font-bold">{{ $t('checkout.address.details') }}</legend>
    <address-autofill-input
      v-if="isAddressAutofillEnabled && !isAddressFieldsShowing"
      :on-address-selected="handleAddressSelected"
    />
    <slot v-if="isPostcodeLookupEnabled" name="postcode-autofill" />
    <p v-if="hasDeliveryRestrictionsMessage" class="form__shipping mb-3">
      <span>{{ $t('text.shipping') }} </span><span class="font-bold">{{ $t('text.shipping.US') }}</span>
    </p>
    <ul v-if="isAddressFieldsShowing" class="mb-3">
      <li
        v-for="(componentConfig, index) in fieldList"
        :key="`${componentConfig.field}-${index}`"
        :qaid="componentConfig.qaid"
      >
        <component
          :is="componentConfig.component"
          v-bind="getFieldProps(componentConfig.field)"
          v-model="form[componentConfig.field]"
        />
      </li>
    </ul>
  </fieldset>
</template>

<script>
import {Line1Field, Line2Field, TownCityField, RegionField, PostcodeField} from '@/components/checkout/fields/address';
import AddressAutofillInput from '@/components/checkout/fields/address-autofill/AddressAutofillInput';
import {mapState, mapActions} from 'vuex';
import {getAddressFormConfig, AddressFormComponents} from '@/config/checkout/address-form-config';

export default {
  components: {Line1Field, Line2Field, TownCityField, RegionField, PostcodeField, AddressAutofillInput},
  props: {
    isAddressAutofillEnabled: {
      type: Boolean,
      default: false,
    },
    isPostcodeLookupEnabled: {
      type: Boolean,
      default: false,
    },
    regions: {
      type: Array,
      default: () => [],
    },
    postcodeMaxLength: {
      type: Number,
      required: true,
    },
    postcodeFormat: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAddressFieldsShowing: !this.isAddressAutofillEnabled,
      form: {
        line1: '',
        line2: '',
        postcode: '',
        regionIso: '',
        townCity: '',
      },
    };
  },
  mounted() {
    if (this.isCheckout && Object.keys(this.drawerDeliveryAddress).length) {
      this.isAddressFieldsShowing = true;
      this.$emit('address-fields-showing', this.isAddressFieldsShowing);
    }
  },
  computed: {
    ...mapState('backend', ['locale', 'isUserLoggedIn']),
    ...mapState('user', ['drawerDeliveryAddress']),
    fieldList() {
      return getAddressFormConfig(this.locale).addressDetails;
    },
    hasDeliveryRestrictionsMessage() {
      return (this.locale === 'gb' || this.locale === 'nl') && !this.isPostcodeLookupEnabled;
    },
  },
  watch: {
    isAddressAutofillEnabled: {
      handler(newVal) {
        this.isAddressFieldsShowing = !newVal;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('checkout', ['getBillingAddressObject']),
    handleAddressSelected(suggestion) {
      this.isAddressFieldsShowing = true;
      this.$emit('address-fields-showing', this.isAddressFieldsShowing);
      this.populateAddressFields(suggestion);
    },
    populateAddressFields(suggestion) {
      let regionProp = this.locale === 'jp' ? 'name' : 'isocodeShort';
      Object.entries(suggestion).forEach(([key, value]) => {
        if (key === 'region') {
          const matchedRegion = this.regions.find((region) => region[regionProp] === value);
          this.form[key] = matchedRegion ? matchedRegion.isocode : value;
          return;
        }
        this.form[key] = value;
      });
      this.getBillingAddressObject(this.form);
    },
    getFieldProps(field) {
      const propConfig = {
        [AddressFormComponents.REGION.field]: {
          regions: this.regions,
        },
        [AddressFormComponents.POSTCODE.field]: {
          postcodeMaxLength: this.postcodeMaxLength,
          postcodeFormat: this.postcodeFormat,
        },
      };
      return propConfig[field] ?? {};
    },
    isCheckout() {
      return window.location.href.includes('/checkout');
    },
  },
};
</script>
