<template>
  <div class="grid-container">
    <template>
      <pdl-section-header class="mt-3" :is-dividing="true" size="xl">
        <template slot="content">
          <pdl-heading :level="1">
            {{ $t('Ride Club Integrations') }}
          </pdl-heading>
        </template>
      </pdl-section-header>
      <pdl-subheading size="md">{{
        $t('Get more out of your riding experience by connecting accounts you already have with our partners.')
      }}</pdl-subheading>
      <div class="my-4">
        <pdl-collapse :default-active="false" align-icon="start">
          <template v-for="(integration, index) in appIntegrations">
            <integrations-list-item
              :key="index"
              :integration="integration"
              :app-integration-status="appsIntegrationStatus[integration.connectionStatusKey]"
              @open-app-sso-window="openAppSsoWindow"
              @show-app-disconnect-dialog="showAppDisconnectDialog"
            />
          </template>
        </pdl-collapse>
      </div>
    </template>
    <pdl-dialog
      id="app-disconnect-dialog"
      :title="$t(appDisconnectDialogTitle)"
      :visible.sync="appDisconnectDialogVisible"
      :center="true"
      :close-dialog-tool-tip="$t('popup.close')"
      qaid="app-disconnect-dialog"
      header-class="text-left"
      @close="closeAppDisconnectDialog"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <pdl-button button alert qaid="rcp-app-disconnect-button" @click="rcpAppDeAuthorize">
            {{ $t('garage.garmin.disconnect') }}
          </pdl-button>
          <pdl-button button secondary qaid="rcp-app-disconnect-cancel-button" @click="closeAppDisconnectDialog">
            {{ $t('text.cancel') }}
          </pdl-button>
        </div>
      </template>
    </pdl-dialog>
  </div>
</template>
<script>
import {PdlButton} from '@pedal/pdl-button';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlCollapse} from '@pedal/pdl-collapse';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {PdlSectionHeader, PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';
import IntegrationsListItem from '@/components/app-integrations/IntegrationsListItem.vue';
import {AppConfigurations, AppsList} from '@/constants/3rd-party-integration.js';
import AppIntegrationApi from '@/rcpRouteList/utils/integrations-api.js';
import {mapState} from 'vuex';

export default {
  name: 'RcpIntegrationsApp',
  components: {
    PdlButton,
    PdlDialog,
    PdlSectionHeader,
    PdlHeading,
    PdlSubheading,
    PdlCollapse,
    IntegrationsListItem,
  },
  data() {
    return {
      appsIntegrationStatus: {},
      appDisconnectDialogVisible: false,
      appToDisconnect: '',
      appToConnect: '',
    };
  },

  computed: {
    ...mapState('backend', ['hostUrl']),
    appIntegrations() {
      return AppConfigurations;
    },
    appsIntegrationInfo() {
      return this.appsIntegrationStatus;
    },
    appDisconnectDialogTitle() {
      if (!this.appToDisconnect) return '';
      let title = AppConfigurations[this.appToDisconnect].disconnectDialogTitle;
      return title;
    },
    appDisconnectMessages() {
      if (!this.appToDisconnect) return '';
      let messages = AppConfigurations[this.appToDisconnect].messages.disconnect;
      return messages;
    },
    appConnectMessages() {
      if (!this.appToConnect) return '';
      let messages = AppConfigurations[this.appToConnect].messages.connect;
      return messages;
    },
  },
  mounted() {
    this.checkAppIntegrationStatus();
  },
  methods: {
    async openAppSsoWindow(appName) {
      this.appToConnect = appName;
      window.addEventListener('message', this.appOauthCloseHandler, false);
      const AppSsoURI = await AppIntegrationApi.authorizeUserTo3rdPartyApp(appName);
      this.authorizationURI = AppSsoURI.authorizationUri;
      this.ssoWindowRef = window.open(this.authorizationURI, 'ssoWindow', 'popup, width=450, height=550');
    },
    showAppDisconnectDialog(appName) {
      this.appToDisconnect = appName;
      this.appDisconnectDialogVisible = true;
    },
    closeAppDisconnectDialog() {
      this.appToDisconnect = '';
      this.appDisconnectDialogVisible = false;
    },
    async checkAppIntegrationStatus() {
      this.appsIntegrationStatus = await AppIntegrationApi.fetchAppsIntegrationStatus();
    },
    async rcpAppDeAuthorize() {
      if (!this.appToDisconnect) return;
      try {
        const appDeAuthorizeStatus = await AppIntegrationApi.deAuthorizeUserTo3rdPartyApp(this.appToDisconnect);
        if (appDeAuthorizeStatus.isDeauthSuccessful) {
          this.showToastMessage({
            type: PdlToastType.SUCCESS,
            message: this.$t(this.appDisconnectMessages.success),
          });
          await this.checkAppIntegrationStatus();
        } else {
          throw Error('Unable to disconnect');
        }
      } catch (error) {
        console.error(error);
        this.showToastMessage({
          message: this.$t(this.appDisconnectMessages.error),
        });
      } finally {
        this.closeAppDisconnectDialog();
      }
    },
    async appOauthCloseHandler(event) {
      try {
        if (
          event.origin !== `https://${this.hostUrl}` ||
          event.data.message !== AppConfigurations[this.appToConnect].successCallbackMsg
        )
          return;
        this.ssoWindowRef.close();
        await this.checkAppIntegrationStatus();
        window.removeEventListener('message', this.appOauthCloseHandler, false);
        if (
          AppsList.includes(this.appToConnect) &&
          this.appsIntegrationStatus[AppConfigurations[this.appToConnect].connectionStatusKey]
        ) {
          this.showToastMessage({
            type: PdlToastType.SUCCESS,
            message: this.$t(this.appConnectMessages.success),
          });
          this.appToConnect = '';
        } else {
          throw Error('App Outh connect failure');
        }
      } catch (error) {
        console.error(error);
        this.showToastMessage({message: this.$t(this.appConnectMessages.error)});
        this.appToConnect = '';
      }
    },
    showToastMessage(feedback) {
      this.$notify({
        type: feedback?.type ?? PdlToastType.ERROR,
        title: feedback?.title ? this.$t(`${feedback?.title}`) : '',
        message: feedback?.message ? this.$t(`${feedback?.message}`) : '',
        showClose: true,
      });
    },
  },
};
</script>
