import {isInStock} from '@/components/containers/pdp/utils/retailer-helpers';

const ProductType = Object.freeze({
  AFTERMARKET: 'am',
  BIKE: 'bike',
});

export function determineOptionAvailability({option, product, selectedAttributes, b2b, isECommAvailable}) {
  const variant = product.variants.find((v) => {
    return Object.entries(selectedAttributes).every(([key, value]) => {
      if (key === option.code) {
        return v.variantAttributes[option.code] === option.value;
      }
      return v.variantAttributes[key] === value;
    });
  });

  const hasStock = variant?.isLocallyStockAvailable || isInStock(false, variant?.globalStockStatus);
  return b2b || (isECommAvailable && hasStock);
}

const Variant = (variantCode, product, b2b = false) => {
  const variant = product?.variants?.find((variant) => variant.code === variantCode);
  const get = () => {
    return variant;
  };
  const getIsOnSale = () => {
    const priceKeys = b2b
      ? {containerKey: 'retailerPrice', key: 'closeout'}
      : {containerKey: 'consumerPrice', key: 'saleAdvertised'};
    if (
      variant?.prices?.[priceKeys.containerKey]?.[priceKeys.key]?.high ||
      variant?.prices?.[priceKeys.containerKey]?.[priceKeys.key]?.low
    )
      return true;
    if (
      !variant?.prices?.[priceKeys.containerKey] &&
      (product?.prices?.[priceKeys.containerKey]?.[priceKeys.key]?.high ||
        product?.prices?.[priceKeys.containerKey]?.[priceKeys.key]?.low)
    )
      return true;
    return false;
  };
  const isAftermarket = () => {
    return variant?.productType === ProductType.AFTERMARKET;
  };
  return {getIsOnSale, get, isAftermarket};
};

export default Variant;
