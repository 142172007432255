<template>
  <div class="grid-container">
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('myCompany.editStoreProfile') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <form id="edit-store-profile">
      <pdl-section-header size="md">
        <template slot="content">
          <pdl-heading :level="2">{{ $t('text.storeInformation') }}</pdl-heading>
        </template>
      </pdl-section-header>
      <div class="grid-x grid-margin-x">
        <div class="cell large-4">
          <form-group
            id="text-store-name"
            v-model="storeData.name"
            type="text"
            name="text-store-name"
            :label="$t('text.storeName')"
            qaid="text-store-name"
            :error="getErrorMessage('storeData.name')"
            required
            show-error-messages
            force-display-error
          />

          <form-group
            id="text-email-address"
            v-model="storeData.emailAddress"
            type="email"
            name="text-email-address"
            :label="$t('text.address.email')"
            qaid="text-email-address"
            :error="getErrorMessage('storeData.emailAddress')"
            required
            show-error-messages
            force-display-error
          />
        </div>
        <div class="cell large-4">
          <form-group
            id="text-phone-number"
            v-model="storeData.phoneNumber"
            v-mask
            type="text"
            allowed-characters-regex="[\d-+. ()]"
            name="text-phone-number"
            :data-mask="phoneNumberMask"
            :label="$t('text.address.phone')"
            qaid="text-phone-number"
            :error="getErrorMessage('storeData.phoneNumber')"
            required
            show-error-messages
            force-display-error
          />

          <form-group
            id="text-website-url"
            v-model="storeData.url"
            type="url"
            name="text-website-url"
            :label="$t('text.websiteURL')"
            qaid="text-website-url"
          />
        </div>
        <div class="cell large-4">
          <p class="text-sm font-bold" v-html="$t('text.storeImage')"></p>
          <div v-if="!!storeData.storeImageUrl" class="mt-25">
            <img class="block w-full" :src="storeData.storeImageUrl" :alt="$t('text.storeImage')" />
          </div>
          <p
            class="mt-25 text-sm"
            v-html="$t('myCompany.storePhoto.missingMessage', [$t('myCompany.storePhoto.missingMessage.emailLink')])"
          ></p>
        </div>
      </div>

      <hr class="rule my-5" />

      <div class="grid-x">
        <div class="cell large-8">
          <pdl-section-header size="md">
            <template slot="content">
              <pdl-heading :level="2">{{ $t('storeDetails.store.address') }}</pdl-heading>
              <pdl-subheading>{{ $t('myCompany.location.mapMarker') }}</pdl-subheading>
            </template>
          </pdl-section-header>
          <form-group
            id="text-address-line-1"
            v-model="storeData.line1"
            type="text"
            name="text-address-line-1"
            :label="$t('address.line1')"
            qaid="text-address-line-1"
            :error="getErrorMessage('storeData.line1')"
            required
            show-error-messages
            force-display-error
            @blur="updateAddress"
          />

          <form-group
            id="text-address-line-2"
            v-model="storeData.line2"
            type="text"
            name="text-address-line-2"
            :label="$t('address.line2')"
            qaid="text-address-line-2"
          />

          <div class="grid-x grid-margin-x">
            <div class="cell large-6">
              <form-group
                id="text-address-city"
                v-model="storeData.town"
                type="text"
                name="text-address-city"
                :label="$t('address.townCity')"
                qaid="text-address-city"
                :error="getErrorMessage('storeData.town')"
                required
                show-error-messages
                force-display-error
                @blur="updateAddress"
              />

              <form-group
                id="text-address-postal-code"
                v-model="storeData.postalCode"
                v-validate="{required: true, length: isPostcodeLength}"
                v-mask
                :data-mask="postcodeMask"
                type="text"
                name="text-address-postal-code"
                :label="$t('address.postalcode')"
                qaid="text-address-postal-code"
                :error="getErrorMessage('storeData.postalCode')"
                required
                show-error-messages
                force-display-error
                @blur="updateAddress"
              />
            </div>
            <div class="cell large-6">
              <fieldset class="form-fieldset">
                <label for="state" class="form-label">
                  {{ $t('address.selectState') }} <span v-if="hasSelectableRegions" class="text-red ml-25">*</span>
                </label>
                <div
                  v-if="hasSelectableRegions"
                  class="select"
                  :class="{'has-error': !!getErrorMessage('storeData.regionIso')}"
                >
                  <select
                    id="state"
                    v-model="storeData.regionIso"
                    required="required"
                    class="form-control"
                    name="text-address-state"
                    @change="updateAddress"
                  >
                    <option
                      v-for="(state, stateIndex) in storeData.options.countryRegionMap[countryIso]"
                      :key="stateIndex"
                      :value="values(state)[0].isocode"
                      :selected="storeData.regionIso === values(state)[0].isocode"
                    >
                      {{ values(state)[0].name }}
                    </option>
                  </select>
                </div>
                <input
                  v-else
                  id="state"
                  v-model="storeData.regionIso"
                  type="text"
                  name="state"
                  class="form-control"
                  @blur="updateAddress"
                />

                <div v-show="!!getErrorMessage('storeData.regionIso')" class="form-feedback is-invalid">
                  {{ getErrorMessage('storeData.regionIso') }}
                </div>
              </fieldset>
              <fieldset class="form-fieldset">
                <div>
                  <form-group
                    id="select-country"
                    v-model="countryIso"
                    :label="$t('address.country')"
                    type="select"
                    name="select-country"
                    :multi-items="countryNames"
                    qaid="edit-store-profile-countries"
                    :error="getErrorMessage('countryIso')"
                    required
                    show-error-messages
                    force-display-error
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell large-6">
              <form-group
                id="text-address-delivery-radius"
                v-model="storeData.deliveryRadius"
                type="text"
                allowed-characters-regex="[\d*]"
                name="text-address-delivery-radius"
                :label="$t('myCompany.deliveryRadius')"
                qaid="text-address-delivery-radius"
                :error="getErrorMessage('storeData.deliveryRadius')"
                required
                show-error-messages
                force-display-error
              />
            </div>
            <div class="cell large-6">
              <fieldset class="form-fieldset" :class="{'has-error': !!getErrorMessage(storeData.deliveryRadiusUnit)}">
                <legend class="form-fieldset__legend font-bold" qaid="text-address-delivery-radius-unit-label">
                  {{ $t('myCompany.deliveryRadius.units') }}<span class="text-red ml-25">*</span>
                </legend>
                <label v-for="unit in distanceUnits" :key="`unit-${unit.code}`" class="radio">
                  <input
                    :id="`delivery-radius-unit-${unit.code}`"
                    v-model="storeData.deliveryRadiusUnit"
                    type="radio"
                    :value="unit.code"
                    name="delivery-radius-unit"
                    qaid="`delivery-radius-unit-${unit.code}`"
                  />
                  <span class="control-indicator"></span>
                  {{ unit.name }}
                </label>
                <span
                  v-if="!!getErrorMessage(storeData.deliveryRadiusUnit)"
                  class="form-feedback is-invalid"
                  v-html="getErrorMessage(storeData.deliveryRadiusUnit)"
                ></span>
              </fieldset>
            </div>
          </div>
          <h5 class="font-medium" v-html="$t('myCompany.location.storeMap')"></h5>
          <p>
            <em v-html="$t('myCompany.location.movePin')"></em>
          </p>
          <div>
            <edit-store-profile-map-container
              map-classes="b2b-map"
              :initial-coordinates="{
                lat: location.latitude,
                lng: location.longitude,
              }"
              :address="formattedAddress"
              :zoom="defaultMapZoom"
              qaid="edit-store-profile-map-container"
              @map-changed="updateLatLng"
            />
          </div>
        </div>
      </div>

      <hr class="rule my-5" />

      <div class="grid-x">
        <div class="cell large-7">
          <div>
            <pdl-section-header size="md">
              <template slot="content">
                <pdl-heading :level="2">{{ $t('storeFinder.store.hours') }}</pdl-heading>
                <pdl-subheading>{{ $t('myCompany.selectStoreHours') }}</pdl-subheading>
              </template>
              <template slot="actions">
                <fieldset class="form-fieldset max-w-xs mt-2 mb-0">
                  <label for="time-zone" class="form-label" v-html="$t('text.selectATimeZone')"></label>
                  <div class="select">
                    <select
                      id="time-zone"
                      v-model="storeData.timeZone"
                      :value="storeData.timeZone"
                      required="required"
                      class="form-control"
                    >
                      <option v-for="(zone, zoneKey) in timeZones" :key="zoneKey" :value="zone.id">
                        {{ zone.displayName }}
                      </option>
                    </select>
                  </div>
                </fieldset>
              </template>
            </pdl-section-header>

            <hr class="rule" />

            <div>
              <store-day
                v-for="day in storeData.days"
                :key="day.id"
                :day="day"
                :add-range-text="$t('myCompany.selectStoreHours.addRange')"
                @update="day = $event"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="buttons my-5 pb-5">
        <pdl-button primary :disabled="isSubmitting" @click="validate">
          <span v-html="$t('text.submitChanges')"></span>
        </pdl-button>
        <pdl-button secondary :disabled="isSubmitting" @click="cancelSubmit">
          <span v-html="$t('text.cancel')"></span>
        </pdl-button>
        <pdl-loading page :is-loading="isSubmitting" />
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import {mask} from '@/directives/masks';
import storefrontInstance from '@/api/instances/storefront';
import find from 'lodash/find';
import omit from 'lodash/omit';
import values from 'lodash/values';
import unescape from 'lodash/unescape';
import FormGroup from '@/components/FormGroup';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlHeading, PdlSectionHeader, PdlSubheading} from '@pedal/pdl-section-header';
import StoreDay from '@/components/containers/my-company/StoreDay.vue';
import EditStoreProfileMapContainer from '@/components/mapbox/containers/EditStoreProfileMapContainer.vue';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type.js';
import {PdlToastType} from '@/constants/pdl-toast-type.js';
import {getPostcodeFormatOptions} from '@/constants/postcode-fields-config.js';
import {mapState} from 'vuex';
import {scrollToElement} from '@/utils/scroll-to';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {getPhoneMaskPattern} from '@/utils/masks/phone-mask-patterns';

const DEFAULT_MAP_ZOOM = 16;

export default {
  components: {
    FormGroup,
    PdlSectionHeader,
    PdlHeading,
    PdlSubheading,
    PdlButton,
    PdlLoading,
    EditStoreProfileMapContainer,
    StoreDay,
  },

  directives: {mask},

  mixins: [TrekValidationMixin],

  props: {
    initialStoreData: {
      type: Object,
      default: undefined,
    },
    distanceUnits: {
      type: Array,
      default: undefined,
    },
    postUrl: {
      type: String,
      default: undefined,
    },
    returnUrl: {
      type: String,
      default: undefined,
    },
    postcodeLength: {
      type: [String, Number],
      required: true,
      default: 0,
    },
    maxDeliveryRadius: {
      type: Number,
      default: 99,
    },
  },

  data() {
    return {
      storeData: this.initialStoreData,
      contextPath: ACC.config.encodedContextPath || '',
      isSubmitting: false,
      formattedAddress: {},
      maxRadius: this.maxDeliveryRadius,
      countryIso: '',
      defaultMapZoom: DEFAULT_MAP_ZOOM,
    };
  },

  validations() {
    const validations = {
      storeData: {
        name: {
          required: this.trekValidators.required('text.storeName'),
        },
        emailAddress: {
          required: this.trekValidators.required('text.address.email'),
          email: this.trekValidators.email('text.address.email'),
        },
        phoneNumber: {
          required: this.trekValidators.required('text.address.phone'),
        },
        line1: {
          required: this.trekValidators.required('address.line1'),
        },
        town: {
          required: this.trekValidators.required('address.townCity'),
        },
        regionIso: {
          requiredIf: this.trekValidators.requiredIf('address.selectState', this.hasSelectableRegions),
        },
        postalCode: {
          required: this.trekValidators.required('address.postalcode'),
        },
        deliveryRadius: {
          required: this.trekValidators.required('myCompany.deliveryRadius'),
          maxValue: this.trekValidators.maxValue('myCompany.deliveryRadius', this.maxDeliveryRadius),
        },
        deliveryRadiusUnit: {
          required: this.trekValidators.required('myCompany.deliveryRadius.units'),
        },
      },
      countryIso: {
        required: this.trekValidators.required('address.country'),
      },
    };

    if (typeof Number(this.postcodeLength) === 'number' && this.postcodeLength > 0) {
      validations.storeData.postalCode.maxLength = this.trekValidators.maxLength(
        'address.postcode',
        this.postcodeLength
      );
    }

    return validations;
  },

  computed: {
    ...mapState(['locale']),
    ...mapState('global', ['headerHeight']),
    extractedLocale() {
      return this.locale?.split('_')?.[1].toLocaleLowerCase();
    },

    postcodeMask() {
      return getPostcodeFormatOptions(this.extractedLocale);
    },

    phoneNumberMask() {
      return getPhoneMaskPattern(this.extractedLocale);
    },

    timeZones() {
      const zones = find(this.storeData.options.timeZones, (timeZone) => timeZone[this.countryIso] !== undefined);
      return zones ? zones[this.countryIso] : [];
    },

    countryNames() {
      return this.storeData.options.countries.map((country) => {
        const clone = {...country};
        return {displayName: clone['name'], value: clone['isoCode']};
      });
    },

    states() {
      const selectedState = find(
        this.storeData.options.countryRegionMap[this.countryIso],
        (state) => values(state)[0].isocode === this.storeData.regionIso
      );
      return selectedState ? selectedState : [];
    },

    location() {
      return {
        latitude: parseFloat(this.storeData.latitude),
        longitude: parseFloat(this.storeData.longitude),
      };
    },

    hasSelectableRegions() {
      return this.storeData.options.countryRegionMap?.[this.countryIso];
    },
  },
  watch: {
    countryIso() {
      this.setCountryData();
    },
  },

  mounted() {
    this.updateAddress();
    this.updateTimeZone();
    if (this.storeData.options.countries.length) {
      this.setInitialCountryIso();
    }
    if (this.storeData.dayLabels.length) {
      this.setDays();
    }
  },

  methods: {
    values,

    updateAddress() {
      if (this.storeData.line1 && this.storeData.town && this.storeData.postalCode) {
        this.formattedAddress = {
          address: `${this.storeData.line1}, ${this.storeData.town},${this.storeData.regionIso},${this.storeData.postalCode}`,
        };
      }
    },

    setInitialCountryIso() {
      if (this.storeData.countryIso) this.countryIso = this.storeData.countryIso;
    },

    setCountryData() {
      this.storeData.countryIso = this.countryIso;
      if (this.storeData.countryIso) {
        const country = this.storeData.options?.countries?.find(
          (country) => country.isoCode === this.storeData.countryIso
        );
        this.storeData.countryName = country?.name;
      }
    },

    setDays() {
      if (!this.storeData.days) return;
      this.storeData.days = this.storeData.dayLabels.map((dayLabel, index) => {
        return {
          id: index,
          active: this.storeData.days?.[index]?.active,
          name: dayLabel,
          ranges: this.storeData.days?.[index]?.ranges.map((range, rangeIndex) => {
            return {
              id: rangeIndex,
              dayName: dayLabel,
              startTime: range.startTime ? range.startTime : '8:00 AM',
              endTime: range.endTime ? range.endTime : '5:00 PM',
            };
          }),
        };
      });
    },

    unescapeString(string) {
      return unescape(string);
    },

    updateLatLng(event) {
      this.storeData.latitude = event.lat;
      this.storeData.longitude = event.lng;
    },

    updateTimeZone() {
      if (!this.storeData.timeZone) {
        this.storeData.timeZone = this.timeZones[0]?.id;
      }
    },

    validate() {
      this.v$.$validate().then((isValid) => {
        if (!isValid) {
          scrollToElement('.has-error', this.headerHeight + 80);
        } else {
          this.submitChanges();
        }
      });
    },

    getDataToSave() {
      return omit(structuredClone(this.storeData), ['options']);
    },

    submitChanges() {
      const token = ACC.config.CSRFToken;
      Vue.set(this.storeData, 'csrfToken', token);

      this.isSubmitting = true;
      storefrontInstance
        .post(`${this.postUrl}?CSRFToken=${token}`, this.getDataToSave())
        .then((response) => {
          if (checkMetaFeedbackType(response?.data, MetaFeedbackType.ERROR)) {
            this.$notify({
              type: PdlToastType.ERROR,
              message: response.data.meta.feedback.message.basePropertyValue,
            });
            this.isSubmitting = false;
          } else {
            document.location.href = `${this.contextPath}${this.returnUrl}`;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isSubmitting = false;
        });
    },

    cancelSubmit() {
      document.location.href = `${this.contextPath}${this.returnUrl}`;
    },
  },
};
</script>
